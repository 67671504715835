import { MenuItem, TextField } from "@mui/material";
import { HTMLAttributes, useEffect } from "react";
import { Control } from "react-hook-form";
import { useAppDispatch, useSelector } from "../../../redux-store";
import { selectActiveCargoTags } from "../../../ducks/data/org-settings/selectors";
import { loadCargoTags } from "../../../ducks/data/org-settings";
import "./CargoTagSelect.scss";
import { useTranslation } from "react-i18next";
import { UFDoraSelect } from "../../Toolkit/DoraSelect";

type CargoTagSelectProps = {
  name: string;
  control: Control<any>;
  className?: string;
  valueForInputColor: string | null;
};

const CargoTagSelect = (props: CargoTagSelectProps) => {
  const { t } = useTranslation("shipmentModal");
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadCargoTags());
  }, [dispatch]);
  const cargoTags = useSelector(selectActiveCargoTags);
  const options = [
    {
      value: null,
      label: t(`inputs.cargoTagPlaceholder`),
      color: "#fff",
    },
    ...cargoTags.map((c) => ({
      value: c.id.toString(),
      label: c.description,
      color: c.color,
    })),
  ];

  const colorInputIndicator = options.find(
    (o) => o.value === props.valueForInputColor
  )?.color;

  return (
    <UFDoraSelect
      {...props}
      fullWidth
      options={options as any}
      customRenderInput={(params) => (
        <div className="cargo-tag__input-container">
          {props.valueForInputColor && (
            <div
              className="cargo-tag__input-color-indicator"
              style={{ backgroundColor: colorInputIndicator }}
            ></div>
          )}
          <TextField
            className={
              props.valueForInputColor ? "cargo-tag__input-text-field" : ""
            }
            {...params}
          />
        </div>
      )}
      customRenderOption={(pr: HTMLAttributes<HTMLLIElement>, option: any) => (
        <MenuItem {...pr} key={option.value}>
          <div
            style={{ backgroundColor: option.color }}
            className="cargo-tag__color"
          ></div>{" "}
          {option.label}
        </MenuItem>
      )}
    />
  );
};

export default CargoTagSelect;
