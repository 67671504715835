import { Suspense } from "react";
/* eslint-disable no-unused-vars */
import { BrowserRouter, Routes, Route } from "react-router-dom";
import WelcomeContainer from "../Components/Auth/welcome-container";
import RequireAuth from "../Components/RequireAuth";
import Notifications from "../Components/Notifications";
import TestPages from "./TestPages/Loader";
import AdminPages from "./AdminPages/Loader";
import LoadStandardData from "../LoadStandardData";
import RouteMapPage from "./RouteMapPage/Loader";
import PingPage from "./PingPage";
import * as Sentry from "@sentry/react";
import Layout from "../Components/Layout";
import Dialogs from "../Components/Dialogs";
import { StableNavigateContextProvider } from "../Components/StableNavigateContext";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import UndispatchedCargosMapPage from "../Components/modals/UndispatchedMapDialog";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const DoraRoutes = () => (
  <BrowserRouter>
    <StableNavigateContextProvider>
      <Notifications />
      <Dialogs />
      <SentryRoutes>
        <Route
          path="/routes/:routeId/map"
          element={
            <>
              <RouteMapPage />
            </>
          }
        />
        <Route
          path="/undispatched-map"
          element={<UndispatchedCargosMapPage />}
        />
        <Route path="/test-pages/*" element={<TestPages />} />
        <Route
          path="/admin-ui/*"
          element={
            <RequireAuth>
              <Suspense fallback={<div>Loading...</div>}>
                <AdminPages />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route path="/ping" element={<PingPage />} />
        <Route path="/welcome/*" element={<WelcomeContainer />} />
        <Route
          path="*"
          element={
            <RequireAuth redirectToHome>
              <LoadStandardData />
              <Layout>
                <Suspense fallback={<div>Loading...</div>}>
                  <AuthenticatedRoutes />
                </Suspense>
              </Layout>
            </RequireAuth>
          }
        />
      </SentryRoutes>
    </StableNavigateContextProvider>
  </BrowserRouter>
);

export default DoraRoutes;
