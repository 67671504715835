import axios, { getData } from "../../../axios";
import { createSlice } from "@reduxjs/toolkit";
import { createErrorReportingAsyncThunk } from "../../helpers";
import {
  RouteForLiveView,
  VehicleForLiveView,
  vehiclesForLiveViewT,
  routeForLiveViewT,
} from "./types";
import { FlespiMessage, flespiMessagesT } from "dora-contracts";
import * as tPromise from "io-ts-promise";
import { DateTime } from "luxon";
import { flespiTelemetryMessageReceived } from "../tracker-devices";

type InitialState = {
  vehiclesForLiveView: VehicleForLiveView[];
  routeForLiveView: RouteForLiveView | null;
  routeForLiveViewFlespiMessages: FlespiMessage[];
};

const prefix = "data/live-view";

const initialState: InitialState = {
  routeForLiveViewFlespiMessages: [],
  vehiclesForLiveView: [],
  routeForLiveView: null,
};

export const loadVehiclesForLiveView = createErrorReportingAsyncThunk(
  `${prefix}/load-vehicles`,
  async () => {
    return axios
      .get("/api/tracking/live-view/vehicles")
      .then(getData)
      .then(tPromise.decode(vehiclesForLiveViewT));
  }
);

export const loadFlespiMessagesForDevice = createErrorReportingAsyncThunk(
  `${prefix}/load-flespi-messages`,
  async (params: {
    trackerDeviceId: string;
    startDate: string;
    endDate: string;
  }) => {
    const { trackerDeviceId, startDate, endDate } = params;
    return axios
      .get(`/api/tracker-devices/${trackerDeviceId}/messages`, {
        params: {
          startDate,
          endDate,
        },
      })
      .then(getData)
      .then(tPromise.decode(flespiMessagesT));
  }
);

export const loadVehicleRouteForLiveView = createErrorReportingAsyncThunk(
  `${prefix}/load-route`,
  async (vehicleId: string) => {
    return axios
      .get(`/api/tracking/live-view/vehicles/${vehicleId}`)
      .then(getData)
      .then(tPromise.decode(routeForLiveViewT));
  }
);

const liveViewSlice = createSlice({
  name: "liveView",
  initialState,
  reducers: {
    unloadRouteForLiveView: (state) => {
      state.routeForLiveView = null;
      state.routeForLiveViewFlespiMessages = [];
    },
    flespiTelemetryMessageReceivedForLiveViewRouteTruck: (state, action) => {
      const message = action.payload.message;
      state.routeForLiveViewFlespiMessages = [
        ...state.routeForLiveViewFlespiMessages,
        {
          timestamp: DateTime.utc().toMillis(),
          "position.latitude": message.lat,
          "position.longitude": message.lon,
          "position.speed": null,
          "engine.ignition.status": null,
          stopTimeInSeconds: undefined,
          "can.fuel.consumption": null,
        },
      ];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadVehiclesForLiveView.fulfilled, (state, action) => {
        state.vehiclesForLiveView = action.payload;
      })
      .addCase(loadVehicleRouteForLiveView.fulfilled, (state, action) => {
        state.routeForLiveView = action.payload;
      })
      .addCase(loadFlespiMessagesForDevice.fulfilled, (state, action) => {
        state.routeForLiveViewFlespiMessages = [
          ...state.routeForLiveViewFlespiMessages,
          ...action.payload,
        ];
      });
  },
});

export default liveViewSlice.reducer;

export const { unloadRouteForLiveView, flespiTelemetryMessageReceivedForLiveViewRouteTruck } = liveViewSlice.actions;
