import axios, { getData } from "../../../axios";
import { createSlice } from "@reduxjs/toolkit";
import { createErrorReportingAsyncThunk } from "../../helpers";
import { decodeCargo, cargosT } from "../../app/cargo-dialog/types";
import * as tPromise from "io-ts-promise";
import { AppThunkAction } from "../../../redux-store";

const prefix = "data/cargos";

export type Cargo = Awaited<ReturnType<typeof decodeCargo>>;

type State = {
  entities: Record<string, Cargo | null>;
};

const initialState: State = {
  entities: {},
};

export const loadCargo = createErrorReportingAsyncThunk(
  `${prefix}/load-cargo`,
  async (cargoId: string): Promise<Cargo> => {
    return await axios
      .get(`/api/cargos/${cargoId}`)
      .then(getData)
      .then(decodeCargo);
  }
);

export const reloadCargo =
  (cargoId: string): AppThunkAction =>
  async (dispatch, getState) => {
    if (cargoId in getState().data.cargos.entities) {
      await dispatch(loadCargo(cargoId));
    }
  };

export const loadCargosForRoute = createErrorReportingAsyncThunk(
  `${prefix}/load-for-route`,
  async (routeId: string): Promise<Cargo[]> => {
    return await axios
      .get(`/api/cargos`, { params: { routeId } })
      .then(getData)
      .then(tPromise.decode(cargosT));
  }
);

export const setTeam = createErrorReportingAsyncThunk(
  `${prefix}/set-team`,
  async ({
    cargoId,
    teamId,
  }: {
    cargoId: string;
    teamId: string | null;
  }): Promise<Cargo> => {
    return await axios
      .patch(`/api/cargos/${cargoId}`, { teamId })
      .then(getData)
      .then(decodeCargo);
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadCargo.pending, (state, action) => {
        const cargoId = action.meta.arg;
        state.entities[cargoId] = null;
      })
      .addCase(loadCargo.fulfilled, (state, action) => {
        const {
          meta: { arg: cargoId },
          payload,
        } = action;
        state.entities[cargoId] = payload;
      })
      .addCase(loadCargo.rejected, (state, action) => {
        const cargoId = action.meta.arg;
        delete state.entities[cargoId];
      })
      .addCase(loadCargosForRoute.fulfilled, (state, action) => {
        for (const cargo of action.payload) {
          state.entities[cargo.id] = cargo;
        }
      })
      .addCase(setTeam.fulfilled, (state, action) => {
        const {
          meta: {
            arg: { cargoId },
          },
          payload,
        } = action;
        state.entities[cargoId] = payload;
      });
  },
});

export default slice.reducer;
