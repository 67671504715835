import { useAppDispatch, useSelector } from "../../../../redux-store";
import { useEffect } from "react";
import { loadDocuments } from "../../../../ducks/data/cargo-documents";
import { selectCargoDocuments } from "../../../../ducks/data/cargo-documents/selectors";
import DocumentsList from "../components/DocumentsList";

type ExistingDocumentsListProps = {
  cargoId: string;
  onRemoveItemClick: (id: string) => void;
};

const ExistingCargoDocumentsList = (props: ExistingDocumentsListProps) => {
  const { cargoId, onRemoveItemClick } = props;
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadDocuments(cargoId));
  }, [dispatch, cargoId]);
  const existingDocuments = useSelector(selectCargoDocuments(cargoId));

  return (
    <>
      {existingDocuments?.length ? (
        <DocumentsList
          documents={existingDocuments}
          onRemoveItem={onRemoveItemClick}
        />
      ) : null}
    </>
  );
};

export default ExistingCargoDocumentsList;
