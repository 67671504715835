// import "@here/maps-api-for-javascript/bin/mapsjs-ui.css";
import { useCallback, useEffect, useRef } from "react";
import { useCreateMap } from "../../../integration/useCreateMap";

interface UndispatchedCargosMapProps {
  markers: H.map.Marker[];
  onHMapLoaded: (map: H.Map) => void;
}

export const UndispatchedCargosMap = (props: UndispatchedCargosMapProps) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const { markers, onHMapLoaded } = props;
  const hMap = useRef<H.Map>();
  const ui = useRef<H.ui.UI | null>();
  const createMap = useCreateMap(false);

  // Setup map
  useEffect(() => {
    // Get the default map types from the platform object
    const mapDiv = mapRef.current;

    const { map, mapUi } = createMap(mapDiv!);
    hMap.current = map;

    ui.current = mapUi;

    // disable wheel zoom behavior
    // behaviour.current.disable(H.mapevents.Behavior.Feature.WHEEL_ZOOM);

    return () => {
      // Remove old map if this rerenders (this happens on save file while developing)
      while (mapDiv?.firstChild) {
        mapDiv.removeChild(mapDiv.firstChild);
      }
    };
  }, [createMap]);

  const onHMapLoadedCallback = useCallback(() => {
    if (!hMap.current) {
      return;
    }
    onHMapLoaded(hMap.current);
  }, [onHMapLoaded]);

  useEffect(() => {
    if (!hMap.current) {
      return;
    }

    // clear previous objects
    hMap.current.removeObjects(hMap.current.getObjects());

    markers && hMap.current.addObjects(markers);

    // Add markers to map
    const group = new H.map.Group();
    for (const marker of markers || []) {
      group.addObject(marker);
    }
    hMap.current.addObject(group);

    // remove bubbles when clicking on the map
    // hMap.current.addEventListener("tap", (evt: any) => {
    //   const bubbles = ui.current?.getBubbles();
    //   for (const b of bubbles || []) {
    //     // TODO: does not remove all bubbles
    //     ui.current?.removeBubble(b);
    //   }
    // });

    // Zoom to fit all markers
    const bounds = group.getBoundingBox();
    if (bounds) {
      hMap.current.getViewModel().setLookAtData({ bounds });
    }
    return () => {
      if (!hMap.current) {
        return;
      }
      hMap.current.removeObjects(hMap.current.getObjects());
    };
  }, [markers]);

  useEffect(() => {
    const resize = () => {
      hMap.current?.getViewPort().resize();
    };

    window.addEventListener("resize", resize);

    onHMapLoadedCallback();

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [onHMapLoadedCallback]);

  return <div id="mapContainer" ref={mapRef} />;
};
