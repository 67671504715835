import React from "react";
import * as i18n from "react-i18next";
import * as Sentry from "@sentry/react";
import { formatError } from "../helpers/error-helper";

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

const Fallback = () => {
  const { t } = i18n.useTranslation("translation");
  const headingText = t("errorBoundary.heading");
  const bodyText = t("errorBoundary.body");
  const buttonText = t("errorBoundary.reloadButtonText");
  return (
    <>
      <h2>{headingText || "Uventet fejl"}</h2>
      <p>
        {bodyText || (
          <>
            Der er desværre opstået en uventet fejl. Prøv at genindlæse siden og
            prøv igen. Hvis fejlen stadig opstår, så tag fat i os
          </>
        )}
      </p>
      <button
        onClick={() => {
          window.location.reload();
        }}
      >
        {buttonText || "Genindslæs side"}
      </button>
    </>
  );
};

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  const onError: Sentry.ErrorBoundaryProps["onError"] = (
    error,
    componentStack,
    eventId
  ) => {
    reportError({ err: formatError(error), componentStack, eventId });
  };
  return (
    <Sentry.ErrorBoundary fallback={<Fallback />} onError={onError}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
