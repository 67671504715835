import * as yup from "yup";
import Decimal from "decimal.js-light";
import { getAccumulatedCargoPieces } from "./get-accumulated-cargo-pieces";

const fieldRequiredErrorTranslateKey = "fieldRequired";

export const timeRangeMin5MinutesErr = "timeRangeMin5MinutesErr";

const stopSchema = yup.object({
  loadmeters: yup.string().required(fieldRequiredErrorTranslateKey),
  weight: yup.string(),
  colli: yup.string(),
  length: yup.string(),
  width: yup.string(),
  height: yup.string(),
  cubicMeters: yup.string(),
  ref: yup.string(),
  // tag: yup.string(),
  date: yup.string().required(),
  time: yup
    .string()
    .transform((_, value) => (value === " - " ? "" : value))
    .test("invalid", (value) => {
      if (!value) {
        return true;
      }
      const [timeStart, timeEnd] = value.split(" - ");
      if (timeEnd && !timeStart) {
        return false;
      }
      if (timeEnd && timeStart) {
        if (timeEnd < timeStart) {
          return false;
        }
      }
      return true;
    })
    .test(timeRangeMin5MinutesErr, (value) => {
      if (!value) {
        return true;
      }
      const [timeStart, timeEnd] = value.split(" - ");
      if (timeStart && timeEnd) {
        const [startHour, startMinute] = timeStart.split(":");
        const [endHour, endMinute] = timeEnd.split(":");
        const startMins = Number(startHour) * 60 + Number(startMinute);
        const endMins = Number(endHour) * 60 + Number(endMinute);
        return endMins - startMins >= 5;
      }
      return true;
    }),
  place: yup.object({}).required(fieldRequiredErrorTranslateKey),
  pieces: yup
    .array()
    .of(
      yup.object({
        unit: yup.mixed().required(),
        quantity: yup.number().required().positive().integer(),
      })
    )
    .nullable(),
});

const tempFieldRequiredBasedOnCargoType = {
  is: (v: string) => ["COOL", "FREEZE", "PHARMA"].includes(v),
  then: (s: any) => s.required(fieldRequiredErrorTranslateKey),
  otherwise: (s: any) => s.nullable(),
};

const schema = yup
  .object()
  .shape({
    type: yup.string().nullable(),
    qualities: yup.array(),
    requirements: yup.array(),
    description: yup.string(),
    minTemperature: yup
      .string()
      .when("type", tempFieldRequiredBasedOnCargoType),
    maxTemperature: yup
      .string()
      .when("type", tempFieldRequiredBasedOnCargoType),
    containerNo: yup.string().nullable(),
    orderConfirmationCheck: yup.boolean(),
    note: yup.string(),
    clientId: yup.string().nullable(),
    assignedTeamId: yup.string(),

    // client contact related
    customerContactId: yup.string().nullable(),
    clientContact: yup.string(),
    clientContactPhone: yup.string(),
    clientContactEmail: yup.string(),

    invoiceRef: yup.string().nullable(),
    ref: yup.string().nullable(),
    pickupList: yup
      .array()
      .of(stopSchema)
      .required(fieldRequiredErrorTranslateKey),
    dropoffList: yup
      .array()
      .of(stopSchema)
      .required(fieldRequiredErrorTranslateKey),
  })
  .test("lmMatch", "lmMatch", (value) => {
    const { pickupList, dropoffList } = value;
    if (!dropoffList.length) {
      return true;
    }
    const pickupLm = pickupList.reduce(
      (acc, curr) => acc.add(new Decimal(curr.loadmeters || 0)),
      new Decimal(0)
    );
    const dropoffLm = dropoffList.reduce(
      (acc, curr) => acc.add(new Decimal(curr.loadmeters || 0)),
      new Decimal(0)
    );
    return pickupLm.eq(dropoffLm);
  })
  .test("colliMatch", "colliMatch", (value) => {
    const { pickupList, dropoffList } = value;
    if (!dropoffList.length) {
      return true;
    }
    const pickupColli = pickupList.reduce(
      (acc, curr) => acc.add(new Decimal(curr.colli || 0)),
      new Decimal(0)
    );
    const dropoffColli = dropoffList.reduce(
      (acc, curr) => acc.add(new Decimal(curr.colli || 0)),
      new Decimal(0)
    );
    return pickupColli.eq(dropoffColli);
  })
  .test("unitsMatch", "unitsMatch", (value) => {
    const { pickupList, dropoffList } = value;
    if (!dropoffList.length) {
      return true;
    }
    const pickupUnits = getAccumulatedCargoPieces(pickupList as any);
    const dropoffUnits = getAccumulatedCargoPieces(dropoffList as any);
    return JSON.stringify(pickupUnits) === JSON.stringify(dropoffUnits);
  })
  .test(
    "pickupTimesBeforeDropoffTimes",
    "pickupTimesBeforeDropoffTimes",
    (value) => {
      const { pickupList, dropoffList } = value;
      if (!dropoffList.length) {
        return true;
      }
      const pickupsMaxDate = pickupList.reduce((acc, curr) => {
        const currPickupDate = new Date(curr.date);
        return currPickupDate > acc ? currPickupDate : acc;
      }, new Date(pickupList[0].date));

      for (const dropoff of dropoffList) {
        const dropoffDate = new Date(dropoff.date);
        if (dropoffDate < pickupsMaxDate) {
          return false;
        }
        if (dropoffDate.toISOString() === pickupsMaxDate.toISOString()) {
          const pickupsToCompareTo = pickupList.filter(
            (pickup) =>
              new Date(pickup.date).toISOString() === dropoffDate.toISOString()
          );

          const dropoffTime = dropoff.time;
          for (const pickup of pickupsToCompareTo) {
            const pickupTime = pickup.time;
            if (pickupTime && dropoffTime && pickupTime > dropoffTime) {
              return false;
            }
          }
        }
      }
      return true;
    }
  );

export default schema;
