import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { useEffect, useState } from "react";
import classnames from "classnames";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import "./DoraTimeRangePicker.scss";

type DoraTimeRangePickerProps = {
  onChange: (value: DoraTimeRangeValue) => void;
  error?: boolean;
  value: DoraTimeRangeValue;
};

export type DoraTimeRangeValue = {
  start: string;
  end: string;
};

type UFTimeRangePickerProps = {
  name: string;
  setValue: UseFormSetValue<any>;
  getValues: UseFormGetValues<any>;
  error?: boolean;
};

export const DoraTimeRangePicker = (props: DoraTimeRangePickerProps) => {
  const { onChange, value: propsValue, error } = props;
  const [value, setValue] = useState<DoraTimeRangeValue>({
    start: props.value?.start || "",
    end: props.value?.end || "",
  });

  useEffect(() => {
    if (propsValue.start !== value.start || propsValue.end !== value.end) {
      setValue(propsValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propsValue, setValue]);

  const onStartTimeChange = (e: any) => {
    const newValue = {
      ...value,
      start: e.target.value,
    };
    setValue(newValue);
    onChange(newValue);
  };

  const onEndTimeChange = (e: any) => {
    const newValue = {
      ...value,
      end: e.target.value,
    };
    setValue(newValue);
    onChange(newValue);
  };

  const componentClasses = classnames("dora-time-range-picker", {
    "dora-time-range-picker--error": error,
  });

  const spacerClasses = classnames("dora-time-range-picker__spacer", {
    "dora-time-range-picker__input--with-value": value.start || value.end,
  });

  return (
    <div className={componentClasses}>
      <FontAwesomeIcon
        className="dora-time-range-picker__clock-icon"
        icon={faClock}
      />
      <div className="flex justify-between items-center w-full">
        <input
          type="time"
          value={value.start}
          onChange={onStartTimeChange}
          className={
            value.start ? "dora-time-range-picker__input--with-value" : ""
          }
        />
        <div className={spacerClasses}>&nbsp;</div>
        <input
          type="time"
          value={value.end}
          onChange={onEndTimeChange}
          className={
            value.end ? "dora-time-range-picker__input--with-value" : ""
          }
        />
      </div>
    </div>
  );
};

export const UFDoraTimeRangePicker = (props: UFTimeRangePickerProps) => {
  const { name, setValue, getValues, error } = props;
  const value = getValues(name);
  let v = { start: "", end: "" };
  if (value && typeof value === "string") {
    const [start, end] = value.split(" - ");
    v = { start, end };
  }
  const onChange = (e: DoraTimeRangeValue) => {
    let v = `${e.start} - ${e.end}`;
    if (v === " - ") {
      v = "";
    }
    setValue(name, v);
  };
  return <DoraTimeRangePicker value={v} onChange={onChange} error={error} />;
};
