import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import axios, { getData } from "../../../axios";
import * as tPromise from "io-ts-promise";
import {
  FlespiMessage,
  flespiMessagesT,
  RouteStats,
  routeStatsT,
} from "dora-contracts";
import { InnerMap } from "../../../Components/dispatching-planning/map/map";
import { DateTimePicker } from "@mui/x-date-pickers";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

const STOP_POINTS_TIMESTAMP_DELTA_IN_SECONDS = 60;

const TrailerPositionHistoryDialog = ({
  trackerDeviceId,
}: {
  trackerDeviceId: string | null;
}) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [messages, setMessages] = useState<FlespiMessage[]>();
  const [routeStats, setRouteStats] = useState<RouteStats>();

  const isLoading = () => !trackerDeviceId;

  useEffect(() => {
    if (!startDate || !endDate) {
      return;
    }
    loadMessages(trackerDeviceId as string, startDate, endDate);
    loadRouteStats(trackerDeviceId as string, startDate, endDate);
  }, [startDate, endDate, trackerDeviceId]);

  const loadMessages = async (
    selector: string,
    startDate: string,
    endDate: string
  ) => {
    const params = { startDate, endDate };
    const messages = await axios
      .get(`/api/tracker-devices/${selector}/messages`, { params })
      .then(getData)
      .then(tPromise.decode(flespiMessagesT));
    setMessages(messages);
  };

  const loadRouteStats = async (
    selector: string,
    startDate: string,
    endDate: string
  ) => {
    const params = { startDate, endDate };
    const stats = await axios
      .get(`/api/tracker-devices/${selector}/messages/stats`, { params })
      .then(getData)
      .then(tPromise.decode(routeStatsT));
    setRouteStats(stats);
  };

  const buildLine = (messages: FlespiMessage[]) => {
    const segments: H.geo.LineString[] = [];
    if (messages.length < 2) {
      return;
    }
    for (let i = 1; i < messages.length; i++) {
      const segment = new H.geo.LineString();
      const prev_lat = messages[i - 1]["position.latitude"];
      const prev_lng = messages[i - 1]["position.longitude"];
      const curr_lat = messages[i]["position.latitude"];
      const curr_lng = messages[i]["position.longitude"];
      segment.pushPoint({ lat: prev_lat, lng: prev_lng });
      segment.pushPoint({ lat: curr_lat, lng: curr_lng });
      segments.push(segment);
    }
    return new H.geo.MultiLineString(segments);
  };

  const buildStopPoints = (messages: FlespiMessage[]) => {
    let filteredMessages = messages.filter(
      (message) =>
        message["position.speed"] === 0 && !message["engine.ignition.status"]
    );
    filteredMessages = filterStopPointsByTimestamp(filteredMessages);
    calculateStopPointsTime(filteredMessages);
    return filteredMessages.map((message) => {
      const cords = {
        lat: message["position.latitude"],
        lng: message["position.longitude"],
      };
      const markerContainer = document.createElement("div");
      markerContainer.innerHTML = message.stopTimeInSeconds
        ? `<p>${Math.round(message.stopTimeInSeconds / 60)}<p/>`
        : "";
      markerContainer.className = "stop-point-marker";
      return new H.map.DomMarker(cords, {
        icon: new H.map.DomIcon(markerContainer),
        data: "",
      });
    });
  };

  const filterStopPointsByTimestamp = (messages: FlespiMessage[]) => {
    return messages.filter((item, index, array) => {
      if (index === 0) {
        return true;
      }
      const prevTimestamp = array[index - 1].timestamp;
      const currentTimestamp = item.timestamp;
      const timeDifference = currentTimestamp - prevTimestamp;
      return timeDifference >= STOP_POINTS_TIMESTAMP_DELTA_IN_SECONDS;
    });
  };

  const calculateStopPointsTime = (messages: FlespiMessage[]) => {
    for (let i = 0; i < messages.length - 1; i++) {
      const currentTimestamp = messages[i].timestamp;
      const nextTimestamp = messages[i + 1].timestamp;
      messages[i].stopTimeInSeconds = nextTimestamp - currentTimestamp;
    }
  };

  const formatDateString = (timestamp: number): string => {
    const dateObject = new Date(timestamp * 1000);
    const dateParts = dateObject.toISOString().split("T");
    const date = dateParts[0];
    const time = dateParts[1].substring(0, 8);
    return `${date} ${time}`;
  };

  const renderMessages = (messages: FlespiMessage[]) => {
    const rows = [];
    for (let i = 0; i < messages.length; i++) {
      rows.push({
        id: i,
        date: formatDateString(messages[i].timestamp),
        speed: messages[i]["position.speed"],
      });
    }
    const columns: GridColDef[] = [
      { field: "date", headerName: "DateTime", width: 200 },
      { field: "speed", headerName: "Speed (km/h)", width: 100 },
    ];
    return (
      <div style={{ height: "600px", width: "400px" }}>
        <DataGrid rows={rows} columns={columns} />
      </div>
    );
  };

  const renderStats = (stats: RouteStats) => {
    return (
      <div style={{ marginLeft: "100px" }}>
        <p>Fuel consumption (liters): {stats.totalFuelConsumption}</p>
      </div>
    );
  };

  return (
    <div style={{ height: "100%" }} className="flex flex-col">
      <h1>History data</h1>
      {isLoading() ? (
        <div>Loading...</div>
      ) : (
        <div className="grow flex gap-4">
          <div className="flex gap-2 flex-col">
            <DateTimePicker
              ampm
              format="yyyy-MM-dd hh:mm"
              label="From date"
              value={startDate}
              onChange={(date) => setStartDate(date)}
            />
            <DateTimePicker
              ampm
              format="yyyy-MM-dd hh:mm"
              label="To date"
              value={endDate}
              onChange={(date) => setEndDate(date)}
            />
            <div>{messages ? renderMessages(messages) : null}</div>
            <div>{routeStats ? renderStats(routeStats) : null}</div>
          </div>
          <Box
            sx={{
              display: "flex",
              flexGrow: 1,
              "&>div": { flexGrow: 1 },
            }}
          >
            <InnerMap
              lineStrings={messages && buildLine(messages)}
              markers={messages && buildStopPoints(messages)}
            />
          </Box>
        </div>
      )}
    </div>
  );
};

export default TrailerPositionHistoryDialog;
