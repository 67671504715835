import axios, { getData } from "../../../../axios";
import { createSlice } from "@reduxjs/toolkit";
import { customerContactT, CustomerContact } from "./types";
import * as tPromise from "io-ts-promise";
import * as t from "io-ts";
import { createErrorReportingAsyncThunk } from "../../../helpers";

const prefix = "economics/data/customer-contacts";
const customerContactsT = t.array(customerContactT);

type State = {
  entities: Record<string, CustomerContact>;
  idsByClientId: Record<string, string[]>;
};

const initialState: State = {
  entities: {},
  idsByClientId: {},
};

export const loadCustomerContacts = createErrorReportingAsyncThunk(
  `${prefix}/load-customer-contacts`,
  async (clientId: string): Promise<CustomerContact[]> => {
    return axios
      .get(`/api/e-conomic/customers/${clientId}/contacts`)
      .then(getData)
      .then(tPromise.decode(customerContactsT));
  }
);

export const contactCreated = createErrorReportingAsyncThunk(
  `${prefix}/customer-created`,
  async (contact: unknown): Promise<CustomerContact> => {
    return await tPromise.decode(customerContactT)(contact);
  }
);

export const contactUpdated = createErrorReportingAsyncThunk(
  `${prefix}/customer-updated`,
  async (contact: unknown): Promise<CustomerContact> => {
    return await tPromise.decode(customerContactT)(contact);
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadCustomerContacts.fulfilled, (state, action) => {
        state.idsByClientId = {};
        state.entities = {};
        const clientId = action.meta.arg;
        const ids = action.payload.map((x) => x.id);
        state.idsByClientId[clientId] = ids;
        for (const contact of action.payload) {
          state.entities[contact.id] = contact;
        }
      })
      .addCase(contactUpdated.fulfilled, (state, action) => {
        const contact = action.payload;
        state.entities[contact.id] = contact;
      })
      .addCase(contactCreated.fulfilled, (state, action) => {
        const contact = action.payload;
        const { id, clientId } = contact;
        state.entities[id] = contact;
        if (state.idsByClientId[clientId]) {
          state.idsByClientId[clientId].push(id);
        }
      });
  },
});

export default slice.reducer;
