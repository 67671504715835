import { useStatefulFields } from "../../hooks/useStatefulFields";
import { useAuthSubmit } from "../../hooks/useAuthSubmit";
import {
  Typography,
  TextField,
  Button,
  Stack,
  Container,
  FormHelperText,
} from "@mui/material";
import { Link } from "react-router-dom";
import ContentStyle from "./ContentStyle";

export default function LoginForm() {
  const [values, handleChange] = useStatefulFields();
  const [error, handleSubmit] = useAuthSubmit("/login", values);

  return (
    <>
      <Typography inline align="right" style={{ paddingTop: 20 }}>
        <Link to="/welcome">
          <Button variant="text">Tilmeld dig her</Button>
        </Link>
      </Typography>
      <ContentStyle>
        <Container sx={{ p: 3, mt: 16 }} maxWidth="xs">
          <form autoComplete="on">
            <Stack spacing={3}>
              <Typography variant="h4">Log ind</Typography>
              <TextField
                id="login-email-field"
                autoFocus
                fullWidth
                name="email"
                label="E-mail"
                type="email"
                variant="outlined"
                onChange={handleChange}
              />
              <TextField
                id="login-password-field"
                fullWidth
                name="password"
                label="Password"
                type="password"
                variant="outlined"
                onChange={handleChange}
              />
              {error && (
                <FormHelperText error>
                  Noget gik galt, forsøg venligst igen.
                </FormHelperText>
              )}
              <Button
                id="login-submit-button"
                color="primary"
                fullWidth
                type="submit"
                variant="contained"
                onClick={handleSubmit}
              >
                Log in
              </Button>
              <Link to="/welcome/reset">
                <Button variant="text">Glemt password?</Button>
              </Link>
            </Stack>
          </form>
        </Container>
      </ContentStyle>
    </>
  );
}
