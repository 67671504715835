import * as t from "io-ts";
import nullable from "../../../../types/nullable";

const agreementGrantStatus = t.union([
  t.literal("UNINITIALIZED"),
  t.literal("UNVERIFIED"),
  t.literal("VERIFIED"),
  t.literal("VERIFICATION_ERROR"),
  t.literal("INVALID"),
]);

export const configurationResponseT = t.strict({
  agreementGrantStatus,
});

export const defaultsT = t.strict({
  currencyCode: nullable(t.string),
  vatZoneNumber: nullable(t.number),
  layoutNumber: nullable(t.number),
  paymentTermsNumber: nullable(t.number),
});

export const optionsT = t.strict({
  defaults: defaultsT,
  vatZones: t.array(
    t.strict({
      vatZoneNumber: t.number,
      name: t.string,
    })
  ),
  layouts: t.array(
    t.strict({
      layoutNumber: t.number,
      name: t.string,
    })
  ),
  paymentTerms: t.array(
    t.strict({
      paymentTermsNumber: t.number,
      name: t.string,
    })
  ),
});

export type Options = t.TypeOf<typeof optionsT>;
export type ConfigurationResponse = t.TypeOf<typeof configurationResponseT>;
export type AgreementGrantStatus = t.TypeOf<typeof agreementGrantStatus>;
export type Defaults = t.TypeOf<typeof defaultsT>;
