import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import DoraLinkButton from "../../../Toolkit/DoraLinkButton";
import DoraButton from "../../../Toolkit/DoraButton";
import "./DocumentsList.scss";

type DocumentsListProps = {
  documents: { id: string; url: string; name: string }[];
  onRemoveItem: (id: string) => void;
  controlsDisabled?: boolean;
  className?: string;
};

const DocumentsList = (props: DocumentsListProps) => {
  const { documents, onRemoveItem, controlsDisabled, className } = props;
  const { t } = useTranslation("components");
  const classes = clsx("uploaded-documents-list", className);
  return (
    <div className={classes}>
      {documents.map((f) => (
        <div key={f.id} className="uploaded-documents-list__item">
          <div className="flex items-center gap-2">
            <div className="flex-grow">
              <DoraLinkButton
                className="flex items-center gap-2 whitespace-nowrap"
                onClick={() => window.open(f.url, "_blank")}
                role="link"
              >
                <FontAwesomeIcon icon={faFile} />
                <div className="max-w-sm truncate">{f.name}</div>
              </DoraLinkButton>
            </div>
            <DoraButton
              className="document-list-item__remove"
              disabled={controlsDisabled}
              aria-label={t("buttonLabels.remove")}
              variant="ghost"
              onClick={() => onRemoveItem(f.id)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </DoraButton>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DocumentsList;
