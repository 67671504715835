import * as t from "io-ts";

const accountT = t.type({
  id: t.string,
  accountNumber: t.number,
  name: t.string,
  disabled: t.boolean,
});

export const accountsT = t.array(accountT);

export type Account = t.TypeOf<typeof accountT>;
