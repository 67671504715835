import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import "./VehicleCirclePin.scss";
import { CSSProperties } from "react";

const VehicleCirclePin = ({
  large,
  style,
  green,
}: {
  large?: boolean;
  style?: CSSProperties;
  green?: boolean;
}) => {
  const classes = classnames("vehicle-circle-pin", {
    "vehicle-circle-pin--large": large,
    "green": green,
  });
  return (
    <div className={classes} style={style}>
      <FontAwesomeIcon icon={faTruck} />
    </div>
  );
};

export default VehicleCirclePin;
