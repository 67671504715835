import axios from "../../axios";
import { useParams } from "react-router-dom";
import * as tPromise from "io-ts-promise";
import { createOutgoingInvoiceTypes } from "dora-contracts";
import * as t from "io-ts";
import { useEffect, useState } from "react";
import { getData } from "../../axios";
import { notify } from "../../ducks/notifications";
import { useAppDispatch } from "../../redux-store";
import { useL10n } from "../../l10n";
import { useSelector } from "react-redux";
import { selectClient } from "../../ducks/data/clients/selectors";
import { Button } from "@mui/material";
import { selectProduct } from "../../ducks/data/products/selectors";
import { loadProducts } from "../../ducks/data/products";
import Decimal from "decimal.js-light";
import { loadUninvoicedClientsInfo } from "../../ducks/data/consolidated-invoices";

const { outgoingInvoiceT, outgoingInvoiceLineT } = createOutgoingInvoiceTypes({
  decimal: t.string,
});
type OutgoingInvoice = t.TypeOf<typeof outgoingInvoiceT>;
type OutgoingInvoiceLine = t.TypeOf<typeof outgoingInvoiceLineT>;
type OutgoingInvoiceStatus = OutgoingInvoice["status"];

const InvoiceStatus = ({ status }: { status: OutgoingInvoiceStatus }) => {
  const statusText = (() => {
    switch (status) {
      case "INVOICE_CREATED":
        return "Oprettet, men ikke sendt";
      case "INVOICE_SENT":
        return "Sendt";
      case "INVOICE_FAILED":
        return "Fejl";
      default:
        return null;
    }
  })();
  return <>{statusText}</>;
};

const InvoiceLine = ({ line }: { line: OutgoingInvoiceLine }) => {
  const l10n = useL10n();
  const product = useSelector(selectProduct(line.productId)) || null;
  return (
    <>
      <div>
        Product: {product && `${product.productNumber} - ${product.name}`}
      </div>
      <div>{line.description}</div>
      <div>Antal: {l10n.formatNumber(line.quantity)}</div>
      <div>Stk pris: {l10n.formatCurrency(line.unitNetPrice)}</div>
      <div>
        (I alt:{" "}
        {l10n.formatNumber(new Decimal(line.quantity).mul(line.unitNetPrice))})
      </div>
      <hr />
    </>
  );
};

const ShowInvoice = ({
  invoice,
  onResendClick,
}: {
  invoice: OutgoingInvoice;
  onResendClick: () => void;
}) => {
  const l10n = useL10n();
  const { date, status, clientId, otherRef, ...rest } = invoice;
  const client = useSelector(selectClient(clientId));
  return (
    <>
      <h1>Faktura</h1>
      <div>
        Status: <InvoiceStatus status={status} />
      </div>
      {status !== "INVOICE_SENT" && (
        <div>
          <div>
            Fakturaen er endnu ikke oprettet. Prøv evt. igen ved at trykke på
            knappen her. Hvis det stadig fejler, så kontakt DORA.
          </div>
          <Button variant="outlined" onClick={onResendClick}>
            Send faktura
          </Button>
        </div>
      )}
      <div>Dato: {l10n.formatDate(date)}</div>
      <div>Kunde: {client && client.client}</div>
      <div>Ref: {otherRef}</div>
      <hr />
      <h2>Linjer</h2>
      {rest.lines.map((line, idx) => (
        <InvoiceLine key={idx} line={line} />
      ))}
      <h2>Attachments</h2>
      {rest.attachments.length > 0 && (
        <ol>
          {rest.attachments.map((attachment, idx) => (
            <li key={idx}>
              <a target="_blank" rel="noreferrer" href={attachment.url}>
                Original
              </a>{" "}
              -{" "}
              <a target="_blank" rel="noreferrer" href={attachment.pdfUrl}>
                Som PDF
              </a>
            </li>
          ))}
        </ol>
      )}
    </>
  );
};

const OutgoingInvoicePage = () => {
  const { id } = useParams();
  const [invoice, setInvoice] = useState<OutgoingInvoice | null>(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadProducts());
    dispatch(loadUninvoicedClientsInfo());
  }, [dispatch]);
  useEffect(() => {
    axios
      .get(`/api/finance/outgoing-invoices/${id}`)
      .then(getData)
      .then(tPromise.decode(outgoingInvoiceT))
      .then(setInvoice)
      .catch(() => {
        dispatch(notify({ type: "error", message: "Error loading invoice" }));
      });
  }, [dispatch, id]);
  const onResendClick = () => {
    axios
      .post(`/api/e-conomic/invoices/${id}/resend`)
      .then(() => axios.get(`/api/finance/outgoing-invoices/${id}`))
      .then(getData)
      .then(tPromise.decode(outgoingInvoiceT))
      .then(setInvoice)
      .catch(() => {
        dispatch(notify({ type: "error", message: "Error resending invoice" }));
      });
  };

  return invoice && <ShowInvoice {...{ invoice, onResendClick }} />;
};

export default OutgoingInvoicePage;
