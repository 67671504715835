import { useMemo, ComponentProps, HTMLAttributes } from "react";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField,
  TextFieldVariants,
} from "@mui/material";

export interface Option {
  id: string;
  label: string;
}

const GenericAutocomplete = ({
  label,
  options,
  value,
  onChange,
  required,
  variant,
  textFieldProps,
  autoFocus,
  disableAutoSelect,
  onInputChange,
  renderOption,
  renderInput,
  ...rest
}: {
  label: string;
  options: Option[];
  value: string | null;
  onChange: (newValue: string | null) => void;
  onInputChange?: (e: any, v: any) => void;
  required?: boolean;
  variant?: TextFieldVariants;
  fullWidth?: boolean;
  textFieldProps?: Partial<ComponentProps<typeof TextField>>;
  autoFocus?: boolean;
  disableAutoSelect?: boolean;
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: any
  ) => JSX.Element;
  renderInput?: (params: AutocompleteRenderInputParams) => JSX.Element;
}) => {
  const selectedValue = useMemo(
    () => options.find((x) => x.id === value) || null,
    [value, options]
  );

  const onInputChangeProp = onInputChange && { onInputChange };
  const renderOptionProp = renderOption && { renderOption };
  const renderInputProp = renderInput
    ? { renderInput }
    : {
        renderInput: (params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            variant={variant}
            required={required}
            label={label}
            autoFocus={autoFocus}
            {...textFieldProps}
          />
        ),
      };
  return (
    <Autocomplete
      options={options}
      value={selectedValue}
      fullWidth={rest.fullWidth}
      onChange={(_e, v) => {
        onChange(v && v.id);
      }}
      {...onInputChangeProp}
      {...renderOptionProp}
      {...renderInputProp}
      selectOnFocus
      autoSelect={!disableAutoSelect}
      autoHighlight={autoFocus}
    />
  );
};

export default GenericAutocomplete;
