import * as t from "io-ts";
import { createDriverEvents } from "dora-contracts";

const ts = createDriverEvents({ decimal: t.string, dateTime: t.string });

export const cargoEventsT = ts.driverEventsT;

export type WeightAddedCargoEvent = t.TypeOf<typeof ts.cargoWeightAddedEventT>;
export type DriverLoggedInEvent = t.TypeOf<typeof ts.driverLoggedInEventT>;
export type CargoCollectedEvent = t.TypeOf<typeof ts.cargoCollectedEventT>;
export type CargoDeliveredEvent = t.TypeOf<typeof ts.cargoDeliveredEventT>;
export type CargoEvent = t.TypeOf<typeof ts.driverEventT>;
export type PlannedLocation = t.TypeOf<typeof ts.plannedLocationInnerT>;
