let platform: H.service.Platform;

export const getPlatform = () => {
  if (!platform) {
    platform = new H.service.Platform({
      apikey: import.meta.env.VITE_APP_HERE_MAP!,
    });
  }
  return platform;
};
