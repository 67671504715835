import { RootState } from "../../../redux-store";
import { createSelector } from "@reduxjs/toolkit";

export const selectCargoTags = (state: RootState) =>
  state.data.orgSettings.cargoTags;

export const selectActiveCargoTags = createSelector(selectCargoTags, (tags) =>
  tags.filter((tag) => tag.description)
);

export const selectOrgSettings = (state: RootState) => state.data.orgSettings;

export const selectHideLoadmeters = (state: RootState) =>
  state.data.orgSettings.hideLoadmeters;

export const selectHideCargoDescription = (state: RootState) =>
  state.data.orgSettings.hideCargoDescription;

export const selectHideInvoiceRef = (state: RootState) =>
  state.data.orgSettings.hideInvoiceRef;

export const selectDriverShouldRegisterTimeDefaultSetting = createSelector(
  selectOrgSettings,
  (orgSettings) => orgSettings.showCargoTimeTrackingToDriver
);
