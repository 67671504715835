import { createTheme } from "@mui/material/styles";

function pxToRem(value) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({ sm, md, lg }) {
  return {
    "@media (min-width:600px)": {
      fontSize: pxToRem(sm),
    },
    "@media (min-width:900px)": {
      fontSize: pxToRem(md),
    },
    "@media (min-width:1200px)": {
      fontSize: pxToRem(lg),
    },
  };
}

export const theme = createTheme({
  map: {
    layer: "normal.night.grey",
  },
  // breakpoints: {
  //     values: {
  //         xs: 0,
  //         sm: 600,
  //         md: 900,
  //         lg: 1200,
  //         xl: 1536,
  //     },
  // },
  palette: {
    mode: "light",
    background: {
      default: "#f6f8fb",
    },

    primary: {
      lightened: "rgba(79, 118, 186, 0.2)",
      light: "#4e7bbc", // ----primary-500
      main: "#3e68a3", // --primary-600
      dark: "#274268", // --primary-800
    },
    secondary: {
      lightened: "rgba(185, 215, 180, 0.5)",
      light: "#C8E1C8",
      main: "#B9D7B4",
    },
    neutral: {
      light: "#fff",
      main: "#f0f0f5", // F6F8FB
      dark: "",
      contrastText: "#000",
      // accentText: "rgba(0, 0, 0, 0.8)",
      accentText: "red",
    },
  },
  text: {
    neutral: "red",
    // neutral: "rgba(0, 0, 0, 0.8)",
  },
  // **Colors**:
  // - Text: #1A1A1A
  // - Background light grey: #F6F8FB
  // - Black and white

  typography: {
    lineHeight: "4",
    fontFamily: "Work Sans",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 500,
    fontWeightBold: 500,
    h1: {
      fontWeight: 500,
      lineHeight: 80 / 64,
      fontSize: pxToRem(24),
      ...responsiveFontSizes({ sm: 24, md: 24, lg: 24 }),
    },
    h2: {
      fontWeight: 500,
      lineHeight: 64 / 48,
      fontSize: pxToRem(22),
      ...responsiveFontSizes({ sm: 22, md: 22, lg: 22 }),
    },
    h3: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(14),
      ...responsiveFontSizes({ sm: 14, md: 14, lg: 14 }),
    },
    h4: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(12),
      ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
    },
    h5: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(10),
      ...responsiveFontSizes({ sm: 14, md: 16, lg: 16 }),
    },
    h6: {
      fontWeight: 700,
      lineHeight: 28 / 18,
      fontSize: pxToRem(8),
      ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
    },
    subtitle1: {
      fontWeight: 600,
      lineHeight: 1.5,
      fontSize: pxToRem(16),
    },
    subtitle2: {
      fontWeight: 600,
      lineHeight: 18 / 12,
      fontSize: pxToRem(14),
    },
    body1: {
      lineHeight: 1.5,
      fontSize: pxToRem(16),
    },
    body2: {
      lineHeight: 18 / 12,
      fontSize: pxToRem(13),
    },
    caption: {
      lineHeight: 1.5,
      fontSize: pxToRem(12),
    },
    overline: {
      fontWeight: 600,
      lineHeight: 1.5,
      fontSize: pxToRem(12),
      letterSpacing: 1.05,
      textTransform: "uppercase",
    },
    button: {
      fontWeight: 600,
      letterSpacing: 0.2,
      fontSize: pxToRem(14),
      textTransform: "unset",
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        margin: "dense",
        size: "small",
        fullWidth: true,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        contained: {},
      },
    },
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      default: "#212121",
    },
    primary: {
      lightened: "rgba(155, 180, 230, 0.4)",
      main: "#9BB4E6",
    },
    secondary: {
      lightened: "rgba(200, 225, 200, 0.5)",
      main: "#C8E1C8",
    },
    neutral: {
      light: "#121212",
      main: "",
      dark: "",
      contrastText: "#FFF",
    },
  },
  text: {
    neutral: "rgba(255, 255, 255, 0.8)",
  },
  typography: {
    lineHeight: "4",
    fontFamily: "Work Sans",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontWeight: 700,
      lineHeight: 80 / 64,
      fontSize: pxToRem(40),
      ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
    },
    h2: {
      fontWeight: 700,
      lineHeight: 64 / 48,
      fontSize: pxToRem(32),
      ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
    },
    h3: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(24),
      ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
    },
    h4: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(20),
      ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
    },
    h5: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(14),
      ...responsiveFontSizes({ sm: 14, md: 16, lg: 16 }),
    },
    h6: {
      fontWeight: 700,
      lineHeight: 28 / 18,
      fontSize: pxToRem(17),
      ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
    },
    subtitle1: {
      fontWeight: 600,
      lineHeight: 1.5,
      fontSize: pxToRem(16),
    },
    subtitle2: {
      fontWeight: 600,
      lineHeight: 18 / 12,
      fontSize: pxToRem(14),
    },
    body1: {
      lineHeight: 1.5,
      fontSize: pxToRem(16),
    },
    body2: {
      lineHeight: 18 / 12,
      fontSize: pxToRem(13),
    },
    caption: {
      lineHeight: 1.5,
      fontSize: pxToRem(12),
    },
    overline: {
      fontWeight: 600,
      lineHeight: 1.5,
      fontSize: pxToRem(12),
      letterSpacing: 1.05,
      textTransform: "uppercase",
    },
    button: {
      fontWeight: 600,
      letterSpacing: 0.2,
      fontSize: pxToRem(14),
      textTransform: "unset",
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        margin: "dense",
        size: "small",
        fullWidth: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
        contained: {
          border: "solid primary 1px",
          backgroundColor: "primary.main",
          hover: {
            border: "solid 1px",
            backgroundColor: "primary.lightened",
            borderColor: "primary.lightened",
          },
        },
      },
    },
  },
});
