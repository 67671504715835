import { useEffect } from "react";
import { Button, Alert, AlertTitle, Container } from "@mui/material";
import UnauthenticatedLayout from "./UnauthenticatedLayout";
import ContentStyle from "./Auth/ContentStyle";
import { useAppDispatch } from "../redux-store";
import { checkAuthState } from "../ducks/auth";
import { useTranslation } from "react-i18next";

const AuthErrorPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("translation");
  const reloadPageClick = () => window.location.reload();
  useEffect(() => {
    const i = setInterval(() => dispatch(checkAuthState()), 1000);
    return () => clearInterval(i);
  }, [dispatch]);
  return (
    <UnauthenticatedLayout>
      <ContentStyle>
        <Container sx={{ p: 3, mt: 10 }} maxWidth="xs">
          <Alert severity="error">
            <AlertTitle>{t("serverError.headingText")}</AlertTitle>
            <p>{t("serverError.bodyText")}</p>
            <Button variant="outlined" onClick={reloadPageClick}>
              {t("serverError.reloadButtonText")}
            </Button>
          </Alert>
        </Container>
      </ContentStyle>
    </UnauthenticatedLayout>
  );
};

export default AuthErrorPage;
