import axios, { getData } from "../../../axios";
import { createSlice } from "@reduxjs/toolkit";
import { usersT, userT } from "dora-contracts";
import * as t from "io-ts";
import * as tPromise from "io-ts-promise";
import { createErrorReportingAsyncThunk } from "../../helpers";
import keyBy from "lodash/keyBy";

type User = t.TypeOf<typeof userT>;

const prefix = "data/dispatchers";

type State = {
  // dispatchers: User[];
  ids: string[];
  entities: Record<string, User>;
};

const initialState: State = { ids: [], entities: {} };

export const loadUsers = createErrorReportingAsyncThunk(
  `${prefix}/load-users`,
  (): Promise<User[]> =>
    axios.get("/api/dispatchers").then(getData).then(tPromise.decode(usersT))
);

const updateTeam = createErrorReportingAsyncThunk(
  `${prefix}/update-teams`,
  async ({
    dispatcherId,
    teamId,
    method,
  }: {
    dispatcherId: string;
    teamId: string;
    method: "put" | "delete";
  }): Promise<User> => {
    return await axios[method](
      `/api/dispatchers/${dispatcherId}/teams/${teamId}`
    )
      .then(getData)
      .then(tPromise.decode(userT));
  }
);

export const removeTeamFromDispatcher = ({
  dispatcherId,
  teamId,
}: {
  dispatcherId: string;
  teamId: string;
}) => updateTeam({ dispatcherId, teamId, method: "delete" });

export const addTeamToDispatcher = ({
  dispatcherId,
  teamId,
}: {
  teamId: string;
  dispatcherId: string;
}) => updateTeam({ teamId, dispatcherId, method: "put" });

export const setDefaultTeam = createErrorReportingAsyncThunk(
  `${prefix}/set-default-team`,
  ({
    dispatcherId,
    teamId,
  }: {
    teamId: string;
    dispatcherId: string;
  }): Promise<User> => {
    return axios
      .patch(`/api/dispatchers/${dispatcherId}`, {
        defaultTeamId: teamId,
      })
      .then(getData)
      .then(tPromise.decode(userT));
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadUsers.fulfilled, (state, { payload }) => {
        state.ids = payload.map((x) => x.id);
        state.entities = keyBy(payload, "id");
      })
      .addCase(updateTeam.fulfilled, (state, { payload }) => {
        state.entities[payload.id] = payload;
      })
      .addCase(setDefaultTeam.fulfilled, (state, { payload }) => {
        state.entities[payload.id] = payload;
      });
  },
});

export default slice.reducer;
