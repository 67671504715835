import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Decimal from "decimal.js-light";
import axios, { isAxiosError } from "../../axios";
import { deleteCargo } from "../../ducks/app/cargo-dialog";
import { arrayUniqueByKey } from "../../helpers/array-helper";
import {
  upsertMultipleValuesInArray,
  upsertValueInArray,
} from "../../helpers/reducer-helper";
import {
  CargoMatching,
  CargoMatchingDto,
  Matching,
} from "../../models/matching";
import { loadRouting } from "../data/routing";
import { createErrorReportingAsyncThunk } from "../helpers";
import { notifyL } from "../notifications";
import { SmartMatchState } from "./types";

export const getMatchings = createErrorReportingAsyncThunk(
  "smartMatch/SMART_MATCH_MATCHES",
  async () => {
    const result = await axios.get<{
      success: boolean;
      matchings: CargoMatchingDto[];
    }>("/api/get-cargo-matches");
    return result.data;
  }
);

export const getSmartMatches = createErrorReportingAsyncThunk(
  "smartMatch/MATCHED_SMART_MATCH",
  async (route_id: string) => {
    const response = await axios.get<{
      success: boolean;
      matches: CargoMatchingDto[];
    }>(`/api/get-matches/${route_id}`);

    const cargos: CargoMatching[] = response.data.matches.map((cargoMatch) => ({
      ...cargoMatch,
      matched_route_id: route_id,
    }));
    let matchings: Matching[] = cargos.map((match) => {
      return {
        id: match.match,
        // pickup_dist: match.pickup_dist,
        // dropoff_dist: match.dropoff_dist,
        price: match.price,
        requested: match.requested,
        accepted: match.accepted,
        matched_route_id: match.matched_route_id,
        cargo_id: match.cargo_id,
        cargo_info_id: match.cargo_id,
        // cargo_holder_id: match.cargo_holder_id,
        first: match.first,
        // last: match.last,
        email: match.email,
        company_name: match.company_name,
      };
    });

    matchings = arrayUniqueByKey(matchings, "id");

    return {
      cargos,
      matchings,
    };
  }
);

export const requestPrice = createErrorReportingAsyncThunk(
  "smartMatch/SMART_MATCH_REQUEST_PRICE",
  async (matching_id: string) => {
    await axios.post(`/api/request-price/${matching_id}`);
    return { matching_id };
  }
);

export interface SetPriceArgs {
  matching_id: string;
  price: number;
}

export const setPrice = createErrorReportingAsyncThunk(
  "smartMatch/SMART_MATCH_SET_PRICE",
  async (args: SetPriceArgs) => {
    await axios.post(`/api/set-price/${args.matching_id}/${args.price}`);
    return args;
  }
);

export const acceptPrice = createErrorReportingAsyncThunk(
  "smartMatch/SMART_MATCH_ACCEPT_PRICE",
  async ({ id, price }: { id: string; price: Decimal }, thunkAPI) => {
    try {
      await axios.post(`/api/accept-price/${id}`, {
        amount: price.toString(),
        currency: "DKK",
      });
    } catch (e) {
      if (isAxiosError(e) && e.response?.status === 409) {
        thunkAPI.dispatch(
          notifyL({
            namespace: "notifications",
            key: "priceIsIncorrectLoadPage",
            type: "error",
            keepOpen: true,
          })
        );
      }
      throw e;
    }
  }
);

const initialState: SmartMatchState = {
  cargos: [],
  matchings: [],
};

const smartMatchSlice = createSlice({
  name: "smartMatch",
  initialState,
  reducers: {
    deleteSmartMatch: (
      state,
      action: PayloadAction<{ matchId: string; cargoInfoId: string }>
    ) => {
      state.matchings = state.matchings.filter(
        (x) => x.id !== action.payload.matchId
      );
      state.cargos = state.cargos.filter(
        (x) => (x as any).match !== action.payload.matchId
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMatchings.fulfilled, (state, action) => {
      state.matchings = upsertMultipleValuesInArray(
        state.matchings,
        action.payload.matchings
      );
    });
    builder.addCase(getSmartMatches.fulfilled, (state, action) => {
      state.cargos = upsertMultipleValuesInArray(
        state.cargos,
        action.payload.cargos
      );
      state.matchings = upsertMultipleValuesInArray(
        state.matchings,
        action.payload.matchings
      );
    });
    builder.addCase(requestPrice.fulfilled, (state, action) => {
      const matching = state.matchings.find(
        (matching) => matching.id === action.payload.matching_id
      );
      if (matching) {
        const matchingCopy = { ...matching, requested: true };

        state.matchings = upsertValueInArray(state.matchings, matchingCopy);
      }
    });
    builder.addCase(setPrice.fulfilled, (state, action) => {
      const matching = state.matchings.find(
        (matching) => matching.id === action.payload.matching_id
      );
      if (matching) {
        const matchingCopy = {
          ...matching,
          price: action.payload.price,
        };
        return {
          ...state,
          matchings: upsertValueInArray(state.matchings, matchingCopy),
        };
      }
    });
    builder.addCase(acceptPrice.fulfilled, (state, action) => {
      const matching = state.matchings.find(
        (matching) => matching.id === action.meta.arg.id
      );
      if (matching) {
        const matchingCopy = { ...matching, accepted: true };
        return {
          ...state,
          matchings: upsertValueInArray(state.matchings, matchingCopy),
        };
      }
    });
    builder.addCase(deleteCargo.fulfilled, (state, action) => {
      state.cargos = state.cargos.filter((x) => x.cargo_id !== action.meta.arg);
      state.matchings = state.matchings.filter(
        (x) => x.cargo_info_id !== action.meta.arg
      );
    });
    builder.addCase(loadRouting.pending, (state, action) => {
      if (action.meta.arg !== state.routeId) {
        delete state.newDistance;
        delete state.originalDistance;
      }
    });
    builder.addCase(loadRouting.fulfilled, (state, action) => {
      if (action.payload) {
        state.newDistance = action.payload.distanceInKm;
        if (!state.originalDistance) {
          state.originalDistance = action.payload.distanceInKm;
        }
        state.routeId = action.meta.arg;
      }
    });
  },
});

export const { deleteSmartMatch } = smartMatchSlice.actions;

export default smartMatchSlice.reducer;
