import React, { FC, useCallback } from "react";
import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  onClick: () => void;
  className?: string;
  disabled?: boolean;
}
export const CloseButton: FC<Props> = React.memo((props) => {
  const { t } = useTranslation("components");
  const onClick = props.onClick;
  const onClickHandler = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <IconButton
      onClick={onClickHandler}
      type="button"
      disableRipple
      disableFocusRipple
      disableTouchRipple
      className={props.className}
      disabled={props.disabled}
      aria-label={t("buttonLabels.close")}
      sx={{
        padding: 0,
        margin: 0,
      }}
    >
      <Close style={{ color: "var(--gray-500)", fontSize: 20 }} />
    </IconButton>
  );
});
