import React, { FC } from "react";
import { ArrowBack } from "@mui/icons-material";
import { IconButton } from "@mui/material";

interface Props {
  onClick: () => void;
  className?: string;
}
export const BackButton: FC<Props> = React.memo((props) => {
  return (
    <IconButton
      onClick={props.onClick}
      disableRipple
      disableFocusRipple
      disableTouchRipple
      className={props.className}
      sx={{
        padding: 0,
        margin: 0,
      }}
    >
      <ArrowBack style={{ color: "var(--gray-500)", fontSize: 20 }} />
    </IconButton>
  );
});
