import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faTrailer } from "@fortawesome/free-solid-svg-icons";
import VehicleCirclePin from "../components/VehicleCirclePin";
import LocationDot from "../components/LocationDot";
import VehicleDetailsLocation from "./VehicleDetailsLocation";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import "./VehicleDetailsPage.scss";
import DoraLinkButton from "../../../Components/Toolkit/DoraLinkButton";
import DoraButton from "../../../Components/Toolkit/DoraButton";
import {
  loadVehicleRouteForLiveView,
  unloadRouteForLiveView,
} from "../../../ducks/data/live-view";
import { useAppDispatch, useSelector } from "../../../redux-store";
import { selectTruckRouteForLiveView } from "../../../ducks/data/live-view/selectors";
import { loadTrailers } from "../../../ducks/data/trailers";
import { loadDrivers } from "../../../ducks/data/drivers";
import { VehicleDetailsLocationData } from "../../../ducks/data/live-view/types";
import VehicleDetailsCurrentLocation from "./VehicleDetailsCurrentLocation";
import LiveViewMapForTruckRouteView from "./LiveMapForTruckRouteView";
import { selectTrailer } from "../../../ducks/data/trailers/selectors";
import trucksColors from "../../../helpers/trucks-colors";
import { selectTelemetryTachoStats } from "../../../ducks/data/tracker-devices/selectors";
import { SocketContext } from "../../../SocketConnection";
import DriverTachoStats from "./DriverTachoStatsSection";

const VehicleDetailsPage = () => {
  const navigate = useNavigate();
  const connection = useContext(SocketContext);
  const { t } = useTranslation(["components", "liveView"]);
  const [joinedLiveViewRoom, setJoinedLiveViewRoom] = useState(false);
  const [previousLocations, setPreviousLocations] = useState<
    VehicleDetailsLocationData[]
  >([]);
  const [futureLocations, setFutureLocations] = useState<
    VehicleDetailsLocationData[]
  >([]);

  const [amountPreviousStopsShow, setAmountPreviousStopsShow] = useState(0);
  const [amountFutureStopsShow, setAmountFutureStopsShow] = useState(4);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const routeForLiveView = useSelector(selectTruckRouteForLiveView);
  const truck = useSelector(selectTrailer(routeForLiveView?.truckId || ""));
  const shownPreviousLocations = useMemo(() => {
    return [...previousLocations]
      .reverse()
      .slice(0, amountPreviousStopsShow)
      .reverse();
  }, [amountPreviousStopsShow, previousLocations]);
  const shownFutureLocations = useMemo(() => {
    return futureLocations.slice(0, amountFutureStopsShow);
  }, [amountFutureStopsShow, futureLocations]);

  useEffect(() => {
    if (!connection || !routeForLiveView || joinedLiveViewRoom) {
      return;
    }
    connection.emit("join-live-view-room", {
      routeId: routeForLiveView?.routeId,
    });
    setJoinedLiveViewRoom(true);

    connection.on("live-view.stops-state-updated", () => {
      dispatch(loadVehicleRouteForLiveView(id!));
    });
  }, [
    routeForLiveView,
    setJoinedLiveViewRoom,
    joinedLiveViewRoom,
    dispatch,
    connection,
    id,
  ]);

  useEffect(() => {
    return () => {
      connection!.emit("leave-live-view-room", {
        routeId: routeForLiveView?.routeId,
      });
    };
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(loadVehicleRouteForLiveView(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(loadTrailers());
    dispatch(loadDrivers());

    return () => {
      dispatch(unloadRouteForLiveView());
    };
  }, [dispatch]);

  useEffect(() => {
    if (routeForLiveView) {
      setPreviousLocations(
        routeForLiveView.pastLocations.filter((l) => l.completedAt)
      );
      const futureLocations = routeForLiveView.futureLocations.filter(
        (l) => !l.completedAt
      );
      setFutureLocations(futureLocations);
    }
  }, [routeForLiveView]);

  const onPreviousStopsClick = () => {
    setAmountPreviousStopsShow(amountPreviousStopsShow + 4);
  };

  const onNextStopsClick = () => {
    setAmountFutureStopsShow(amountFutureStopsShow + 4);
  };

  const hiddenPreviousLocationsNumber =
    previousLocations.length - shownPreviousLocations.length;
  const hiddenFutureLocationsNumber =
    futureLocations.length - shownFutureLocations.length;

  const onBackClick = () => {
    navigate(`/live-view`);
  };

  const vehiclePinBackgroundColor = useMemo(() => {
    return trucksColors.find((c) => c.id === truck?.color)?.color;
  }, [truck]);

  const truckTachoStats = useSelector(
    selectTelemetryTachoStats({ doraDeviceId: truck?.trackerDeviceId || null })
  );

  const pastCoords = shownPreviousLocations.map((l) => l.coords);
  const pastCoordsRef = useRef(pastCoords);
  const pastLocationsCoords = useMemo(() => {
    const newCoords = shownPreviousLocations.map((l) => l.coords);
    if (JSON.stringify(pastCoordsRef.current) !== JSON.stringify(newCoords)) {
      pastCoordsRef.current = newCoords;
    }
    return pastCoordsRef.current;
  }, [shownPreviousLocations]);

  const futureCoords = shownFutureLocations.map((l) => l.coords);
  const futureCoordsRef = useRef(futureCoords);
  const futureLocationsCoords = useMemo(() => {
    const newCoords = shownFutureLocations.map((l) => l.coords);
    if (JSON.stringify(futureCoordsRef.current) !== JSON.stringify(newCoords)) {
      futureCoordsRef.current = newCoords;
    }
    return futureCoordsRef.current;
  }, [shownFutureLocations]);

  return routeForLiveView ? (
    <div className="vehicle-details-page">
      <div className="vehicle-details">
        <DoraButton
          aria-label={t("components:buttonLabels.back")}
          className="vehicle-details__back-button"
          variant="primary"
          onClick={onBackClick}
        >
          <FontAwesomeIcon icon={faChevronLeft} />
        </DoraButton>
        <div className="vehicle-details__title-row">
          {truck && (
            <VehicleCirclePin
              style={
                vehiclePinBackgroundColor
                  ? { backgroundColor: vehiclePinBackgroundColor }
                  : {}
              }
              large
            />
          )}
          {routeForLiveView.truckNumber}
        </div>
        <div className="vehicle-details__details-row">
          <div>{routeForLiveView.driverName} ·</div>
          <a href={`tel:${routeForLiveView.driverPhone}`}>
            {routeForLiveView.driverPhone}
          </a>
          <div>·</div>
          <FontAwesomeIcon icon={faTrailer} />
          <div>{routeForLiveView.trailerNumber}</div>
        </div>

        {truckTachoStats && (
          <div className="mt-3">
            <div className="font-bold mb-3" style={{ fontSize: 18 }}>
              Tacho Stats
            </div>
            {!truckTachoStats.driver1.showStatus &&
              !truckTachoStats.driver2.showStatus && (
                <div>Nothing to show currently</div>
              )}
            {truckTachoStats.driver1.showStatus && (
              <>
                <div className="my-1 font-bold">Driver 1</div>
                <DriverTachoStats stats={truckTachoStats.driver1} />
              </>
            )}
            {truckTachoStats.driver2.showStatus && (
              <>
                <div className="mt-5 mb-1 font-bold">Driver 2</div>
                <DriverTachoStats stats={truckTachoStats.driver2} />
              </>
            )}
          </div>
        )}

        <div className="vehicle-details__route-title">
          {t("liveView:route")}
        </div>
        <div className="vehicle-details__route-stops">
          {hiddenPreviousLocationsNumber > 0 && (
            <div className="flex">
              <div>
                <div className="z-10">
                  <LocationDot variant="black" />
                </div>
                <div className="flex justify-center h-full">
                  <div className="route-stops__horizontal-black-line"></div>
                </div>
              </div>
              <div className="vehicle-details__load-more">
                <DoraLinkButton
                  className="font-bold"
                  onClick={onPreviousStopsClick}
                >
                  {t("liveView:previousStops", {
                    count: hiddenPreviousLocationsNumber,
                  })}
                </DoraLinkButton>
              </div>
            </div>
          )}
          {shownPreviousLocations.map((location, i) => (
            <VehicleDetailsLocation
              location={location}
              key={i}
              lastStop={false}
            />
          ))}
          <VehicleDetailsCurrentLocation
            truckId={id!}
            lastStop={!shownFutureLocations.length}
          />
          {shownFutureLocations.map((location, i) => (
            <VehicleDetailsLocation
              location={location}
              key={i}
              lastStop={
                hiddenFutureLocationsNumber === 0 &&
                i === shownFutureLocations.length - 1
              }
            />
          ))}
          {hiddenFutureLocationsNumber > 0 && (
            <div className="flex">
              <div>
                <div className="z-10">
                  <LocationDot variant="gray" />
                </div>
              </div>
              <div className="vehicle-details__load-more">
                <DoraLinkButton
                  className="font-bold"
                  onClick={onNextStopsClick}
                >
                  {t("liveView:moreStops", {
                    count: hiddenFutureLocationsNumber,
                  })}
                </DoraLinkButton>
              </div>
            </div>
          )}
        </div>
      </div>
      <LiveViewMapForTruckRouteView
        truckId={routeForLiveView.truckId}
        pastStopsLocations={pastLocationsCoords}
        futureStopsLocations={futureLocationsCoords}
        startDateForMessages={routeForLiveView.driverStartDayDate}
        className="live-view-page__map"
      />
    </div>
  ) : null;
};

export default VehicleDetailsPage;
