import { createSelector } from "@reduxjs/toolkit";
import Decimal from "decimal.js-light";
import { CargoType } from "../../../../helpers/types";
import { RootState } from "../../../../redux-store";
import { Stop2 } from "../../cargo-dialog/types";
import { WaypointData } from "./types";

const notNull = <T>(x: T | null): x is T => !!x;

export type RouteCargoStop = {
  id: string;
  cargoStop: Stop2;
  cargoId: string;
  cargoType: CargoType | null;
  type: "CARGO_STOP";
  stopType: "PICKUP" | "DROPOFF";
  loadmeters: string;
  accLoadmeters: number;
};

export type RouteWaypoint = WaypointData & { type: "WAYPOINT" };

export type RouteStopData = RouteCargoStop | RouteWaypoint;

export const selectRouteStopsAndWaypoints = createSelector(
  (state: RootState) => state.app.routeInfo.stops.stops,
  (state: RootState) => state.data.cargos.entities,
  (stops, entities): RouteStopData[] | null => {
    if (!stops) return null;
    let accLoadmeters = new Decimal(0);
    const result = stops?.map((stop) => {
      if (stop.type === "WAYPOINT") {
        return stop as WaypointData & { type: "WAYPOINT" };
      }
      if (!("cargoId" in stop) || !("stopId" in stop)) {
        return null;
      }
      const { cargoId, stopId } = stop;
      const cargo = entities[cargoId];
      if (!cargo) {
        return null;
      }
      const result =
        entities[cargoId]?.pickupList
          .map((x) => ({ ...x, stopType: "PICKUP" as const }))
          .find((x) => x.id === stopId) ||
        entities[cargoId]?.dropoffList
          .map((x) => ({
            ...x,
            stopType: "DROPOFF" as const,
            loadmeters: new Decimal(x.loadmeters).mul(-1).toString(),
          }))
          .find((x) => x.id === stopId);
      if (!result) {
        return null;
      }
      accLoadmeters = accLoadmeters.add(result?.loadmeters);
      return {
        id: stop.id,
        cargoStop: result,
        type: "CARGO_STOP" as const,
        cargoId,
        cargoType: cargo.type,
        loadmeters: result.loadmeters,
        accLoadmeters: accLoadmeters.toNumber(),
        stopType: result.stopType,
      };
    });
    return result.filter(notNull);
  }
);

export const selectedDraggedStopId = (state: RootState) => {
  const movedStop = state.app.routeInfo.stops.movedStop;
  return movedStop?.id;
};
