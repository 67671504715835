/* eslint-disable no-unused-vars */
import { Routes, Route, Navigate } from "react-router-dom";
import UpcomingPage from "./UpcomingPage/UpcomingPage";
import VehiclesPage from "./FleetManagementPage/VehiclesTab/VehiclesPage";
import DriversPage from "./FleetManagementPage/DriversTab/DriversPage";
import AnalyticsPage from "./AnalyticsPage/Loader";
import FeatureToggle from "../Components/FeatureToggle";
import SettingsPage from "./SettingsPage";
import UserAccountPage from "./UserAccountPage";
import ConsolidatedInvoicingPage from "./ConsolidatedInvoicePage";
import OutgoingInvoicePage from "./OutgoingInvoicePage";
import DispatchPage from "./DispatchPage/loader";
import EmissionsPage from "./EmissionsPage/Loader";
import CargosReportPage from "./CargosReportPage/Loader";
import TrackerDevicesPage from "./FleetManagementPage/TrackerDevicesTab/TrackerDevicesPage";
// import TrackerDevicesPage from "../Components/fleet-management/TrackerDevicesPage";
import VehiclePage from "./VehiclePage";
import OnboardingVideosPage from "./OnboardingVideosPage";
import LiveViewPages from "./LiveViewPage";

const AuthenticatedRoutes = () => (
  <Routes>
    <Route path="/dispatching/*" element={<DispatchPage />} />
    <Route
      path="/finance/outgoing-invoices/:id"
      element={<OutgoingInvoicePage />}
    />
    <Route path="/fleet/vehicles/:vehicleId" element={<VehiclePage />} />
    <Route path="/upcoming" element={<UpcomingPage />} />
    <Route path="/settings/*" element={<SettingsPage />} />
    <Route path="/live-view/*" element={<LiveViewPages />} />

    <Route path="/user-account" element={<UserAccountPage />} />
    <Route path="/fleet" element={<VehiclesPage />} />
    <Route path="/drivers" element={<DriversPage />} />
    <Route path="/tracker-devices" element={<TrackerDevicesPage />} />
    <Route path="/help-and-support" element={<OnboardingVideosPage />} />
    <Route
      path="/consolidated-invoicing"
      element={<ConsolidatedInvoicingPage />}
    />
    <Route path="*" element={<Navigate to="/dispatching" />} />
    <Route
      path="/analytics"
      element={
        <FeatureToggle feature="analytics">
          <AnalyticsPage />
        </FeatureToggle>
      }
    />
    <Route path="/emissions" element={<EmissionsPage />} />
    <Route path="/cargos-report" element={<CargosReportPage />} />
  </Routes>
);

export default AuthenticatedRoutes;
