import { Routes, Route } from "react-router-dom";
import RegistrationForm from "./Components/Auth/registration-form.jsx";
import LoginForm from "./Components/Auth/login-form.jsx";
import ResetPassword from "./Components/Auth/reset.jsx";
import EnterResetCodePage from "./Components/Auth/EnterResetCodePage";

export default function Welcome() {
  return (
    <Routes>
      <Route path="" element={<RegistrationForm />} />
      <Route path="login" element={<LoginForm />} />
      <Route path="reset" element={<ResetPassword />} />
      <Route path="reset/enter-code" element={<EnterResetCodePage />} />
    </Routes>
  );
}
