import {
  useEffect,
  createContext,
  useContext,
  useRef,
  MutableRefObject,
  ReactNode,
} from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";

const StableNavigateContext =
  createContext<MutableRefObject<NavigateFunction> | null>(null);

export const StableNavigateContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);
  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);

  return (
    <StableNavigateContext.Provider value={navigateRef}>
      {children}
    </StableNavigateContext.Provider>
  );
};

/**
 * modified `useNavigate` to not rerender unnecessarily.
 *
 * Normally `useNavigate` causes the component to rerender when the route
 * changes. This is unnecessary in some circumstances, e.g. when
 * opening/closing a cargo card (changes the fragment), or opening a route
 * (opens a sub/url, but keeps the rest of the page intact)
 */
export const useStableNavigate = (): NavigateFunction => {
  const navigateRef = useContext(StableNavigateContext);
  if (!navigateRef?.current)
    throw new Error("StableNavigate context is not initialized");

  return navigateRef.current;
};
