import { createSlice } from "@reduxjs/toolkit";
import axios, { getData } from "../../../../axios";
import * as tPromise from "io-ts-promise";
import * as types from "./types";
import { createErrorReportingAsyncThunk } from "../../../helpers";
import { ERR_CHANGE_ECONOMIC_TOKEN } from "dora-shared";

const prefix = "app/economic/settings";

export const loadOptions = createErrorReportingAsyncThunk(
  `${prefix}/load-options`,
  async (): Promise<types.Options> => {
    return axios
      .get("/api/e-conomic/configuration/options")
      .then(getData)
      .then(tPromise.decode(types.optionsT));
  }
);

export const settingsLoaded = createErrorReportingAsyncThunk(
  `${prefix}/loaded`,
  async (
    config: types.ConfigurationResponse,
    { dispatch }
  ): Promise<types.ConfigurationResponse> => {
    if (config.agreementGrantStatus === "VERIFIED") {
      dispatch(loadOptions());
    }
    return config;
  }
);

export const loadSettings = createErrorReportingAsyncThunk(
  `${prefix}/load`,
  async (_, { dispatch }) => {
    const result = await axios
      .get("/api/e-conomic/configuration")
      .then(getData)
      .then(tPromise.decode(types.configurationResponseT));
    dispatch(settingsLoaded(result));
  }
);

export const updateDefaults = createErrorReportingAsyncThunk(
  `${prefix}/update-defaults`,
  async (input: {
    currencyCode: string | null;
    vatZoneNumber: number | null;
    layoutNumber: number | null;
    paymentTermsNumber: number | null;
  }) => {
    await axios.put("/api/e-conomic/configuration/defaults", input);
  }
);

export const updateAgreementGrantToken = createErrorReportingAsyncThunk(
  `${prefix}/update-agreement-grant`,
  async (
    {
      token,
    }: {
      token: string;
    },
    { dispatch }
  ) => {
    try {
      const result = await axios
        .put("/api/e-conomic/configuration/agreement-grant-token", {
          agreementGrantToken: token,
        })
        .then(getData)
        .then(tPromise.decode(types.configurationResponseT));
      dispatch(settingsLoaded(result));
    } catch (err: any) {
      if (err.response?.data?.code === ERR_CHANGE_ECONOMIC_TOKEN) {
        return ERR_CHANGE_ECONOMIC_TOKEN;
      }
      throw err;
    }
  }
);

type State = {
  configuration: null | types.ConfigurationResponse;
  options: types.Options | null;
};

const initialState: State = {
  configuration: null,
  options: null,
  // {
  // layouts: [
  // { layoutNumber: 1, name: "Layout 1" },
  // { layoutNumber: 2, name: "Layout 2" },
  // ],
  // vatZones: [
  // { vatZoneNumber: 1, name: "Zone 1" },
  // { vatZoneNumber: 2, name: "Zone 2" },
  // ],
  // paymentTerms: [
  // { paymentTermsNumber: 1, name: "Terms 1" },
  // { paymentTermsNumber: 2, name: "Terms 2" },
  // ],
  // },
};

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadSettings.pending, (state) => {
        state.configuration = null;
      })
      .addCase(settingsLoaded.fulfilled, (state, action) => {
        state.configuration = action.payload;
      })
      .addCase(updateAgreementGrantToken.fulfilled, (state, action) => {
        // state.configuration = action.payload;
      })
      .addCase(loadOptions.fulfilled, (state, action) => {
        state.options = action.payload;
      });
  },
});

export default slice.reducer;
