import { useEffect, useState } from "react";
import { useAppDispatch, useSelector } from "../../../redux-store";
import {
  selectFormDataForWhenOpeningModal,
  selectIsSaving,
  selectResetFormData,
} from "../../../ducks/app/cargo-template-dialog/selectors";
import * as cargoTemplateDialogActions from "../../../ducks/app/cargo-template-dialog";
import CreateEditShipmentDialog from "../CreateEditShipmentDialog";
import { useStableNavigate } from "../../StableNavigateContext";
import { CargoTemplateData } from "../../../ducks/app/cargo-template-dialog/types";
import { loadCargoTemplate } from "../../../ducks/app/cargo-template-dialog";
import { useLocation } from "react-router-dom";
import { loadSchedule } from "../../../ducks/data/schedules";
import { CargoFormData } from "../../../ducks/app/cargo-dialog/types";

type EditShipmentTemplateProps = {
  onClose: (props: {
    formData?: CargoFormData;
    cargoTemplateId?: string;
  }) => void;
  cargoTemplateId?: string;
  routeTemplateId: string | null;
  routeId?: string;
};

const AddEditCargoTemplateModalWrapper = (props: EditShipmentTemplateProps) => {
  const { cargoTemplateId, routeTemplateId } = props;
  const [cargoTemplateData, setCargoTemplateData] =
    useState<CargoTemplateData | null>(null);
  const resetData = useSelector(selectResetFormData);
  const isSaving = useSelector(selectIsSaving);
  const dispatch = useAppDispatch();
  const formStartingData = useSelector(selectFormDataForWhenOpeningModal);
  const navigate = useStableNavigate();
  const { hash } = useLocation();
  const params = new URLSearchParams(hash.substring(1));
  const scheduleId = params.get("scheduleId") || "";

  const onClose = ({
    formData,
    cargoTemplateId,
  }: {
    formData?: CargoFormData;
    cargoTemplateId?: string;
  }) => {
    setCargoTemplateData(null);
    props.onClose({ formData, cargoTemplateId });
  };

  useEffect(() => {
    if (cargoTemplateId) {
      dispatch(loadCargoTemplate(cargoTemplateId)).then((result) => {
        if (result.payload) {
          const data = (result.payload as any).data as CargoTemplateData; // TODO: can't infer correct type
          setCargoTemplateData(data);
        }
      });
    }
  }, [cargoTemplateId, dispatch]);

  const handleSubmit = async (
    formData: CargoTemplateData & {
      keepAlive?: boolean;
      submitType: "CREATE" | "UPDATE" | "DUPLICATE" | "CREATE_AND_MAKE_COPY";
    }
  ) => {
    const res = await dispatch(
      cargoTemplateDialogActions.submit({
        ...formData,
        scheduleId,
        routeTemplateId,
      })
    ).unwrap();
    if (!res.keepAlive) {
      setCargoTemplateData(null);
      navigate({ hash: "" });
    }
    dispatch(loadSchedule(scheduleId));

    return res;
  };

  const handleDelete = async () => {
    if (!cargoTemplateId) {
      console.error("No cargo template id to delete");
      return;
    }
    dispatch(
      cargoTemplateDialogActions.deleteCargoTemplate({
        cargoTemplateId,
        scheduleId,
      })
    );
    // TODO: only navigate and close on success, how to do this?
    // TODO: consider moving into the redux actions layer
    navigate({ hash: "" }, { replace: true });
  };

  return cargoTemplateData || (!cargoTemplateId && formStartingData) ? (
    <CreateEditShipmentDialog
      resetData={resetData!}
      initialData={cargoTemplateData || formStartingData!}
      onSubmit={handleSubmit}
      onDelete={handleDelete}
      onClose={onClose}
      isSaving={isSaving}
      createNew={!cargoTemplateId}
      isTemplate={true}
    />
  ) : null;
};

export default AddEditCargoTemplateModalWrapper;
