import { StopApiDto } from "../../models/stop-api-dto";
import nullable from "../../types/nullable";
import { Cargo } from "../../models/cargo";
import * as t from "io-ts";
import { cargoTypeWithFallbackT, unitT } from "dora-contracts";

export interface CargoState {
  stops: StopApiDto[];
  cargoEntities: Record<string, StateCargo>;
  stopEntities: { [key: string]: StopApiDto };
  sortProperty: string | undefined;
  sortAscending: boolean;
  hoveredCargoId: string | undefined;
}

export interface StateCargo extends Cargo {
  totalLm: string;
  totalWeight: string | null;
}

export type StateStop = StopApiDto;
export const cargoT = t.strict({
  id: t.string,
  invoiced: t.boolean,
  lm: t.string,
  new_date: nullable(t.string),
  address: nullable(t.string),
  colli: nullable(t.string),
  length: nullable(t.string),
  width: nullable(t.string),
  order_id: nullable(t.number),
  place_name: nullable(t.string),
  postcode: nullable(t.string),
  qualities: t.array(t.string),
  requirements: t.array(t.string),
  route_id: nullable(t.string),
  smart_match: t.boolean,
  type: cargoTypeWithFallbackT,
  fix_time: t.boolean,
  time: nullable(t.string),
  delivery_id: t.string,
  temperature: nullable(t.string),
  trailer_id: nullable(t.string),
  coords: nullable(t.string),
  date: nullable(t.string),
  height: nullable(t.string),
  country: nullable(t.string),
  city: nullable(t.string),
  cargo_time: nullable(t.string),
  cargo_ref: nullable(t.string),
  cargo_phone: nullable(t.string),
  cargo_info_note: nullable(t.string),
  cargo_contact: nullable(t.string),
  cargo_id: t.string,
  cargo_note: nullable(t.string),
  created_at: t.string, // We shouldn't use this
  client: nullable(t.string),
  client_phone: nullable(t.string),
  client_contact: nullable(t.string),
  client_contact_phone: nullable(t.string),
  client_contact_email: nullable(t.string),
  client_number: nullable(t.string),
  unit_id: nullable(unitT),
  quantity: nullable(t.string),
  weight: nullable(t.string),
  note: nullable(t.string),
});
export const cargosT = t.array(cargoT);
export type CargoT = t.TypeOf<typeof cargoT>;
