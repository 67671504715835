import * as t from "io-ts";
import { createLiveViewTypes, decimal, placeT } from "dora-contracts";

export const {
  routeForLiveViewT,
  vehicleForLiveViewT,
  vehiclesForLiveViewT,
  vehicleDetailsLocationDataT,
} = createLiveViewTypes({
  dateTime: t.string,
  decimal: decimal,
});

export type VehicleDetailsLocationData = t.TypeOf<
  typeof vehicleDetailsLocationDataT
>;
export type VehicleForLiveView = t.TypeOf<typeof vehicleForLiveViewT>;
export type RouteForLiveView = t.TypeOf<typeof routeForLiveViewT>;
export type Place = t.TypeOf<typeof placeT>;
