import { DateTime } from "luxon";

const format = "dd.MM.yyyy";

export const formatBasicStringDate = (date?: string | null) => {
  if (date) return asDateTime(date).toFormat(format);
  return "";
};

export const toISODate = (date: any) => {
  return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
};

export const formatCustomDateString = (
  dateString: string,
  toFormat: string
) => {
  return DateTime.fromJSDate(new Date(dateString)).toFormat(toFormat);
};

const asDateTime = (d: DateTime | string): DateTime =>
  d instanceof DateTime ? d : DateTime.fromISO(d, { setZone: true });

export const formatDateAndTime = (d: DateTime | string) =>
  asDateTime(d).toLocaleString(DateTime.DATETIME_SHORT);

export const formatDateAndTimeDayTwoDigits = (d: DateTime | string) =>
  asDateTime(d).toLocaleString({
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

export const formatDateAndTime24Hour = (d: DateTime | string) =>
  asDateTime(d).toLocaleString(DateTime.TIME_24_SIMPLE);

export const formatDateDot = (d: DateTime | string) =>
  asDateTime(d).toFormat("dd.MM.yy");
