import classNames from "classnames";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import LocationDot from "../components/LocationDot";
import "./VehicleDetailsLocation.scss";
import { VehicleDetailsLocationData } from "../../../ducks/data/live-view/types";
import { useSelector } from "../../../redux-store";
import { selectClient } from "../../../ducks/data/clients/selectors";
import { useStableNavigate } from "../../../Components/StableNavigateContext";

type VehicleDetailsLocationProps = {
  location: VehicleDetailsLocationData;
  lastStop: boolean;
};

const VehicleDetailsLocation = (props: VehicleDetailsLocationProps) => {
  const { lastStop, location } = props;
  const { t } = useTranslation(["components", "liveView"]);

  const client = useSelector(selectClient(location.clientId));

  const navigate = useStableNavigate();

  const classes = classNames("vehicle-details-location", {
    "vehicle-details-location--previous": location.completedAt,
    "vehicle-details-location--future": !location.completedAt,
  });

  const onOpenCargoClick = () => {
    navigate({ hash: `cargo=${location.cargoId}` });
  };

  return (
    <div className={classes}>
      {location.completedAt && (
        <div>
          <div className="flex">
            <div>
              <div className="z-10">
                <LocationDot variant="black" />
              </div>
              {!lastStop && (
                <div className="flex justify-center h-full">
                  <div className="route-stops__horizontal-black-line"></div>
                </div>
              )}
            </div>
            <div className="vehicle-details-location__right">
              <div className="vehicle-details-location__type">
                {location.type === "CARGO_STOP"
                  ? t("liveView:stop")
                  : t("liveView:waypoint")}
              </div>
              <div className="vehicle-details-location__place">
                {location.address}
              </div>
              {client && (
                <div className="vehicle-details-location__client">
                  <FontAwesomeIcon
                    className="vehicle-details-location__client-icon"
                    icon={faUserCircle}
                  />
                  <div>{client.client}</div>
                </div>
              )}
              {location.packageInfo && (
                <div className="vehicle-details-location__package">
                  <div>
                    {t("liveView:packageInfo", {
                      lm: location.packageInfo.lm,
                      cargoType: location.packageInfo.cargoType,
                    })}
                  </div>
                  {location.cargoId && (
                    <FontAwesomeIcon
                      role="button"
                      aria-label={t("liveView:openCargo")}
                      onClick={onOpenCargoClick}
                      icon={faArrowUpRightFromSquare}
                      className="vehicle-details-location__package-icon"
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {!location.completedAt && (
        <div className="flex">
          <div>
            <div className="z-10">
              <LocationDot variant="gray" />
            </div>
            {!lastStop && (
              <div className="flex justify-center h-full">
                <div className="route-stops__horizontal-gray-dashed-line"></div>
              </div>
            )}
          </div>
          <div className="vehicle-details-location__right">
            <div className="vehicle-details-location__type">
              {location.type === "CARGO_STOP"
                ? t("liveView:stop")
                : t("liveView:waypoint")}
            </div>
            <div className="vehicle-details-location__place">
              {location.address}
            </div>
            {client && (
              <div className="vehicle-details-location__client">
                <FontAwesomeIcon
                  className="vehicle-details-location__client-icon"
                  icon={faUserCircle}
                />
                <div>{client.client}</div>
              </div>
            )}
            {location.packageInfo && (
              <div className="vehicle-details-location__package">
                <div>
                  {t("liveView:packageInfo", {
                    lm: location.packageInfo.lm,
                    cargoType: location.packageInfo.cargoType,
                  })}
                </div>
                <FontAwesomeIcon
                  role="button"
                  aria-label={t("liveView:openCargo")}
                  onClick={onOpenCargoClick}
                  icon={faArrowUpRightFromSquare}
                  className="vehicle-details-location__package-icon"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default VehicleDetailsLocation;
