import { useMemo } from "react";
import { useAppDispatch } from "../../../redux-store";
import * as actions from "./index";
import { useTeams } from "../../data/teams/hooks";

export const useTeamsDialog = () => {
  const dispatch = useAppDispatch();
  const teams = useTeams();
  return {
    addTeam: () => {
      dispatch(actions.newTeam());
    },
    teams,
  };
};

type DialogFormData = { name: string; description: string };

export const useDialogActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(
    () => ({
      close: () => {
        dispatch(actions.close());
      },
      submit: (formData: DialogFormData) => dispatch(actions.submit(formData)),
    }),
    [dispatch]
  );
};
