import React, { useEffect } from "react";
import { List, Link, Grid, Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { CargoEvent } from "../../../ducks/data/cargo-events/types";
import RoomOutlinedIcon from "@mui/icons-material/RoomOutlined";
import { selectDriver } from "../../../ducks/data/drivers/selectors";
import { useSelector } from "react-redux";
import {
  formatDateAndTime24Hour,
  formatDateDot,
} from "../../../helpers/date-helper";
import { useL10n } from "../../../l10n";
import { selectCargoEvents } from "../../../ducks/data/cargo-events/selectors";
import { useAppDispatch } from "../../../redux-store";
import { loadCargoEvents } from "../../../ducks/data/cargo-events";
import { CargoType } from "dora-contracts";
import { selectClients } from "../../../ducks/data/clients/selectors";
import { selectDispatcher } from "../../../ducks/data/dispatchers/selectors";
import { loadUsers } from "../../../ducks/data/dispatchers";

const fullName = (driver: null | { firstName?: string; lastName?: string }) =>
  driver ? `${driver?.firstName || ""} ${driver?.lastName || ""}`.trim() : "";

const EventItem = ({
  event,
  children,
  label,
}: {
  event: CargoEvent;
  cargoId: string | null;
  label: string | null;
  children?: React.ReactNode;
  secondaryAction?: React.ReactNode;
}) => {
  const { t } = useTranslation("dispatching");
  const { position } = event;
  const l10n = useL10n();
  const driver = useSelector(selectDriver(event.createdBy.driverId));
  const plannedLocation =
    "plannedLocation" in event ? event.plannedLocation : null;
  const distance =
    position && position?.accuracy > 1000
      ? l10n.formatNumber(Math.ceil(position.accuracy / 1000)) + "km."
      : position && l10n.formatNumber(position?.accuracy) + "m.";
  const url = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
    `${position?.lat},${position?.lon}`
  )}`;

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="start"
      sx={{ borderBottom: "solid var(--gray-200) 0.2px", py: 1 }}
    >
      <Tooltip
        title={`${formatDateAndTime24Hour(event.time)} ${formatDateDot(
          event.time
        )},\r\n
          ${fullName(driver)}
          `}
        placement="top"
        // slotProps={{
        //   popper: {
        //     modifiers: [
        //       {
        //         name: "offset",
        //         options: {
        //           offset: [0, -14],
        //         },
        //       },
        //     ],
        //   },
        // }}
      >
        <Grid item xs={3.5} sm={3}>
          <Typography
            variant="body2"
            sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
          >
            {label}
          </Typography>
        </Grid>
      </Tooltip>
      <Grid item xs>
        <Typography variant="body2" color="var(--gray-500)" fontWeight="400">
          {children || formatDateAndTime24Hour(event.time)}{" "}
          {(event as any).lastEdited &&
            `(${t("driverEvents.editedFrom")} ${formatDateAndTime24Hour(
              (event as any).lastEdited.value
            )})`}
        </Typography>
      </Grid>
      <Tooltip
        title={t("driverEvents.accuracyWithin", { distance })}
        placement="top"
      >
        <Grid
          container
          item
          xs
          columnGap={0.2}
          direction="row"
          alignItems="center"
          component="a"
          href={url}
          target="_blank"
          rel="noreferrer"
          sx={{ textDecoration: "none" }}
        >
          <RoomOutlinedIcon
            fontSize="inherit"
            sx={{ color: "var(--gray-500)" }}
          />
          <Typography variant="body2" color="var(--gray-500)" fontWeight="400">
            {plannedLocation?.city || ""}
          </Typography>
        </Grid>
      </Tooltip>
    </Grid>
  );
};

export const CreatedByDispatcherEventItem = ({
  dispatcherId,
}: {
  dispatcherId: string;
}) => {
  const { t } = useTranslation("dispatching");
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadUsers());
  }, [dispatch]);

  const createdByDispatcher = useSelector(selectDispatcher(dispatcherId));

  return createdByDispatcher ? (
    <Grid container sx={{ borderBottom: "solid var(--gray-200) 0.2px", py: 1 }}>
      <Typography
        variant="body2"
        sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
      >
        {t("driverEvents.cargoCreated", {
          dispatcher: fullName(createdByDispatcher),
        })}
      </Typography>
    </Grid>
  ) : null;
};

export const DriverEventItem = (props: {
  event: CargoEvent;
  cargoId: string | null;
}) => {
  const l10n = useL10n();
  const { t } = useTranslation("dispatching");
  const { event } = props;
  switch (event.type) {
    case "DRIVER_CARGO_TIME_TRACKING_STARTED":
      return (
        <EventItem {...props} label={t("driverEvents.timeTrackingStarted")} />
      );
    case "DRIVER_CARGO_TIME_TRACKING_PAUSED":
      return (
        <EventItem {...props} label={t("driverEvents.timeTrackingPaused")} />
      );
    case "DRIVER_CARGO_TIME_TRACKING_RESUMED":
      return (
        <EventItem {...props} label={t("driverEvents.timeTrackingResumed")} />
      );
    case "DRIVER_CARGO_TIME_TRACKING_STOPPED":
      return (
        <EventItem {...props} label={t("driverEvents.timeTrackingStopped")} />
      );
    case "CARGO_COLLECTED":
      return <EventItem {...props} label={t("driverEvents.collected")} />;
    case "CARGO_DELIVERED":
      return <EventItem {...props} label={t("driverEvents.delivered")} />;
    case "CARGO_NOTE_ADDED":
      return (
        <EventItem {...props} label={t("driverEvents.note")}>
          {event.note}
        </EventItem>
      );
    case "CARGO_DOCUMENT_ADDED":
      return (
        <EventItem {...props} label={t("driverEvents.image")}>
          <Link
            aria-label={t("driverEvents.downloadButtonLabel")}
            component="a"
            href={event.url}
          >
            {t("driverEvents.downloadButtonLabel")}
          </Link>
        </EventItem>
      );
    case "CARGO_WEIGHT_ADDED":
      return (
        <EventItem {...props} label={t("driverEvents.weight")}>
          {l10n.formatNumber(event.weight)} kg
        </EventItem>
      );
    case "CARGO_VOLUME_ADDED":
      return (
        <EventItem {...props} label={t("driverEvents.volume")}>
          {l10n.formatNumber(event.cubicMeters)} m³
        </EventItem>
      );
    case "CARGO_INVOICE_REF_ADDED":
      return (
        <EventItem {...props} label={t("driverEvents.invoiceRef")}>
          {event.ref}
        </EventItem>
      );
    case "DRIVER_ARRIVED":
      return <EventItem {...props} label={t("driverEvents.arrived")} />;
    case "DRIVER_DEPARTED":
      return <EventItem {...props} label={t("driverEvents.departed")} />;
    case "DRIVER_CARGO_STOP_UPDATE":
      return <CargoStopUpdateEventItemBody event={event} otherProps={props} />;
    default:
      return null;
  }
};

const CargoStopUpdateEventItemBody = (props: {
  event: {
    type: "DRIVER_CARGO_STOP_UPDATE";
    from: {
      lm: string | null;
      stopTime: string | null;
      date: string | null;
      place: any | null;
      cargoType: CargoType | null;
      description: string | null;
      clientId: string | null;
    };
    to: {
      lm: string | null;
      stopTime: string | null;
      date: string | null;
      place: any | null;
      cargoType: CargoType | null;
      description: string | null;
      clientId: string | null;
    };
  };
  otherProps: any;
}) => {
  const l10n = useL10n();
  const { t } = useTranslation(["dispatching", "types"]);
  const { event, otherProps } = props;
  const clients = useSelector(selectClients);
  if (event.from.clientId !== event.to.clientId) {
    const fromClient = clients.find((c) => c.id === event.from.clientId);
    const toClient = clients.find((c) => c.id === event.to.clientId);
    return (
      <EventItem {...otherProps} label={t("driverEvents.changed")}>
        <span>
          {t("driverEvents.updateByClient", {
            from: fromClient?.client || "-",
            to: toClient?.client || "-",
          })}
        </span>
      </EventItem>
    );
  }
  if (event.from.lm !== event.to.lm) {
    return (
      <EventItem {...otherProps} label={t("driverEvents.changed")}>
        <span>
          {t("driverEvents.updateByDriverLm", {
            from: event.from.lm || "",
            to: event.to.lm || "",
          })}
        </span>
      </EventItem>
    );
  }
  if (event.from.cargoType !== event.to.cargoType) {
    return (
      <EventItem {...otherProps} label={t("driverEvents.changed")}>
        <span>
          {t("dispatching:driverEvents.updateByDriverType", {
            from: event.from.cargoType
              ? t(`types:cargoTypes.type_${event.from.cargoType}`)
              : "",
            to: event.to.cargoType
              ? t(`types:cargoTypes.type_${event.to.cargoType}`)
              : "",
          })}
        </span>
      </EventItem>
    );
  }
  if (event.from.description !== event.to.description) {
    return (
      <EventItem {...otherProps} label={t("driverEvents.changed")}>
        <span>
          {t("dispatching:driverEvents.updateByDriverDescription", {
            from: event.from.description || "",
            to: event.to.description || "",
          })}
        </span>
      </EventItem>
    );
  }
  if (event.from.stopTime !== event.to.stopTime) {
    return (
      <EventItem {...otherProps} label={t("driverEvents.changed")}>
        <span>
          {t("driverEvents.updateByDriverTime", {
            from: event.from.stopTime || "",
            to: event.to.stopTime || "",
          })}
        </span>
      </EventItem>
    );
  }
  if (event.from.date !== event.to.date) {
    return (
      <EventItem {...otherProps} label={t("driverEvents.changed")}>
        <span>
          {t("driverEvents.updateByDriverDate", {
            from: event.from.date ? l10n.formatDate(event.from.date) : "",
            to: event.to.date ? l10n.formatDate(event.to.date) : "",
          })}
        </span>
      </EventItem>
    );
  }
  if (event.from.place !== event.to.place) {
    return (
      <EventItem {...otherProps} label={t("driverEvents.changed")}>
        {t("driverEvents.updateByDriverLocation")}
      </EventItem>
    );
  }
  return null;
};

const CargoEventsShipment = ({
  cargoId,
  createdByDispatcherId,
  hideTitle,
}: {
  cargoId: string;
  createdByDispatcherId: string | null;
  hideTitle?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const events = useSelector(selectCargoEvents);
  useEffect(() => {
    dispatch(loadCargoEvents(cargoId));
  }, [cargoId, dispatch]);
  const { t } = useTranslation(["cargo"]);

  return (
    <>
      <Typography variant="subtitle1" component="h3">
        {hideTitle ? "" : t("cargo:log.heading")}
        <List>
          {createdByDispatcherId && (
            <CreatedByDispatcherEventItem
              dispatcherId={createdByDispatcherId}
            />
          )}
          {events.map((e) => (
            <DriverEventItem key={e.id} event={e} cargoId={cargoId} />
          ))}
        </List>
      </Typography>
    </>
  );
};

export default CargoEventsShipment;
