import axios from "../../../../axios";
import * as types from "./types";
import * as core from "../../clients";
import { createErrorReportingAsyncThunk } from "../../../helpers";
const prefix = "data/economic/customers";

export type Customer = types.Customer;

export const syncCustomers = createErrorReportingAsyncThunk(
  `${prefix}/sync-customers`,
  async (_, thunkAPI) => {
    await axios.post("/api/e-conomic/customers/sync");
    await thunkAPI.dispatch(core.getClients());
  }
);
