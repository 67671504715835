import axios, { getData } from "../../../axios";
import { createSlice, Dictionary } from "@reduxjs/toolkit";
import * as tPromise from "io-ts-promise";
import { documentsT, Document } from "./types";
import { createErrorReportingAsyncThunk } from "../../helpers";

const prefix = "/data/stop-documents";

type State = {
  byStopId: Dictionary<Document[]>;
};

const initialState: State = {
  byStopId: {},
};

export const loadDocuments = createErrorReportingAsyncThunk(
  `${prefix}/load`,
  (stopId: string): Promise<Document[]> => {
    return axios
      .get(`/api/stops/${stopId}/documents`)
      .then(getData)
      .then(tPromise.decode(documentsT));
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadDocuments.fulfilled, (state, action) => {
      state.byStopId[action.meta.arg] = action.payload;
    });
  },
});

export default slice.reducer;
