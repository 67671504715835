import { useEffect } from "react";
import { loadDrivers } from "./ducks/data/drivers";
import { getClients } from "./ducks/data/clients";
import { getAll as getWebBookers } from "./ducks/data/web-bookers";
import { loadTrailers } from "./ducks/data/trailers";
import { useAppDispatch } from "./redux-store";
import { loadDepartments } from "./ducks/data/departments";

const LoadStandardData = () => {
  // Render-less component. React pattern for e.g. triggering effects on
  // mounting, in this case loading initial data.
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadTrailers());
    dispatch(loadDrivers());
    dispatch(getClients());
    dispatch(getWebBookers());
    dispatch(loadDepartments());
  });
  return null;
};

export default LoadStandardData;
