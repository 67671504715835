import TextField, { TextFieldProps } from "@mui/material/TextField";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import { FieldProps, getIn } from "formik";
import { FormControlLabel } from "@mui/material";

// These components bind MUI components to Formik
export const MuiTextField = (props: FieldProps & TextFieldProps) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);
  const { error, helperText, field, form, ...rest } = props;
  return (
    <TextField
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={
        helperText ?? (isTouched && errorMessage ? errorMessage : undefined)
      }
      {...field}
      {...rest}
      type="text"
    />
  );
};

export const MuiNumberTextField = (props: FieldProps & TextFieldProps) => {
  const isTouched = getIn(props.form.touched, props.field.name);
  const errorMessage = getIn(props.form.errors, props.field.name);
  const { error, helperText, field, form, ...rest } = props;
  return (
    <TextField
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={
        helperText ?? (isTouched && errorMessage ? errorMessage : undefined)
      }
      {...field}
      {...rest}
      type="number"
    />
  );
};

export const MuiCheckBox = (
  props: { label: string } & FieldProps & CheckboxProps
) => {
  const { field, label, form, ...rest } = props;
  return (
    <FormControlLabel
      control={<Checkbox {...field} {...rest} />}
      label={label}
    />
  );
};

// export const MuiPhoneNoField = (
// props: FieldProps & React.ComponentPropsWithRef<typeof MuiPhoneNumber>
// ) => {
// const isTouched = getIn(props.form.touched, props.field.name);
// const errorMessage = getIn(props.form.errors, props.field.name);
// const { field, form, ...rest } = props;
// return (
// <MuiPhoneNumber
// // error={error ?? Boolean(isTouched && errorMessage)}
// // helperText={
// // helperText ??
// // (isTouched && errorMessage ? errorMessage : undefined)
// // }
// {...field}
// {...rest}
// // margin="dense"
// // size="small"
// // fullWidth
// />
// );
// };

export const MuiNumericField = (props: FieldProps & TextFieldProps) => {
  const { onChange, ...rest } = props;
  return (
    <MuiTextField
      {...rest}
      onChange={(e) => {
        props.form.setFieldTouched(props.field.name, false);
        props.form.setFieldValue(
          props.field.name,
          e.target.value.replace(/\D/g, "")
        );
      }}
    />
  );
};
