import { useFieldArray, useFormContext } from "react-hook-form";
import StopEdit from "./StopEdit";
import { MuiTooltip } from "../../../nav/nav-styles";
import DoraButton from "../../../Toolkit/DoraButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import "./StopList.scss";
import { createEmptyStop } from "./create-empty-stop";
import { useTranslation } from "react-i18next";
import { focusOnLocationSearchInput } from "./helpers";
import { useFeature } from "../../../../hooks";
import { useMemo } from "react";

type StopListProps = {
  addButtonDisabled: boolean;
  isSingleStop?: boolean;
  disableAccumulatedInputs?: boolean;
  stopsLengthChanged?: () => void;
  isPickup?: boolean;

  // useForms inject:
  errors: any;

  // used in order to compose the name or the path of the attribute in the form, so that register can work and knows to bound values to their properties in the main object
  relativeFromPath: string;
};

const StopList = (props: StopListProps) => {
  const allowEmptyDropoffsList = useFeature(
    "allow-dispatcher-no-dropoff-shipment"
  );
  const { t } = useTranslation(["components", "shipmentModal"]);
  const {
    isPickup,
    addButtonDisabled,
    isSingleStop,
    errors,
    relativeFromPath,
    disableAccumulatedInputs,
    stopsLengthChanged = () => {},
  } = props;
  const { getValues, control } = useFormContext();

  const {
    fields: stops,
    append,
    remove,
    swap,
  } = useFieldArray({
    control,
    name: relativeFromPath,
  });

  const header = useMemo(
    () =>
      isPickup && !isSingleStop
        ? t("shipmentModal:pickupHeader")
        : isSingleStop
          ? ""
          : t("shipmentModal:dropoffHeader"),
    [t, isPickup, isSingleStop]
  );

  const addStop = () => {
    const firstPickupStopDate = getValues("pickupList[0].date");
    append({
      ...createEmptyStop(firstPickupStopDate),
      // it is clear that this is the dropoff list and there are no dropoffs, the driver created a shipment with only a pickup
      ...(!stops.length && {
        loadmeters: getValues("pickupList[0].loadmeters"),
      }),
    });
    stopsLengthChanged();
    focusOnLocationSearchInput(`${relativeFromPath}[${stops.length}]`);
  };

  const moveDownClick = (i: number) => (e: React.SyntheticEvent) => {
    e.preventDefault();
    swap(i, i + 1);
  };
  const moveUpClick = (i: number) => (e: React.SyntheticEvent) => {
    e.preventDefault();
    swap(i, i - 1);
  };

  const removeStop = (i: number) => (e: React.SyntheticEvent) => {
    e.preventDefault();
    remove(i);
    stopsLengthChanged();
  };

  return (
    <>
      {stops.map((_, i) => (
        <div key={i}>
          <div className="shipment-cargo-form__title">
            <div>{header}</div>
            <div className="flex">
              {(!isPickup && allowEmptyDropoffsList) || stops.length > 1 ? (
                <MuiTooltip
                  title={t("components:buttonLabels.delete")}
                  placement="top"
                >
                  <div>
                    <DoraButton
                      onClick={removeStop(i)}
                      variant="ghost"
                      className="shipment-cargo-forms-section__title-action"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </DoraButton>
                  </div>
                </MuiTooltip>
              ) : null}
              {stops.length > 1 && (
                <>
                  <MuiTooltip
                    title={t("components:buttonLabels.moveUp")}
                    placement="top"
                  >
                    <div>
                      <DoraButton
                        variant="ghost"
                        className="shipment-cargo-forms-section__title-action"
                        disabled={i === 0}
                        onClick={moveUpClick(i)}
                      >
                        <FontAwesomeIcon icon={faChevronUp} />
                      </DoraButton>
                    </div>
                  </MuiTooltip>
                  <MuiTooltip
                    title={t("components:buttonLabels.moveDown")}
                    placement="top"
                  >
                    <div>
                      <DoraButton
                        variant="ghost"
                        className="shipment-cargo-forms-section__title-action"
                        disabled={i === stops.length - 1}
                        onClick={moveDownClick(i)}
                      >
                        <FontAwesomeIcon icon={faChevronDown} />
                      </DoraButton>
                    </div>
                  </MuiTooltip>
                </>
              )}
            </div>
          </div>
          <StopEdit
            isPickup={isPickup}
            isSingleStop={isSingleStop}
            errors={errors}
            disableAccumulatedInputs={disableAccumulatedInputs}
            relativeFormPath={`${relativeFromPath}[${i}]`}
          />
        </div>
      ))}
      <DoraButton
        onClick={addStop}
        disabled={addButtonDisabled}
        variant="ghost"
        className="shipment-cargo-forms-section__add-button"
      >
        <FontAwesomeIcon icon={faPlus} style={{ marginRight: 8 }} />
        {t("shipmentModal:addAnother")} {header}
      </DoraButton>
    </>
  );
};

export default StopList;
