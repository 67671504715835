import { Button, Dialog, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CloseButton } from "../../../dispatching-planning/common/close-button";
import "./SaveFavoriteLocationDialog.scss";
import { useActions } from "../../../../hooks/useActions";
import { createSavedLocation } from "../../../../ducks/data/saved-locations";
import { SearchOption } from "./PlaceSearchWithSavedLocations";
import SavedLocation from "../../ManageFavoritesAndTerminalsDialog/SavedLocation";
import { useState } from "react";
import { AutoSuggestItem } from "dora-shared";
import { AddressChange } from "../NewPlaceSearch";

const SaveFavoriteLocationDialog = ({
  onClose,
  location,
}: {
  onClose: ({
    placeSaved,
    customName,
    place,
  }: {
    placeSaved: boolean;
    customName?: string;
    place?: AddressChange;
  }) => void;
  location: SearchOption;
}) => {
  const { t } = useTranslation(["modals", "components"]);
  const [customName, setCustomName] = useState("");

  const actions = useActions({
    createSavedLocation,
  });

  const onSaveClick = async () => {
    const data = location.value.data as AutoSuggestItem;
    const lookupResult = await data.lookup();

    const lat = lookupResult.coords.lat;
    const lon = lookupResult.coords.lon;
    const placeObj: AddressChange = {
      address: lookupResult.address,
      city: lookupResult.city,
      country: lookupResult.countryCode,
      postcode: lookupResult.postcode,
      placeName: customName || lookupResult.placeName,
      coord: { lon, lat },
      googlePlaceId: lookupResult.googlePlaceId,
    };
    await actions.createSavedLocation({
      customName: customName || placeObj.placeName || placeObj.address,
      place: placeObj,
      type: "FAVORITE",
    } as Partial<SavedLocation>);
    onClose({
      placeSaved: true,
      ...(customName && { customName }),
      place: placeObj,
    });
  };

  return (
    <Dialog open onClose={() => onClose({ placeSaved: false })}>
      <div className="save-favorite-location__first-row">
        <div>{t("modals:saveLocation.addName")}</div>
        <CloseButton onClick={() => onClose({ placeSaved: false })} />
      </div>
      <div className="save-favorite-location__second-row">
        <TextField
          label={t("modals:saveLocation.addName")}
          value={customName}
          onChange={(e) => setCustomName(e.target.value)}
        />
        <div className="save-favorite-location__subtext">
          {t("modals:saveLocation.placeNameSubtext")}
        </div>
      </div>

      <hr className="save-favorite-location__hr" />
      <div className="save-favorite-location__buttons">
        <Button
          variant="outlined"
          color="primary"
          onClick={() => onClose({ placeSaved: false })}
        >
          {t("components:buttonLabels.cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={onSaveClick}>
          {t("components:buttonLabels.save")}
        </Button>
      </div>
    </Dialog>
  );
};

export default SaveFavoriteLocationDialog;
