import { useEffect } from "react";
import {
  loadCargoEvents,
  unloadCargoEvents,
} from "../../../ducks/data/cargo-events";
import { useAppDispatch } from "../../../redux-store";

const useCargoEvents = ({
  cargoId,
  isTemplate,
}: {
  cargoId: string | null;
  isTemplate: boolean;
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (cargoId && !isTemplate) {
      dispatch(loadCargoEvents(cargoId));
    }

    return () => {
      if (cargoId && !isTemplate) {
        dispatch(unloadCargoEvents());
      }
    };
  }, [dispatch, cargoId, isTemplate]);
};

export default useCargoEvents;
