import { decodeGoogleAddressComponents } from "dora-contracts";
import take from "lodash/take";

let geoCoder: google.maps.Geocoder;

const getGeoCoder = () => {
  if (!geoCoder) {
    geoCoder = new google.maps.Geocoder();
  }
  return geoCoder;
};

export const reverseGeoCodeMany = async (coord: {
  lat: number;
  lon: number;
}) => {
  const response = await getGeoCoder().geocode({
    location: { lat: coord.lat, lng: coord.lon },
  });
  return take(response.results, 3).map((result) => ({
    ...coord,
    place: result.formatted_address,
    ...decodeGoogleAddressComponents(result.address_components),
    googlePlaceId: result.place_id,
  }));
};

export const reverseGeoCode = async (coord: { lat: number; lon: number }) => {
  const results = await reverseGeoCodeMany(coord);
  return results[0];
};
