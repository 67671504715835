import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

// These are by design compatible with MUI alert severities, but we don't want
// to import MUI here, as there is no dependency to MUI, just compatibility.
type NotificationType = "error" | "warning" | "info" | "success";

interface Notification {
  id: string;
  message?: string;
  namespace?: string;
  key?: string;
  type: NotificationType;
}

type State = {
  networkError: boolean;
  notifications: Notification[];
};

let id = 1;
let createId = (): string => (id++).toString();

const initialState: State = {
  notifications: [],
  networkError: false,
};

export const notify = createAsyncThunk(
  "notifications/notify",
  async (
    payload: {
      message: string;
      keepOpen?: boolean;
      type?: NotificationType;
    },
    thunkApi
  ) => {
    const id = createId();
    if (!payload.keepOpen) {
      setTimeout(() => {
        thunkApi.dispatch(dismissNotification(id));
      }, 5000);
    }
    return { id, ...payload };
  }
);

export const notifyL = createAsyncThunk(
  "notifications/notifyL",
  async (
    payload: {
      namespace: string;
      key: string;
      keepOpen?: boolean;
      type?: NotificationType;
    },
    thunkApi
  ) => {
    const id = createId();
    if (!payload.keepOpen) {
      setTimeout(() => {
        thunkApi.dispatch(dismissNotification(id));
      }, 5000);
    }
    return { id, ...payload };
  }
);

const slice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    dismissNotification(state, action: PayloadAction<string>) {
      state.notifications = state.notifications.filter(
        (x) => x.id !== action.payload
      );
    },
    showNetworkErrorMessage(state) {
      state.networkError = true;
    },
    hideNetworkErrorMessage(state) {
      state.networkError = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(notify.fulfilled, (state, action) => {
      state.notifications.push({
        ...action.payload,
        type: action.payload.type || "info",
      });
    });
    builder.addCase(notifyL.fulfilled, (state, action) => {
      state.notifications.push({
        ...action.payload,
        type: action.payload.type || "info",
      });
    });
  },
});

export const {
  dismissNotification,
  showNetworkErrorMessage,
  hideNetworkErrorMessage,
} = slice.actions;
export default slice.reducer;
