import { cloneDeep } from "lodash";
import { StopPiece } from "../../../ducks/app/cargo-and-template-shared";

export const getAccumulatedCargoPieces = (
  stops: Partial<{ pieces: StopPiece[] | null }>[]
) => {
  const accumulated = stops.reduce((acc, curr) => {
    const pieces = cloneDeep(curr?.pieces);
    pieces?.forEach((piece) => {
      const existingPiece = acc.find((p) => p.unit === piece.unit);
      if (existingPiece) {
        existingPiece.quantity += piece.quantity;
      } else {
        acc.push(piece);
      }
    });
    return acc;
  }, [] as StopPiece[]);
  return accumulated.sort((a, b) =>
    a.unit ? a.unit.localeCompare(b.unit) : 1
  );
};
