import GenericPromptDialog from "./GenericPromptDialog/GenericPromptDialog";
import { archiveRoute } from "../../ducks/routes";
import { useAppDispatch } from "../../redux-store";
import { useTranslation } from "react-i18next";

const EndRouteDialogPrompt = (props: {
  route: {
    routeId: string;
    routeRef: string;
  };
  onClose: () => void;
}) => {
  const { route, onClose } = props;
  const { routeId, routeRef } = route;
  const { t } = useTranslation("modals");

  const dispatch = useAppDispatch();

  const onContinueClick = () => {
    dispatch(archiveRoute({ id: routeId }))
      .unwrap()
      .then(() => onClose());
  };

  return (
    <GenericPromptDialog
      title={t("endRouteWithInvoicedCargos.title")}
      text={t("endRouteWithInvoicedCargos.text", { routeRef })}
      continueText={t("endRouteWithInvoicedCargos.continueText")}
      continueButtonColor="primary"
      onContinueClick={onContinueClick}
      onCancelClick={onClose}
      focusOnContinueButton
    />
  );
};

export default EndRouteDialogPrompt;
