import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as t from "io-ts";
import { routingResponseT } from "dora-contracts";
import { AppThunkAction } from "../../../../redux-store";
import { loadCargosForRoute } from "../../../data/cargos";

type RoutingResponse = t.TypeOf<typeof routingResponseT>;

const prefix = "app/route-info/routing";

type State = {
  selectedRouteId: string | null;
  routeId: string | null;
  routing: RoutingResponse | null;
  largeMap: boolean;
};

const initialState: State = {
  selectedRouteId: null,
  routeId: null,
  routing: null,
  largeMap: false,
};

export const reloadRoute =
  (routeId: string): AppThunkAction =>
  (dispatch, getState) => {
    const loadedRouteId = getState().app.routeInfo.routing.routeId;
    const loadingRoutingRouteId = getState().data.routing?.routeId;
    if (loadedRouteId === routeId || loadingRoutingRouteId === routeId) {
      dispatch(loadCargosForRoute(routeId));
    }
  };

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setCurrentRouteFromURL(state, action: PayloadAction<string | null>) {
      state.selectedRouteId = action.payload;
    },
    setShowLargeMap(state, action: PayloadAction<boolean>) {
      state.largeMap = action.payload;
    },
  },
});

/**
 * @deprecated
 *
 * Don't call this directly. This is is controlled from URL alone
 * and setting this to a different value than the route in the URL will lead
 * to wrong behaviour.
 */
export const setCurrentRouteFromURL = slice.actions.setCurrentRouteFromURL;
export const { setShowLargeMap } = slice.actions;
export default slice.reducer;
