import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import LocationDot from "../components/LocationDot";
import "./VehicleDetailsLocation.scss";
import DoraButton from "../../../Components/Toolkit/DoraButton";
import TimeAgo from "../components/TimeAgo";
import { useAppDispatch, useSelector } from "../../../redux-store";
import { selectTrackerAddress } from "../../../ducks/data/tracker-devices/selectors";
import { selectTrailer } from "../../../ducks/app/fleet-management/selectors";
import { useEffect } from "react";
import { lookupDevicePosition } from "../../../ducks/data/tracker-devices";

const VehicleDetailsCurrentLocation = ({
  truckId,
  lastStop,
}: {
  truckId: string;
  lastStop: boolean;
}) => {
  const { t } = useTranslation(["components", "liveView"]);

  // TODO, merge into one selector
  const truck = useSelector(selectTrailer(truckId));
  const data = useSelector(
    selectTrackerAddress({ doraDeviceId: truck?.trackerDeviceId || "" })
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (truck?.trackerDeviceId) {
      dispatch(lookupDevicePosition(truck.trackerDeviceId));
    }
  }, [dispatch, truck]);

  const onRefreshClick = () => {
    // TODO: fetch data that also comes with a new update date
    if (truck?.trackerDeviceId) {
      dispatch(lookupDevicePosition(truck.trackerDeviceId));
    }
  };

  return (
    <div className="vehicle-details-location vehicle-details-location--current">
      {
        <div className="flex">
          <div>
            <div className="z-10">
              <LocationDot variant="green" />
            </div>
            {!lastStop && (
              <div className="flex justify-center h-full">
                <div className="route-stops__horizontal-gray-dashed-line"></div>
              </div>
            )}
          </div>
          <div className="vehicle-details-location__right">
            <div className="flex gap-4 items-center">
              <div>
                <div className="vehicle-details-location__type">
                  {data?.updatedAt && (
                    <TimeAgo lastUpdateDate={data.updatedAt} />
                  )}
                </div>
                <div className="vehicle-details-location__place">
                  {data?.place?.placeName}
                </div>
              </div>
              <DoraButton
                className="vehicle-details-location__refresh-button"
                variant={"ghost"}
                title={t("liveView:refresh")}
                aria-label={t("liveView:refresh")}
                onClick={onRefreshClick}
              >
                <FontAwesomeIcon
                  style={{ transform: "scale(1, -1)" }}
                  icon={faArrowsRotate}
                />
              </DoraButton>
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default VehicleDetailsCurrentLocation;
