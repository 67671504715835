import react from "react";

const features = {
  smartMatch: "staging",
  analytics: "staging",
  userMenu: "staging",
  bookkeeping: "staging",
  routeLog: "development",
  newRoutesTopBar: "disabled",
};

type Feature = keyof typeof features;

interface FeatureToggleProps {
  feature: Feature;
  children: react.ReactNode;
}

const isDevEnvironment =
  process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test";
const isStaging = window?.location?.host === "staging.doranordic.dk";

export const featureEnabled = (feature: Feature) => {
  switch (features[feature]) {
    case "development":
      return isDevEnvironment;
    case "staging":
      return isStaging || isDevEnvironment;
    case "disabled":
      return false;
    case "enabled":
      return true;
    default:
      return true;
  }
};

const FeatureToggle = (props: FeatureToggleProps) => {
  return featureEnabled(props.feature) ? <>{props.children}</> : null;
};

export default FeatureToggle;
