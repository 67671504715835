import * as t from "io-ts";
import axios, { getData } from "../../../axios";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as selectors from "./selectors";
import {
  BusinessCentralCompany,
  BusinessCentralConfig,
  BusinessCentralEnvironment,
} from "./types";
import * as tPromise from "io-ts-promise";
import {
  businessCentralCompanyT,
  businessCentralConfigT,
  businessCentralEnvironmentT,
} from "dora-contracts";
import {
  createErrorReportingAsyncThunk,
  defaultErrorAction,
} from "../../helpers";
import { AuthenticationResult } from "@azure/msal-browser";
import { AsyncAppThunkAction } from "../../../redux-store";

const prefix = "app/business-central";

type State = {
  // This is ALWAYS the value returned from the server, thus we can rely on
  // if this has an environment, the environment has been saved.
  configuration?: BusinessCentralConfig;
  unconfirmedAccessToken?: AuthenticationResult;
  environments?: BusinessCentralEnvironment[];
  companies?: BusinessCentralCompany[];
};

const initialState: State = {};

const handleConfiguration =
  (config: BusinessCentralConfig): AsyncAppThunkAction =>
  async (dispatch) => {
    dispatch(slice.actions.setConfiguration(config));
    if (config.accessTokenStatus === "INITIALIZED") {
      await dispatch(loadEnvironments());
      if (config.environmentName) {
        await dispatch(loadCompanies());
      }
    }
  };

export const confirmSetAccessToken = createErrorReportingAsyncThunk(
  "confirm-set-access-token",
  async (_, thunkApi) => {
    const bcState = selectors.selectBC(thunkApi.getState());
    if (!bcState) {
      return;
    }
    const token = bcState.unconfirmedAccessToken;
    if (!token) {
      throw new Error("Cannot confirm when token not set");
    }
    const result = await axios
      .put("/api/business-central/configuration/access-token", token)
      .then(getData)
      .then(tPromise.decode(businessCentralConfigT));
    if (result.accessTokenStatus === "INITIALIZED") {
      await thunkApi.dispatch(loadEnvironments());
      if (result.environmentName) {
        await thunkApi.dispatch(loadCompanies());
      }
    }
    thunkApi.dispatch(slice.actions.setConfiguration(result));
  }
);

export const setAccessToken =
  (token: AuthenticationResult): AsyncAppThunkAction =>
  async (dispatch, getState) => {
    const bcState = selectors.selectBC(getState());
    if (!bcState) {
      return;
    }
    const currentTenant = bcState?.configuration?.tenantId;
    if (currentTenant && currentTenant !== token.tenantId) {
      dispatch(slice.actions.setAccessTokenConfirmation(token));
      return;
    }
    try {
      const result = await axios
        .put("/api/business-central/configuration/access-token", token)
        .then(getData)
        .then(tPromise.decode(businessCentralConfigT));
      if (result.accessTokenStatus === "INITIALIZED") {
        await dispatch(loadEnvironments());
        if (result.environmentName) {
          await dispatch(loadCompanies());
        }
      }
      dispatch(slice.actions.setConfiguration(result));
    } catch (err) {
      dispatch(defaultErrorAction());
      throw err;
    }
  };

export const selectEnvironment =
  (environmentName: string): AsyncAppThunkAction =>
  async (dispatch, getState) => {
    const bcState = selectors.selectBC(getState());
    if (!bcState) {
      // Shouldn't get here, the component that dispatches this wouldn't be
      // mounted if this is not initialized
      throw new Error("BC State not initialized");
    }
    const environment = bcState.environments?.find(
      (x) => x.name === environmentName
    );
    if (!environment) {
      // Shouldn't get here, the component that dispatches this should have
      // only allowed selecting between valid environments
      throw new Error("BC State not initialized");
    }

    const result = await axios
      .put("/api/business-central/configuration/environment", environment)
      .then(getData)
      .then(tPromise.decode(businessCentralConfigT));
    await dispatch(handleConfiguration(result));
  };

export const selectCompany =
  (companyId: string): AsyncAppThunkAction =>
  async (dispatch, getState) => {
    const bcState = selectors.selectBC(getState());
    if (!bcState) {
      // Shouldn't get here, the component that dispatches this wouldn't be
      // mounted if this is not initialized
      throw new Error("BC State not initialized");
    }
    const company = bcState.companies?.find((x) => x.id === companyId);
    if (!company) {
      // Shouldn't get here, the component that dispatches this should only
      // have allowed selecting between valid companies
      throw new Error("Company id invalid");
    }
    const result = await axios
      .put("/api/business-central/configuration/company", company)
      .then(getData)
      .then(tPromise.decode(businessCentralConfigT));
    await dispatch(handleConfiguration(result));
  };

export const loadConfig = (): AsyncAppThunkAction => async (dispatch) => {
  const result = await axios
    .get("/api/business-central/configuration")
    .then(getData)
    .then(tPromise.decode(businessCentralConfigT));
  await dispatch(handleConfiguration(result));
};

const loadEnvironments = createAsyncThunk("load-environments", async () => {
  const result = await axios
    .get("/api/business-central/environments")
    .then(getData)
    .then(
      tPromise.decode(
        t.strict({
          environments: t.array(businessCentralEnvironmentT),
        })
      )
    );
  return result;
});

const loadCompanies = createAsyncThunk("load-companies", async () => {
  const result = await axios
    .get("/api/business-central/companies")
    .then(getData)
    .then(
      tPromise.decode(
        t.strict({
          companies: t.array(businessCentralCompanyT),
        })
      )
    );
  return result;
});

const slice = createSlice({
  initialState,
  name: prefix,
  reducers: {
    setConfiguration: (state, action: PayloadAction<BusinessCentralConfig>) => {
      state.configuration = action.payload;
    },
    setAccessTokenConfirmation: (
      state,
      action: PayloadAction<AuthenticationResult>
    ) => {
      state.unconfirmedAccessToken = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadEnvironments.fulfilled, (state, action) => {
        state.environments = action.payload.environments;
      })
      .addCase(loadCompanies.fulfilled, (state, action) => {
        state.companies = action.payload.companies;
      })
      .addCase(confirmSetAccessToken.fulfilled, (state) => {
        delete state.unconfirmedAccessToken;
      });
  },
});

export default slice.reducer;
