import { RootState } from "../../../redux-store";
import { createSelector } from "@reduxjs/toolkit";

export const selectInitialFormData = (state: RootState) =>
  state.app.cargoDialog.initialFormData;

export const selectResetFormData = (state: RootState) =>
  state.app.cargoDialog.resetFormData;

const selectPreviousFormData = (state: RootState) =>
  state.app.cargoDialog.previousFormData;

export const selectFormDataForWhenOpeningModal = createSelector(
  selectInitialFormData,
  selectPreviousFormData,
  (initial, previous) => previous || initial
);

export const selectIsSaving = (state: RootState) =>
  state.app.cargoDialog.isSaving;
