// TODO: move in shared folder as there is an almost duplicate of this in the mobile app
export default function sortByStartsWith<
  TKey extends string,
  TKey2 extends string,
>(startsWithInput: string, labelAttribute: TKey, identifierAttribute: TKey2) {
  const inputLower = startsWithInput.toLowerCase();
  return <
    T extends {
      [key in TKey | TKey2]: string;
    },
  >(
    a: T,
    b: T
  ) => {
    if (a[identifierAttribute] === inputLower) {
      return -1;
    }
    if (b[identifierAttribute] === inputLower) {
      return 1;
    }
    const aLabelLower = a[labelAttribute].toLowerCase();
    const bLabelLower = b[labelAttribute].toLowerCase();
    if (
      aLabelLower.startsWith(inputLower) &&
      !bLabelLower.startsWith(inputLower)
    ) {
      return -1;
    }
    if (
      !aLabelLower.startsWith(inputLower) &&
      bLabelLower.startsWith(inputLower)
    ) {
      return 1;
    }
    return 0;
  };
}
