import { useTranslation } from "react-i18next";

type Tab = "shipment" | "finances" | "log";

const ScrollNavigationTabs = () => {
  const { t } = useTranslation(["shipmentModal"]);

  const onTabClick = (tab: Tab) => {
    const el = document.getElementById("shipment-dialog-section__" + tab);
    if (el) {
      el.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className="shipment-dialog__tabs">
      <button
        className="shipment-dialog__tab"
        onClick={() => onTabClick("shipment")}
      >
        {t("shipmentModal:tabs.shipment")}
      </button>
      <button
        className="shipment-dialog__tab"
        onClick={() => onTabClick("finances")}
      >
        {t("shipmentModal:tabs.finances")}
      </button>
      <button
        className="shipment-dialog__tab"
        onClick={() => onTabClick("log")}
      >
        {t("shipmentModal:tabs.log")}
      </button>
    </div>
  );
};

export default ScrollNavigationTabs;
