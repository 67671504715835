import axios, { getData, isAxiosError } from "../../../axios";
import { createSlice } from "@reduxjs/toolkit";
import { createErrorReportingAsyncThunk } from "../../helpers";
import * as types from "./types";
import * as tPromise from "io-ts-promise";
import { AppThunkAction } from "../../../redux-store";

const prefix = "data/routing";

type State = {
  routeId: string | null;
  routing: types.Routing | null;
  allowTravelByFerryPrevious?: boolean;
};

const initialState: State = {
  routeId: null,
  routing: null,
};

export const loadRouting = createErrorReportingAsyncThunk(
  `${prefix}/load`,
  async (routeId: string, { dispatch }) => {
    return axios
      .get(`/api/routings?route_id=${routeId}`)
      .then(getData)
      .then(tPromise.decode(types.routingT))
      .catch((err) => {
        if (isAxiosError(err) && err.status === 404) {
          return null;
        }
      });
  }
);

export const setRoutingAllowTravelByFerry = createErrorReportingAsyncThunk(
  `${prefix}/setAllowTravelByFerry`,
  async (
    input: { routeId: string; allowTravelByFerry: boolean },
    { dispatch }
  ) => {
    return axios
      .post(`/api/routings/set-allow-ferry-travel?route_id=${input.routeId}`, {
        allowTravelByFerry: input.allowTravelByFerry,
      })
      .then(getData)
      .then(() => {
        dispatch(loadRouting(input.routeId));
      });
  }
);

export const routingUpdated =
  (routeId: string): AppThunkAction =>
  async (dispatch, getState) => {
    if (getState().data.routing?.routeId === routeId) {
      await dispatch(loadRouting(routeId));
    }
  };

const slice = createSlice({
  name: prefix,
  initialState: initialState as State,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadRouting.pending, (state, action) => {
        if (state && state.routeId === action.meta.arg) {
          return state;
        } else {
          return { routeId: action.meta.arg, routing: null };
        }
      })
      .addCase(setRoutingAllowTravelByFerry.pending, (state, action) => {
        return {
          ...state,
          routing: state?.routing
            ? {
                ...state.routing,
                allowTravelByFerry: action.meta.arg.allowTravelByFerry, // Optimistic update
              }
            : null,
          allowTravelByFerryPrevious: state.routing?.allowTravelByFerry,
        };
      })
      .addCase(setRoutingAllowTravelByFerry.rejected, (state, action) => {
        return {
          ...state,
          routing: state?.routing
            ? {
                ...state.routing,
                allowTravelByFerry: !!state.allowTravelByFerryPrevious,
              }
            : null,
        };
      })
      .addCase(loadRouting.fulfilled, (_state, action) => {
        if (action.payload) {
          return {
            routeId: action.meta.arg,
            routing: action.payload,
          };
        }
      });
  },
});

export default slice.reducer;
