import { useCallback, useEffect, useMemo, useState } from "react";
import { DateTime } from "luxon";
import { nanoid } from "nanoid";
import { UFDoraDatePicker } from "../../Toolkit/DoraDatePicker";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useRouteView } from "../../../ducks/app/dispatching/hooks";
import { selectCargoViewsBetweenDates } from "../../../ducks/data/cargo-views/selectors";
import { flatten } from "lodash";
import { UndispatchedCargosMap } from "./UndispatchedCargosMap";
import { CargoFormData } from "../../../ducks/app/cargo-dialog/types";
import Marker = H.map.Marker;
import { selectClients } from "../../../ducks/data/clients/selectors";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../redux-store";
import { getAllRoutes } from "../../../ducks/routes";
import "./index.scss";
import DoraButton from "../../Toolkit/DoraButton";
import { getClients } from "../../../ducks/data/clients";

type DateInterval = {
  from: DateTime | null;
  to: DateTime | null;
};

type MarkerData = {
  cargoId: string;
  lat: number;
  lng: number;
  isPickup: boolean;
  popupData: {
    clientName: string;
    loadmeter: number;
    weight: string;
    height: string;
    width: string;
    length: string;
  };
};

const pickupMarkerSvg = `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM135.1 217.4c-4.5 4.2-7.1 10.1-7.1 16.3c0 12.3 10 22.3 22.3 22.3H208v96c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V256h57.7c12.3 0 22.3-10 22.3-22.3c0-6.2-2.6-12.1-7.1-16.3L269.8 117.5c-3.8-3.5-8.7-5.5-13.8-5.5s-10.1 2-13.8 5.5L135.1 217.4z"/></svg>`;
const dropoffMarkerSvg = `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z"/></svg>`;

const pickupMarkerContainer = document.createElement("div");
const pickupMarkerContainerActive = document.createElement("div");
pickupMarkerContainer.className = `unassigned-stop-point-marker`;
pickupMarkerContainerActive.className = `unassigned-stop-point-marker--active`;
pickupMarkerContainer.innerHTML = pickupMarkerSvg;
pickupMarkerContainerActive.innerHTML = pickupMarkerSvg;

const pickupIcon = new H.map.DomIcon(pickupMarkerContainer);
const pickupIconActive = new H.map.DomIcon(pickupMarkerContainerActive);

const dropoffMarkerContainer = document.createElement("div");
const dropoffMarkerContainerActive = document.createElement("div");
dropoffMarkerContainer.className = `unassigned-stop-point-marker`;
dropoffMarkerContainerActive.className = `unassigned-stop-point-marker--active`;
dropoffMarkerContainer.innerHTML = dropoffMarkerSvg;
dropoffMarkerContainerActive.innerHTML = dropoffMarkerSvg;

const dropoffIcon = new H.map.DomIcon(dropoffMarkerContainer);
const dropoffIconActive = new H.map.DomIcon(dropoffMarkerContainerActive);

// TODO: having these in useState DOES NOT WORK! That's why they are added here
let trueMapRef: H.Map;

const UNDISPATCHED_MAP_DATES = "undispatchedMapDates";

const UndispatchedCargosMapPage = () => {
  const { t } = useTranslation(["modals", "components"]);
  const dispatch = useAppDispatch();
  const formMethods = useForm<DateInterval>({
    defaultValues: {
      from: DateTime.now(),
      to: DateTime.now().plus({ days: 1 }),
    },
  });
  const clients = useSelector(selectClients);

  const { control, watch, setValue } = formMethods;

  const fromId = useMemo(() => nanoid(), []);
  const toId = useMemo(() => nanoid(), []);

  const resetInfoSectionText = useCallback(() => {
    const infoSection = document.getElementsByClassName(
      "undispatched-map-dialog__marker-info"
    )[0];
    infoSection.innerHTML = t("modals:undispatchedCargos.hoverMapToSeeInfo");
  }, [t]);

  useEffect(() => {
    dispatch(getAllRoutes());
    dispatch(getClients());
  }, [dispatch]);

  useEffect(() => {
    resetInfoSectionText();
  }, [resetInfoSectionText]);

  useEffect(() => {
    const subscription = watch((data) => {
      const dateInterval = data as DateInterval;
      localStorage.setItem(
        UNDISPATCHED_MAP_DATES,
        JSON.stringify(dateInterval)
      );
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  let unassignedCargosIds = useRouteView(null).items;
  const filteredUnassignedCargos = useSelector(
    selectCargoViewsBetweenDates(
      unassignedCargosIds,
      watch("from"),
      watch("to")
    )
  );

  useEffect(() => {
    const dates = localStorage.getItem(UNDISPATCHED_MAP_DATES);
    if (dates) {
      const parsedDates = JSON.parse(dates);
      setValue("from", DateTime.fromISO(parsedDates.from));
      setValue("to", DateTime.fromISO(parsedDates.to));
    } else {
      const now = DateTime.now();
      setValue("from", now);
      setValue("to", now.plus({ days: 1 }));
    }
  }, [setValue]);

  const memoizedSelectedData = useMemo(
    () => JSON.stringify(filteredUnassignedCargos),
    [filteredUnassignedCargos]
  );

  const onClearDatesClick = useCallback(() => {
    setValue("from", DateTime.now());
    setValue("to", DateTime.now().plus({ days: 1 }));
  }, [setValue]);

  const [markers, setMarkers] = useState<H.map.Marker[]>([]);

  const getMapMarkers = () =>
    trueMapRef.getObjects().filter((m: any) => m.getData()) as Marker[];

  const onStopEnter = useCallback((marker: Marker) => {
    const data: MarkerData = marker.getData();
    const { cargoId } = data;
    for (const m of getMapMarkers()) {
      const markerData = m.getData();
      if (markerData.cargoId === cargoId) {
        m.setIcon(markerData.isPickup ? pickupIconActive : dropoffIconActive);
      }
    }
    const d = data.popupData;

    const infoSection = document.getElementsByClassName(
      "undispatched-map-dialog__marker-info"
    )[0];

    let dimensions = "";
    let dimensionsText = "";
    if (d.length) {
      dimensions += d.length;
      dimensionsText += "L";
    }
    if (d.width) {
      dimensions += ` x ${d.width}`;
      dimensionsText += " x W";
    }
    if (d.height) {
      dimensions += ` x ${d.height}`;
      dimensionsText += " x H";
    }
    if (dimensions) {
      dimensions += "cm.";
    }

    infoSection.innerHTML = `
      <div class="undispatched-map-dialog__popup-title">${d.clientName}</div>
      <div><span>${d.loadmeter}</span> ldm.</div>
      ${
        dimensions
          ? `<div>
            <span>${dimensions}</span> (${dimensionsText})
          </div>`
          : ""
      }
      ${d.weight ? `<div>${d.weight} kg</div>` : ""}
    `;
  }, []);

  const onStopExit = useCallback(
    (marker: Marker) => {
      const { cargoId } = marker.getData() as MarkerData;
      for (const m of getMapMarkers()) {
        const markerData = m.getData();
        if (markerData.cargoId === cargoId) {
          m.setIcon(markerData.isPickup ? pickupIcon : dropoffIcon);
        }
      }
      resetInfoSectionText();
    },
    [resetInfoSectionText]
  );

  const buildMarkers = useCallback(async () => {
    const markers = [];
    const filteredCargos = JSON.parse(memoizedSelectedData);
    for (const cargo of filteredCargos) {
      const res = await fetch(`/api/cargos/${cargo.id}`);
      const cargoWithDetails: CargoFormData = await res.json();
      const stops = [
        ...cargoWithDetails.pickupList.map((p) => ({ isPickup: true, ...p })),
        ...cargoWithDetails.dropoffList.map((d) => ({ isPickup: false, ...d })),
      ];
      for (const stop of stops) {
        if (!stop) {
          return null;
        }
        const lat = stop.place?.coord.lat!;
        const lng = stop.place?.coord.lon!;
        const client = clients.find((c) => c.id === cargoWithDetails.clientId);
        const clientName = client ? client.client : "";
        const markerData: MarkerData = {
          cargoId: cargo.id,
          lat,
          lng,
          isPickup: stop.isPickup,
          popupData: {
            clientName,
            loadmeter: stop.loadmeters as number,
            weight: stop.weight,
            height: stop.height,
            width: stop.width,
            length: stop.length,
          },
        };
        const m = new H.map.DomMarker(
          { lat, lng },
          {
            icon: stop.isPickup ? pickupIcon : dropoffIcon,
            data: markerData,
          }
        );
        m.addEventListener("pointerenter", () => onStopEnter(m));
        m.addEventListener("pointerleave", () => onStopExit(m));
        markers.push(m);
      }
    }

    setMarkers(flatten(markers.filter((m) => m)));
  }, [setMarkers, clients, memoizedSelectedData, onStopExit, onStopEnter]);

  const onMapLoaded = (map: H.Map) => {
    trueMapRef = map;
  };

  useEffect(() => {
    buildMarkers();
  }, [memoizedSelectedData, clients, buildMarkers]);

  return (
    <FormProvider {...formMethods}>
      <div className="undispatched-map-dialog">
        <h1>{t("modals:undispatchedCargos.title")}</h1>
        <div className="undispatched-map-dialog__controls">
          <label htmlFor={fromId}>From:</label>
          <UFDoraDatePicker id={fromId} name="from" control={control} />
          <label htmlFor={toId}>To:</label>
          <UFDoraDatePicker id={toId} name="to" control={control} />
          <DoraButton variant="primary-filled" onClick={onClearDatesClick}>
            {t("components:buttonLabels.clear")}
          </DoraButton>
        </div>
        <div className="undispatched-map-dialog__map-wrapper">
          <div className="undispatched-map-dialog__marker-info"></div>
          <UndispatchedCargosMap markers={markers} onHMapLoaded={onMapLoaded} />
        </div>
      </div>
    </FormProvider>
  );
};

export default UndispatchedCargosMapPage;
