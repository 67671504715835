import { Toolbar, IconButton, Box, Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HelpIcon from "@mui/icons-material/Help";
import { AppBar } from "./nav-styles";
import logo from "../../styles/logo.svg";
import FeatureToggle from "../FeatureToggle";
import UserAccountMenu from "./UserAccountMenu";
import Notifications from "./Notifications";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SettingsMenu from "./SettingsMenu";
import "./topbar.scss";

export default function TopBar(props: {
  open: boolean;
  handleDrawerOpen: () => void;
}) {
  const { t } = useTranslation("navigation");
  const navigate = useNavigate();

  const onHelpClick = () => navigate("/help-and-support");

  return (
    <>
      <AppBar position="fixed" open={props.open}>
        <Toolbar variant="dense">
          <Box
            sx={{
              flexGrow: 1,
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <IconButton
              color="inherit"
              onClick={props.handleDrawerOpen}
              className="top-menu-icon-button"
              edge="start"
              sx={{
                marginRight: "36px",
                ...(props.open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <img src={logo} height={17} alt="dora logo" />
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "flex-end",
              // justifyContent: "space-between",
              maxWidth: 380, // 420
            }}
          >
            <Button
              variant="text"
              color="inherit"
              size="large"
              endIcon={<HelpIcon />}
              onClick={onHelpClick}
              // href="https://youtube.com/playlist?list=PLMVqAt1RgVm9rPl2k3SkCy2F9h4wpnI9Q" // https://www.vimeo.com/showcase/dora or https://vimeo.com/showcase/9471815
              // target="_blank"
            >
              {t("topbar.helpAndSupport")}
            </Button>
            {/* <MuiTooltip title="OBS." placement="bottom">
                            <IconButton
                                color="inherit"
                                size="large"
                            >
                                <WarningIcon />
                            </IconButton>
                        </MuiTooltip> */}
            <FeatureToggle feature="userMenu">
              <Notifications />
            </FeatureToggle>
            <SettingsMenu />
            <UserAccountMenu />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
