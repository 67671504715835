import { RootState } from "../../../redux-store";
import { createSelector } from "@reduxjs/toolkit";

export const selectEntities = (state: RootState) =>
  state.data.expenseAccounts.entities;
const selectIds = (state: RootState) => state.data.expenseAccounts.ids;

export const selectExpenseAccounts = createSelector(
  selectIds,
  selectEntities,
  (ids, entities) => ids.map((id) => entities[id])
);
