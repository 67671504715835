import { createSlice } from "@reduxjs/toolkit";
import { createErrorReportingAsyncThunk } from "../../helpers";
import type { Dictionary } from "lodash";
import axios, { getData } from "../../../axios";
import { Trailer, decodeTrailers } from "./types";
import keyBy from "lodash/keyBy";

const prefix = "data/archived-trailers";

type State = {
  entities: Dictionary<Trailer>;
  ids: string[];
};

const initialState: State = {
  entities: {},
  ids: [],
};

export const loadArchivedTrailers = createErrorReportingAsyncThunk(
  `${prefix}/load`,
  async (): Promise<Trailer[]> => {
    return await axios
      .get("/api/trailers?archived=true")
      .then(getData)
      .then(decodeTrailers);
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadArchivedTrailers.fulfilled, (state, action) => {
      const trailers = action.payload;
      state.entities = keyBy(trailers, "id");
      state.ids = trailers.map((x) => x.id);
    });
  },
});

export default slice.reducer;
