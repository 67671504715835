import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { cargoTypes } from "dora-contracts";
import { cargoQualities, trailerQualities } from "../../../helpers/types";
import { useAppDispatch, useSelector } from "../../../redux-store";
import { selectTeams } from "../../../ducks/data/teams/selectors";
import { loadTeams } from "../../../ducks/data/teams";

const useShipmentFormSelectOptions = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadTeams());
  }, [dispatch]);

  const teams = useSelector(selectTeams);
  const { t } = useTranslation("types");
  const cargoTypeOptions = useMemo(
    () =>
      cargoTypes.map((id) => ({
        value: id,
        label: t("cargoTypes.type", { context: id }),
      })),
    [t]
  );
  const qualityOptions = useMemo(
    () =>
      cargoQualities.map((id) => ({
        id,
        label: t("cargoQualities.type", { context: id }),
      })),
    [t]
  );
  const trailerQualityOptions = useMemo(
    () =>
      trailerQualities.map((id) => ({
        id,
        label: t("trailerQualities.type", { context: id }),
      })),
    [t]
  );
  const teamsOptions = useMemo(
    () =>
      teams.map((t) => ({
        label: t.name,
        value: t.id,
      })),
    [teams]
  );
  return {
    teamsOptions,
    trailerQualityOptions,
    qualityOptions,
    cargoTypeOptions,
  };
};

export default useShipmentFormSelectOptions;
