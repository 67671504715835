import { TachoDriverStatus } from "../../../ducks/data/tracker-devices";

const DriverTachoStats = ({ stats }: { stats: TachoDriverStatus }) => {
  const secondsToHoursMinutes = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}h ${minutes}m`;
  };

  return (
    <div className="grid grid-cols-2 gap-x-2 font-normal mt-2">
      {stats.continuousDrivingDuration && (
        <>
          <div>Continuous driving duration:</div>
          <div> {secondsToHoursMinutes(stats.continuousDrivingDuration)}</div>
        </>
      )}
      {stats.cumulativeBreakDuration && (
        <>
          <div>Cumulative break duration:</div>
          <div> {secondsToHoursMinutes(stats.cumulativeBreakDuration)}</div>
        </>
      )}
      {stats.cumulativeDrivingDuration && (
        <>
          <div>Cumulative driving duration:</div>
          <div>{secondsToHoursMinutes(stats.cumulativeDrivingDuration)}</div>
        </>
      )}
      {stats.selectedActivityDuration && (
        <>
          <div>Selected activity duration:</div>
          <div>{secondsToHoursMinutes(stats.selectedActivityDuration)}</div>
        </>
      )}
      {stats.workState && (
        <>
          <div>Work state:</div>
          <div>{stats.workState}</div>
        </>
      )}
      {stats.timeState && (
        <>
          <div>Time state:</div>
          <div>{stats.timeState}</div>
        </>
      )}
    </div>
  );
};

export default DriverTachoStats;
