import { useEffect, useRef, useState } from "react";
import { useCreateMap } from "../../../integration/useCreateMap";
import {
  HereMapsFutureStopContainer,
  HereMapsPastStopContainer,
} from "./HereMapsStopMarkers";
import { useAppDispatch, useSelector } from "../../../redux-store";
import { selectTrailer } from "../../../ducks/data/trailers/selectors";
import HereMapsColoredTruckMarker from "../components/HereMapsColoredTruckMarker";
import { selectTrackerDeviceMessagesForLiveView } from "../../../ducks/data/live-view/selectors";
import { loadFlespiMessagesForDevice } from "../../../ducks/data/live-view";
import { FlespiMessage } from "dora-contracts";

type StopLocations = {
  lat: number;
  lon: number;
}[];

const LiveViewMapForTruckRouteView = (props: {
  className?: string;
  truckId: string;
  startDateForMessages: string | null;
  pastStopsLocations: StopLocations;
  futureStopsLocations: StopLocations;
}) => {
  const {
    truckId,
    startDateForMessages,
    pastStopsLocations,
    futureStopsLocations,
  } = props;
  const mapRef = useRef<HTMLDivElement>(null);
  const [mapInitialized, setMapInitialized] = useState(false);
  const hMap = useRef<H.Map>();
  const ui = useRef<H.ui.UI | null>();
  const behaviour = useRef<H.mapevents.Behavior>();
  const createMap = useCreateMap(true);
  const truck = useSelector(selectTrailer(truckId));
  const trackerDeviceMessages = useSelector(
    selectTrackerDeviceMessagesForLiveView
  );
  const [firstSegmentAdded, setFirstSegmentAdded] = useState(false);
  const [multiLineString, setMultiLineString] = useState<
    H.geo.MultiLineString | undefined
  >();
  const [lastCoordMessageIndex, setLastCoordMessageIndex] = useState<number>(0);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!truck) {
      return;
    }

    if (startDateForMessages) {
      dispatch(
        loadFlespiMessagesForDevice({
          trackerDeviceId: truck.trackerDeviceId!,
          startDate: startDateForMessages,
          endDate: new Date().toISOString(),
        })
      );
    }
  }, [truck, dispatch, startDateForMessages]);

  // Setup map
  useEffect(() => {
    // Instantiate the map
    const mapDiv = mapRef.current;
    const { map, mapUi } = createMap(mapDiv!);
    hMap.current = map;

    // Enable the event system on the map instance
    const mapEvents = new H.mapevents.MapEvents(map);
    // Instantiate the default behavior, providing the mapEvents object
    behaviour.current = new H.mapevents.Behavior(mapEvents);
    // Create the default UI:
    // ui.current = H.ui.UI.createDefault(map, defaultLayers, "en-US");

    ui.current = mapUi;

    const resize = () => {
      map.getViewPort().resize();
    };
    window.addEventListener("resize", resize);
    setMapInitialized(true);

    return () => {
      window.removeEventListener("resize", resize);
      // Remove old map if this rerenders (this happens on save file while developing)
      while (mapDiv?.firstChild) {
        mapDiv.removeChild(mapDiv.firstChild);
      }
    };
  }, [createMap]);

  useEffect(() => {
    const drawDistanceSkip = 2;
    const startIndex = Math.max(lastCoordMessageIndex - 1, 0);
    const endIndex = trackerDeviceMessages.length;
    if (startIndex >= endIndex - drawDistanceSkip) {
      return;
    }
    const line = trackerDeviceMessages.slice(startIndex, endIndex);
    setLastCoordMessageIndex(trackerDeviceMessages.length);
    buildLine(line);
  }, [trackerDeviceMessages, lastCoordMessageIndex]);

  // @ts-ignore
  useEffect(() => {
    if (!hMap.current || !multiLineString) {
      return;
    }

    // Render route on the map
    // Create a polyline to display each line segment as they are built:
    const routeOutlineSegment = new H.map.Polyline(multiLineString, {
      style: { lineWidth: 6, strokeColor: "rgba(0, 0, 0, 1)" },
    } as any);

    hMap.current.addObjects([routeOutlineSegment]);
    if (!firstSegmentAdded) {
      // Zoom to bounds of stops / route
      const routeBounds = multiLineString.getBoundingBox();
      const allStops = [...pastStopsLocations, ...futureStopsLocations];
      let maxBounds = routeBounds;
      if (allStops.length > 1) {
        const segments: H.geo.LineString[] = [];
        for (let i = 1; i < allStops.length; i++) {
          const segment = new H.geo.LineString();
          const prev_lat = allStops[i - 1].lat;
          const prev_lng = allStops[i - 1].lon;
          const curr_lat = allStops[i].lat;
          const curr_lng = allStops[i].lon;
          segment.pushPoint({ lat: prev_lat, lng: prev_lng });
          segment.pushPoint({ lat: curr_lat, lng: curr_lng });
          segments.push(segment);
        }
        const multiLineStringForStops = new H.geo.MultiLineString(segments);
        const stopsBounds = multiLineStringForStops.getBoundingBox();

        maxBounds = new H.geo.Rect(
          Math.min(routeBounds!.getTop(), stopsBounds!.getTop()),
          Math.min(routeBounds!.getLeft(), stopsBounds!.getLeft()),
          Math.max(routeBounds!.getBottom(), stopsBounds!.getBottom()),
          Math.max(routeBounds!.getRight(), stopsBounds!.getRight())
        );
      }

      const marginFactor = 0.1; // 10% margin
      const latMargin =
        (maxBounds.getTop() - maxBounds.getBottom()) * marginFactor;
      const lngMargin =
        (maxBounds.getRight() - maxBounds.getLeft()) * marginFactor;

      const extendedBounds = new H.geo.Rect(
        maxBounds.getTop() + latMargin,
        maxBounds.getLeft() - lngMargin,
        maxBounds.getBottom() - latMargin,
        maxBounds.getRight() + lngMargin
      );

      if (extendedBounds) {
        hMap.current.getViewModel().setLookAtData({ bounds: extendedBounds });
      }
    }
    setFirstSegmentAdded(true);

    // return () => {
    //   if (!hMap.current) {
    //     return;
    //   }
    //   hMap.current.removeObjects([routeOutline]);
    // };
  }, [multiLineString]);

  const buildLine = (messages: FlespiMessage[]) => {
    const segments: H.geo.LineString[] = [];
    if (messages.length < 2) {
      return;
    }
    for (let i = 1; i < messages.length; i++) {
      const segment = new H.geo.LineString();
      const prev_lat = messages[i - 1]["position.latitude"];
      const prev_lng = messages[i - 1]["position.longitude"];
      const curr_lat = messages[i]["position.latitude"];
      const curr_lng = messages[i]["position.longitude"];
      segment.pushPoint({ lat: prev_lat, lng: prev_lng });
      segment.pushPoint({ lat: curr_lat, lng: curr_lng });
      segments.push(segment);
    }
    setMultiLineString(new H.geo.MultiLineString(segments));
    // return new H.geo.MultiLineString(segments);
  };

  return (
    <>
      <div id="mapContainer" className={props.className} ref={mapRef} />
      {mapInitialized && (
        <>
          {pastStopsLocations.map((stop, i) => (
            <HereMapsPastStopContainer
              key={i}
              lat={stop.lat}
              lng={stop.lon}
              mapRef={hMap}
            />
          ))}
          {futureStopsLocations.map((stop, i) => (
            <HereMapsFutureStopContainer
              key={i}
              lat={stop.lat}
              lng={stop.lon}
              mapRef={hMap}
            />
          ))}
          {truck && (
            <HereMapsColoredTruckMarker
              key={truck.id}
              trailerId={truck.id}
              mapRef={hMap}
            />
          )}
        </>
      )}
    </>
  );
};

export default LiveViewMapForTruckRouteView;
