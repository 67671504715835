import { useSelector } from "react-redux";
import { setFilterOptions } from "../../../ducks/app/dispatching";
import { selectFilterOptions } from "../../../ducks/app/dispatching/selectors";
import { useMyTeams } from "../../../ducks/data/teams/hooks";
import { useAppDispatch } from "../../../redux-store";
import * as selectors from "./selectors";
import * as types from "./types";

export const useRouteView = (routeId: string | null) => {
  const localState = useSelector(selectors.selectRouteCargoIds(routeId));
  return { items: localState };
};

export const useTeamFilters = (filterType: types.FilterType) => {
  const dispatch = useAppDispatch();
  const selectedTeams = useSelector(selectFilterOptions(filterType));
  const teams = useMyTeams();
  const isTeamSelected = (id: string) => selectedTeams.includes(id);
  const setTeamSelected = (input: { teamId: string; enabled: boolean }) => {
    dispatch(setFilterOptions({ ...input, filterType }));
  };
  return { teams, isTeamSelected, setTeamSelected };
};

export const useRouteFilterOptions = () => useTeamFilters("routes");
export const useCargoFilterOptions = () => useTeamFilters("cargos");
