import { createSlice } from "@reduxjs/toolkit";
import axios from "../../../axios";
import { Account, accountsT } from "./types";
import * as tPromise from "io-ts-promise";
import keyBy from "lodash/keyBy";
import { createErrorReportingAsyncThunk } from "../../helpers";
const prefix = "data/expense-accounts";

interface State {
  entities: { [key: string]: Account };
  ids: string[];
}

const initialState: State = {
  entities: {},
  ids: [],
};

export const sync = () => () => {
  axios.post("/api/e-conomic/accounts/sync");
};

export const loadExpenseAccounts = createErrorReportingAsyncThunk(
  `${prefix}/load-accounts`,
  async () => {
    const { data } = await axios.get("/api/expense-accounts");
    return await tPromise.decode(accountsT, data);
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadExpenseAccounts.fulfilled, (state, action) => {
      return {
        entities: keyBy(action.payload, "id"),
        ids: action.payload.map((x) => x.id),
      };
    });
  },
});

export default slice.reducer;
