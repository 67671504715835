import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLanguage } from "../../../i18n/i18n";

const TimeAgo = ({ lastUpdateDate }: { lastUpdateDate: DateTime }) => {
  const { t } = useTranslation("liveView");

  const [timeAgo, setTimeAgo] = useState<string>(t("now"));

  const locale = useMemo(getLanguage, []);

  const calculateTimeAgo = useCallback(
    (date: DateTime) => {
      const timeText =
        date.diff(DateTime.utc()).as("minutes") < -1
          ? date.toRelative({ locale })
          : t("now");
      setTimeAgo(timeText);
    },
    [setTimeAgo, t, locale]
  );

  useEffect(() => {
    calculateTimeAgo(lastUpdateDate);
  }, [lastUpdateDate, calculateTimeAgo]);

  useEffect(() => {
    const timeUpdateInterval = setInterval(
      () => calculateTimeAgo(lastUpdateDate),
      10 * 1000
    );
    return () => clearInterval(timeUpdateInterval);
  }, [calculateTimeAgo, lastUpdateDate]);

  return <>{timeAgo}</>;
};

export default TimeAgo;
