import { MutableRefObject, useEffect, useMemo } from "react";
import { selectTrailer } from "../../../ducks/data/trailers/selectors";
import getColoredTruckHtml from "../helpers/colored-trucks-rendered-html";
import { selectTrailerTelemetry } from "../../../ducks/app/fleet-management/selectors";
import { useSelector } from "../../../redux-store";

/**
 * Renderless component, that displays a truck on a map.
 *
 * Written as a react component to piggy back into the automated rerender when
 * redux changes, AND, because it needs access to the HERE maps map object
 * which is created in a react component.
 */
const HereMapsColoredTruckMarker = (props: {
  trailerId: string;
  mapRef: MutableRefObject<H.Map | undefined>;
}) => {
  // Note, there is a small thing not working 100% correct, but in practice
  // doesn't have any effect.
  //
  // When this is rendered the first time, the mapRef is not yet initialized,
  // thus the marker is not added on the map on the first render.
  // But since messages are received asynchronously, the map _should_ be
  // initialized when the first message is received, and this rerenders.
  const { trailerId, mapRef } = props;
  const map = mapRef.current;
  const data = useSelector(selectTrailerTelemetry(trailerId));
  const trailer = useSelector(selectTrailer(trailerId));
  const truckHtmlContainer = getColoredTruckHtml(trailer!.color);
  const marker = useMemo(
    () =>
      new H.map.DomMarker(
        { lat: 0, lng: 0 },
        {
          icon: new H.map.DomIcon(truckHtmlContainer),
          data: "",
          visibility: false,
        }
      ),
    [truckHtmlContainer]
  );
  useEffect(() => {
    if (!data) {
      return;
    }
    const lat = data.lat;
    const lng = data.lon;
    if (typeof lat !== "number" || typeof lng !== "number") {
      return;
    }
    marker.setVisibility(true);
    marker.setGeometry({ lat, lng });
  }, [marker, data]);
  useEffect(() => {
    if (!map) {
      return;
    }
    map.addObject(marker);
    return () => {
      map.removeObject(marker);
    };
  }, [map, marker]);
  return null;
};

export default HereMapsColoredTruckMarker;
