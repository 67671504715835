import { SearchOption } from "./PlaceSearchWithSavedLocations";
import { useState } from "react";
import {
  Place,
  StarBorder,
  Apartment,
  ChevronRight,
  ChevronLeft,
  Add,
  Star,
} from "@mui/icons-material";
import "./SearchResultOption.scss";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { AutoSuggestItem } from "dora-shared";
import RenderParsedText from "../../../location-search-result/RenderParsedText";
// import { useSelector } from "../../../../redux-store";
// import { isLocationWithCoordsSaved } from "../../../../ducks/data/saved-locations/selectors";

const SearchResultOption = ({
  option,
  onBookmarkClick,
}: {
  option: SearchOption;
  onBookmarkClick: (location: SearchOption) => void;
}) => {
  const { t } = useTranslation("modals");
  const { type, value } = option;
  const [showBookmark, setShowBookmark] = useState(false);
  // const isLocationSaved = useSelector(
  //   isLocationWithCoordsSaved(searchResultCoords)
  // );
  // if (type === "SEARCH_RESULT") {
  //   setSearchResultCoords({
  //     lon: value.data.geometry.location.lng,
  //     lat: value.data.geometry.location.lat,
  //   });
  // }

  let component;

  switch (type) {
    case "TERMINALS_MENU":
      component = (
        <div className="search-result-option__wrapper">
          <Apartment className="search-result-option__icon" />
          <div className="search-result-option__text">
            {t("manageSavedLocations.terminals")}
          </div>
          <ChevronRight className="search-result-option__chevron" />
        </div>
      );
      break;
    case "TERMINALS_BACK":
      component = (
        <div className="search-result-option__wrapper">
          <ChevronLeft className="search-result-option__chevron" />
          <div className="search-result-option__text">
            {t("manageSavedLocations.terminals")}
          </div>
        </div>
      );
      break;
    case "ADD_TERMINAL":
      component = (
        <div className="search-result-option__wrapper">
          <Add className="search-result-option__icon" />
          <div className="search-result-option__terminal-text">
            {t("manageSavedLocations.addTerminal")}
          </div>
        </div>
      );
      break;
    default:
      switch (value.locationType) {
        case "TERMINAL":
          component = (
            <div className="search-result-option__wrapper">
              <Apartment className="search-result-option__icon" />
              <div className="search-result-option__text">
                {value.data.customName}
              </div>
            </div>
          );
          break;
        case "FAVORITE":
          component = (
            <div className="search-result-option__wrapper">
              <Star className="search-result-option__icon" />
              <div className="search-result-option__text">
                {value.data.customName}
              </div>
            </div>
          );
          break;
        default:
          const data = value.data as AutoSuggestItem;
          component = (
            <div className="search-result-option__wrapper">
              <Place className="search-result-option__icon" />
              <div className="search-result-option__text">
                <div>
                  <RenderParsedText item={data.title} />
                </div>
                <Typography variant="h4" color="text.secondary">
                  <RenderParsedText item={data.address} secondary />
                </Typography>
              </div>
              {showBookmark && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopPropagation();
                    e.nativeEvent.preventDefault();
                    onBookmarkClick(option);
                  }}
                >
                  <StarBorder className="search-result-option__bookmark" />
                  {/*{isLocationSaved ? (*/}
                  {/*  <Star className="search-result-option__bookmark" />*/}
                  {/*) : (*/}
                  {/*  <StarBorder className="search-result-option__bookmark" />*/}
                  {/*)}*/}
                </div>
              )}
            </div>
          );
          break;
      }
  }

  return (
    <div
      onMouseEnter={() => setShowBookmark(true)}
      onMouseLeave={() => setShowBookmark(false)}
      className="search-result-option__container"
    >
      {component}
    </div>
  );
};

export default SearchResultOption;
