import { useSelector } from "react-redux";
import "./IncomeHandler.scss";
import { selectInvoiceLines } from "../../../../../ducks/data/deliveries/selectors";
import { useL10n } from "../../../../../l10n";
import { Fragment, useMemo, useState } from "react";
import AddInvoiceLineRow from "./AddInvoiceLineRow";
import InvoiceLineEditingRow from "./EditInvoiceLineRow";
import { useTranslation } from "react-i18next";
import DoraButton from "../../../../Toolkit/DoraButton";
import Decimal from "decimal.js-light";
import { faLock, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as actions from "../../../../../ducks/data/deliveries";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { useAppDispatch } from "../../../../../redux-store";
import { DoraCheckbox } from "../../../../Toolkit/DoraCheckbox";
import ProductCellValue from "./ProductCellValue";
import UnitCellValue from "./UnitCellValue";

const IncomeHandler = (props: {
  deliveryId: string;
  selectedLineIds: string[];
  showAddNewLine: boolean;
  onCancelNewLine: () => void;
  onSelectedLinesChange: (lineIds: string[]) => void;
  onLinesCreated: (newLineIds: string[]) => void;
  clientId?: string;
}) => {
  const { t } = useTranslation(["invoicing", "components"]);
  const {
    deliveryId,
    onSelectedLinesChange,
    selectedLineIds,
    showAddNewLine,
    onCancelNewLine,
    clientId,
  } = props;
  const lines = useSelector(selectInvoiceLines(deliveryId));
  const dispatch = useAppDispatch();

  const [editingLineId, setEditingLineId] = useState<string | null>(null);
  const lineViewModels = useMemo(
    () =>
      lines.map((line) => ({
        line,
        selected: selectedLineIds.includes(line.id),
        editing: editingLineId === line.id,
      })),
    [lines, selectedLineIds, editingLineId]
  );

  const l10n = useL10n();

  const allLinesChecked = useMemo(
    () => selectedLineIds.length === lines.filter((l) => !l.locked).length,
    [selectedLineIds, lines]
  );

  const setSelected = (id: string, selected: boolean) => {
    const previousSelection = [...selectedLineIds];
    if (selected) {
      if (previousSelection.includes(id)) {
        return;
      }
      onSelectedLinesChange([...previousSelection, id]);
    } else {
      onSelectedLinesChange(previousSelection.filter((x) => x !== id));
    }
  };

  const onEditClick = (lineId: string) => {
    setEditingLineId(lineId);
  };

  const onAllCheckboxClick = (v: boolean) => {
    if (allLinesChecked) {
      onSelectedLinesChange([]);
      return;
    }
    onSelectedLinesChange(lines.filter((x) => !x.locked).map((x) => x.id));
  };

  const onAfterCreate = (newLineIds: string[]) => {
    props.onLinesCreated(newLineIds);
  };

  const onDeleteClick = (lineId: string) => {
    dispatch(
      actions.deleteLine({
        deliveryId,
        lineId,
      })
    ).unwrap();
  };

  return (
    <>
      <div className="income-grid" role="grid">
        <div role="row" className="income-grid__row">
          <div role="gridcell" className="income-grid__heading">
            <div className="income-grid-heading__checkbox">
              <DoraCheckbox
                checked={allLinesChecked}
                onChange={onAllCheckboxClick}
              />
            </div>
          </div>
          <div
            role="gridcell"
            className="income-grid__heading"
            id="income-grid-heading-product"
          >
            {t("invoicing:invoiceLine.code")}
          </div>
          <div role="gridcell" className="income-grid__heading">
            {t("invoicing:invoiceLine.description")}
          </div>
          <div
            role="gridcell"
            className="income-grid__heading flex justify-end"
          >
            {t("invoicing:invoiceLine.quantity")}
          </div>
          <div
            role="gridcell"
            className="income-grid__heading"
            id="income-grid-heading-unit"
          >
            {t("invoicing:invoiceLine.unit")}
          </div>
          <div
            role="gridcell"
            className="income-grid__heading flex justify-end"
          >
            {t("invoicing:invoiceLine.amount")}
          </div>
          <div
            role="gridcell"
            className="income-grid__heading flex justify-end"
            id="income-grid-heading-percentage"
          >
            {t("invoicing:invoiceLine.percentage")}
          </div>
          <div role="gridcell" className="income-grid__heading">
            {t("invoicing:invoiceLine.created")}
          </div>
          <div
            role="gridcell"
            className="income-grid__heading flex justify-end"
          >
            {t("invoicing:invoiceLine.sum")}
          </div>
          <div role="gridcell" className="income-grid__heading"></div>
        </div>
        {lineViewModels.map(({ line, selected, editing }) => (
          <Fragment key={line.id}>
            {editing ? (
              <InvoiceLineEditingRow
                line={line}
                deliveryId={deliveryId}
                clientId={clientId}
                onCancel={() => setEditingLineId(null)}
                onAfterEdit={() => setEditingLineId(null)}
              />
            ) : (
              <div role="row" className="income-grid__row">
                <div
                  role="gridcell"
                  className="flex items-center income-grid-row__checkbox-cell"
                >
                  <DoraCheckbox
                    checked={selected}
                    disabled={line.locked}
                    onChange={() =>
                      !line.locked && setSelected(line.id, !selected)
                    }
                  />
                </div>
                <div
                  role="gridcell"
                  className="flex items-center income-grid__grid-cell"
                  aria-describedby="income-grid-heading-product"
                >
                  {line.productId && (
                    <ProductCellValue productId={line.productId} />
                  )}
                </div>
                <div
                  role="gridcell"
                  className="flex items-center income-grid__grid-cell"
                >
                  {line.description}
                </div>
                <div role="gridcell" className="flex items-center justify-end">
                  {l10n.formatNumber(line.quantity)}
                </div>
                <div role="gridcell" className="flex items-center">
                  {line.unitId && <UnitCellValue unitId={line.unitId} />}
                </div>
                <div role="gridcell" className="flex items-center justify-end">
                  {l10n.formatCurrency(line.amount)}
                </div>
                <div
                  role="gridcell"
                  className="flex items-center justify-end"
                  aria-describedby="income-grid-heading-percentage"
                >
                  {line.percentage && l10n.formatPercentage(line.percentage)}
                </div>
                <div role="gridcell" className="flex items-center">
                  {l10n.formatDate(line.date)}
                </div>
                <div role="gridcell" className="flex items-center justify-end">
                  {l10n.formatCurrency(
                    new Decimal(line.amount).mul(new Decimal(line.quantity))
                  )}
                </div>
                <div role="gridcell" className="flex items-center justify-end">
                  {line.locked ? (
                    <div style={{ padding: 8, marginRight: 8 }}>
                      <FontAwesomeIcon
                        style={{ color: "var(--gray-400)" }}
                        icon={faLock}
                      />
                    </div>
                  ) : (
                    <>
                      <DoraButton
                        variant="ghost"
                        style={{ color: "var(--gray-400)" }}
                        onClick={(_) => onEditClick(line.id)}
                        aria-label={t("components:buttonLabels.edit")}
                        title={t("components:buttonLabels.edit")}
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </DoraButton>
                      <DoraButton
                        variant="ghost"
                        style={{ color: "var(--gray-400)" }}
                        aria-label={t("components:buttonLabels.delete")}
                        title={t("components:buttonLabels.delete")}
                        onClick={() => onDeleteClick(line.id)}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </DoraButton>
                    </>
                  )}
                </div>
              </div>
            )}
          </Fragment>
        ))}
        {showAddNewLine && (
          <AddInvoiceLineRow
            onCancel={onCancelNewLine}
            deliveryId={deliveryId}
            clientId={clientId}
            onAfterCreate={onAfterCreate}
          />
        )}
      </div>
    </>
  );
};

export default IncomeHandler;
