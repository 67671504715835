import axios from "../../../axios";
import { AppDispatch } from "../../../redux-store";
import { createSlice } from "@reduxjs/toolkit";
import * as tPromise from "io-ts-promise";
import * as t from "io-ts";
import {
  createProductsSurchargesAndAgreementsT,
  clientProductPricingAgreementCreateUpdateReqBodyT,
} from "dora-contracts";
import keyBy from "lodash/keyBy";
import { createErrorReportingAsyncThunk, unwrap } from "../../helpers";
import { notifyL } from "../../notifications";
import { getClients } from "../clients";
import { prettyPrintIfDecodeError } from "../../../helpers/io-ts-helpers";

export const productsSurchargesAndAgreementsT =
  createProductsSurchargesAndAgreementsT({
    decimalT: t.string,
  });

export interface Product {
  id: string;
  unitId: string | null;
  productNumber: string;
  name: string;
  salesPrice: string | null;
  surcharges: string[];
}

export type PricingAgreement = {
  id: string;
  clientId: string;
  unitId: string | null;
  unitNumber: number | null;
  productId: string;
  name: string | null;
  salesPrice: string;
};

type PricingAgreementReqBody = t.TypeOf<
  typeof clientProductPricingAgreementCreateUpdateReqBodyT
>;

export type Surcharge = {
  id: string;
  productNumber: string;
  name: string;
  salesPrice: string | null;
  percentage: string | null;
};

interface State {
  entities: { [key: string]: Product };
  surchargeEntities: { [key: string]: Surcharge };
  surcharges: Surcharge[];
  pricingAgreements: {
    [clientId: string]: PricingAgreement[];
  };
  ids: string[]; // product ids
}

const initialState: State = {
  entities: {},
  surchargeEntities: {},
  surcharges: [],
  ids: [],
  pricingAgreements: {},
};

const prefix = "data/products";

export const loadProducts = createErrorReportingAsyncThunk(
  `${prefix}/loadProducts`,
  async () => {
    const deliveries = await axios.get(`/api/e-conomic/products`);
    return await tPromise
      .decode(productsSurchargesAndAgreementsT, deliveries.data)
      .catch(prettyPrintIfDecodeError);
  }
);

export const convertProductToSurcharge = createErrorReportingAsyncThunk(
  `${prefix}/convertProductToSurcharge`,
  async (product: Product, { dispatch }) => {
    await axios.post(
      `/api/e-conomic/products/${product.id}/convertProductToSurcharge`
    );
    return dispatch(loadProducts());
  }
);

export const convertSurchargeToProduct = createErrorReportingAsyncThunk(
  `${prefix}/convertProductToSurcharge`,
  async (surcharge: Surcharge, { dispatch }) => {
    await axios.post(
      `/api/e-conomic/products/${surcharge.id}/convertSurchargeToProduct`
    );
    return dispatch(loadProducts());
  }
);

export const toggleSurchargeForAllCustomers = createErrorReportingAsyncThunk(
  `${prefix}/applySurchargeToAllCustomers`,
  async (surcharge: Surcharge, { dispatch }) => {
    await axios.post(
      `/api/e-conomic/products/${surcharge.id}/toggle-surcharge-for-all-customers`
    );
    dispatch(loadProducts());
    return dispatch(getClients());
  }
);

const doSetSurchargePercentage = createErrorReportingAsyncThunk(
  `${prefix}/setSurchargePercentage`,
  async ({
    surcharge,
    percentage,
  }: {
    surcharge: Surcharge;
    percentage: string;
  }) => {
    await axios.put(`/api/e-conomic/products/${surcharge.id}/set-percentage`, {
      percentage,
    });
  }
);

const doApplySurchargesForProduct = createErrorReportingAsyncThunk(
  `${prefix}/applySurchargesForProduct`,
  async (
    { product, surchargesIds }: { product: Product; surchargesIds: string[] },
    { dispatch }
  ) => {
    return axios
      .post(
        `/api/e-conomic/products/${product.id}/apply-surcharges-for-product`,
        { surchargesIds }
      )
      .then(() =>
        dispatch(
          notifyL({
            namespace: "notifications",
            key: "surchargeForProductSaved",
            type: "success",
          })
        )
      );
  }
);

export const setSurchargePercentage = unwrap(doSetSurchargePercentage);

export const applySurchargesForProduct = unwrap(doApplySurchargesForProduct);

export const syncProducts = () => async (dispatch: AppDispatch) => {
  await axios.post(`/api/e-conomic/products/sync`);
  return dispatch(loadProducts());
};

export const syncProductsManual = () => async (dispatch: AppDispatch) => {
  return dispatch(syncProducts()).then(() => {
    dispatch(
      notifyL({
        namespace: "notifications",
        key: "productsSynced",
        type: "success",
      })
    );
  });
};

export const createPricingAgreement = createErrorReportingAsyncThunk(
  `${prefix}/create-pricing-agreement`,
  async (pricingAgreement: PricingAgreementReqBody, { dispatch }) => {
    await axios.post(
      `/api/e-conomic/client-product-pricing-agreements`,
      clientProductPricingAgreementCreateUpdateReqBodyT.encode(pricingAgreement)
    );
    return dispatch(loadProducts());
  }
);

export const updatePricingAgreement = createErrorReportingAsyncThunk(
  `${prefix}/update-pricing-agreement`,
  async (
    pricingAgreement: PricingAgreementReqBody & { id: string },
    { dispatch }
  ) => {
    await axios.put(
      `/api/e-conomic/client-product-pricing-agreements/${pricingAgreement.id}`,
      clientProductPricingAgreementCreateUpdateReqBodyT.encode(pricingAgreement)
    );
    return dispatch(loadProducts());
  }
);

export const deletePricingAgreement = createErrorReportingAsyncThunk(
  `${prefix}/delete-pricing-agreement`,
  async (pricingAgreementId: string, { dispatch }) => {
    await axios.delete(
      `/api/e-conomic/client-product-pricing-agreements/${pricingAgreementId}`
    );
    return dispatch(loadProducts());
  }
);

const slice = createSlice({
  name: "data/products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadProducts.fulfilled, (_state, action) => {
      const pricingAgreements: Record<string, PricingAgreement[]> = {};
      for (const pricingAgreement of action.payload.pricingAgreements) {
        if (pricingAgreements[pricingAgreement.clientId]) {
          pricingAgreements[pricingAgreement.clientId].push(pricingAgreement);
        } else {
          pricingAgreements[pricingAgreement.clientId] = [pricingAgreement];
        }
      }
      return {
        ...initialState,
        surcharges: action.payload.surcharges,
        entities: keyBy(action.payload.products, "id"),
        surchargeEntities: keyBy(action.payload.surcharges, "id"),
        ids: action.payload.products.map((x) => x.id),
        pricingAgreements,
      };
    });
  },
});

export default slice.reducer;
