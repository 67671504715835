import { useEffect, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import {
  AUTH_STATUS_UNINITIALIZED,
  AUTH_STATUS_AUTHENTICATED,
  AUTH_STATUS_UNAUTHENTICATED,
  AUTH_STATUS_AUTHENTICATING,
  AUTH_STATUS_ERROR,
  checkAuthState,
} from "../ducks/auth";
import { useSelector } from "react-redux";
import { selectAuthStatus } from "../ducks/auth/selectors";
import { useAppDispatch } from "../redux-store";
import AuthErrorPage from "./AuthErrorPage";

// A component that checks that you are authenticated, and only renders
// its children if you are.
const RequireAuth = ({
  children,
  redirectToHome,
}: {
  children: ReactNode;
  redirectToHome?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const authStatus = useSelector(selectAuthStatus);
  useEffect(() => {
    dispatch(checkAuthState());
  }, [dispatch]);
  switch (authStatus) {
    case AUTH_STATUS_UNINITIALIZED:
    case AUTH_STATUS_AUTHENTICATING:
      return null;
    case AUTH_STATUS_AUTHENTICATED:
      return <>{children}</>;
    case AUTH_STATUS_UNAUTHENTICATED:
      return redirectToHome ? <Navigate to="/welcome" /> : null;
    case AUTH_STATUS_ERROR:
      return <AuthErrorPage />;
    default:
      return null;
  }
};

export default RequireAuth;
