import { createSlice } from "@reduxjs/toolkit";
import * as t from "io-ts";
import { savedLocationT } from "dora-contracts";
import * as tPromise from "io-ts-promise";
import SavedLocation from "../../../Components/modals/ManageFavoritesAndTerminalsDialog/SavedLocation";
import { createErrorReportingAsyncThunk, unwrap } from "../../helpers";
import axios from "../../../axios";

const prefix = "data/saved-locations";

const savedLocationsT = t.array(savedLocationT);

type State = {
  locations: SavedLocation[];
};

const initialState: State = {
  locations: [],
};

export const loadSavedLocations = createErrorReportingAsyncThunk(
  `${prefix}/load`,
  async () => {
    const locations = await axios.get(`/api/saved-locations`);
    return await tPromise.decode(savedLocationsT, locations.data);
  }
);

const doCreateSavedLocation = createErrorReportingAsyncThunk(
  `${prefix}/create`,
  async (location: Partial<SavedLocation>) => {
    await axios.post(`/api/saved-locations`, location);
  }
);

const doUpdateSavedLocation = createErrorReportingAsyncThunk(
  `${prefix}/update`,
  async (location: SavedLocation) => {
    await axios.put(`/api/saved-locations/${location.id}`, location);
  }
);

const doDeleteSavedLocation = createErrorReportingAsyncThunk(
  `${prefix}/delete`,
  async (location: SavedLocation) => {
    await axios.delete(`/api/saved-locations/${location.id}`);
  }
);

export const deleteSavedLocation = unwrap(doDeleteSavedLocation);
export const updateSavedLocation = unwrap(doUpdateSavedLocation);
export const createSavedLocation = unwrap(doCreateSavedLocation);

const slice = createSlice({
  name: prefix,
  initialState: initialState as State,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadSavedLocations.fulfilled, (_state, action) => {
      return {
        ...initialState,
        locations: action.payload,
      };
    });
  },
});

export default slice.reducer;
