import { Container, Box, Grid } from "@mui/material";
import Logo from "../styles/logo.svg";
import Image from "../styles/register.jpg";

const UnauthenticatedLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box sx={{ minHeight: "100vh", display: "flex" }}>
      <Grid container spacing={{ xs: 0, md: 1 }}>
        <Grid item xs={12} sm={6} md={6}>
          <Box
            sx={{
              backgroundImage: `url(${Image})`,
              backgroundSize: "cover",
              width: "100%",
              height: "100%",
              bgcolor: "primary.dark",
              "&:hover": {
                bgcolor: "primary.main",
                opacity: [0.9, 0.8, 0.7],
              },
            }}
          >
            <img src={Logo} alt="" style={{ width: 120, padding: 10 }} />
            {/* <Typography
                            variant="h6"
                            component="h2"
                            style={{
                                color: "white",
                                fontWeight: 400,
                                paddingLeft: 10,
                            }}
                        >
                            Disponering med netværk i fokus
                        </Typography> */}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{ flexGrow: 1 }}>
          <Container sx={{ height: "100vh" }}>{children}</Container>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UnauthenticatedLayout;
