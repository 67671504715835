import { combineReducers } from "redux";
import invoices from "./invoices";
import settings from "./settings";
import customerContacts from "./customer-contacts";

export default combineReducers({
  invoices,
  settings,
  customerContacts,
});
