import axios from "../axios";
import { useState } from "react";
import { UAParser } from "ua-parser-js";

export function useAuthSubmit(url, vals) {
  const [error, setError] = useState();
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(url, vals)
      .then(({ data }) => {
        if (data.success) {
          const parser = new UAParser(window.navigator.userAgent); // you need to pass the user-agent for nodejs
          const parserResults = parser.getResult();
          window.location.replace(
            parserResults.device.type === "mobile" ? "/live-view" : "/"
          );
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        console.log(`error in axios post ${url}`, err);
        setError(true);
      });
  };
  return [error, handleSubmit];
}
