import { Settings as LuxonSettings } from "luxon";

declare module "luxon" {
  interface TSSettings {
    throwOnInvalid: true;
  }
}

const initLuxon = () => {
  LuxonSettings.throwOnInvalid = true;
};

export default initLuxon;
