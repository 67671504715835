import { createErrorReportingAsyncThunk } from "../../helpers";
import axios from "../../../axios";
import { createSlice } from "@reduxjs/toolkit";
import * as tPromise from "io-ts-promise";
import { CargosReportTableData, cargosReportTableDataT } from "./types";

const prefix = "data/cargo-report";

type State = {
  data: CargosReportTableData;
};

const initialState: State = {
  data: [],
};

export const loadCargosReport = createErrorReportingAsyncThunk(
  `${prefix}/load-cargos-report`,
  async (input: {
    from: string | null;
    to: string | null;
    customerId: string | null;
    cargoId: string | null;
  }) => {
    return axios
      .get("/api/mileage-reports/for-cargos", {
        params: input,
      })
      .then((x) => x.data)
      .then(tPromise.decode(cargosReportTableDataT));
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadCargosReport.fulfilled, (state, action) => {
      state.data = action.payload;
    });
  },
});

export default slice.reducer;
