import { useSelector } from "react-redux";
import { selectProduct, selectSurcharge } from "../../../../../ducks/data/products/selectors";

const ProductCellValue = (props: { productId: string }) => {
  const product = useSelector(selectProduct(props.productId));
  const surcharge = useSelector(selectSurcharge(props.productId));
  const displayedProduct = product || surcharge;
  return displayedProduct ? (
    <>
      {displayedProduct.productNumber} - {displayedProduct.name}
    </>
  ) : null;
};

export default ProductCellValue;