import { RootState } from "../../redux-store";

const empty: string[] = [];

export const selectMyTeams = (state: RootState) =>
  state.auth.user?.teamIds || empty;
export const selectAuthStatus = (state: RootState) => state.auth.status;
export const selectUser = (state: RootState) => state.auth.user;
export const selectDefaultPhoneCountryCode = (state: RootState) =>
  state.auth.user?.defaultPhoneCountryCode || "";
