import * as t from "io-ts";

export const invoiceT = t.type({
  id: t.string,
  clientId: t.string,
  total: t.string,
  sentToEconomic: t.boolean,
});

export const invoicesT = t.array(invoiceT);
export type Invoice = t.TypeOf<typeof invoiceT>;
