import { RootState } from "../../../redux-store";
import values from "lodash/values";
import { selectDriver } from "../drivers/selectors";
import { selectWebBooker } from "../web-bookers/selectors";
import { selectClient } from "../clients/selectors";

export const selectCargoDocuments = (cargoId: string) => (state: RootState) =>
  state.data.cargoDocuments.byCargoId[cargoId] || [];

export const selectFormattedUploader =
  (documentId: string) => (state: RootState) => {
    const document = values(state.data.cargoDocuments.byCargoId)
      .flatMap((x) => x)
      .find((x) => x?.id === documentId);

    if (!document) return null;
    const { addedBy } = document;
    switch (addedBy.userType) {
      case "DRIVER": {
        const driver = selectDriver(addedBy.driverId)(state);
        return driver ? `${driver.firstName} ${driver.lastName}` : null;
      }
      case "WEB_BOOKER": {
        const webBooker = selectWebBooker(addedBy.webBookerId)(state);
        if (!webBooker) return null;
        const client = selectClient(webBooker.clientId)(state);
        if (!client) return null;
        return `${webBooker.firstName} ${webBooker.lastName} (${client.client})`;
      }
      default:
        return null;
    }
  };
