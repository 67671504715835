import axios from "../../../axios";
import { createSlice } from "@reduxjs/toolkit";
import { routesT, Route } from "./types";
import * as tPromise from "io-ts-promise";
import * as dataActions from "../../data/trailers";
import * as dialogActions from "../trailer-dialog";
import { createErrorReportingAsyncThunk } from "../../helpers";
import { notifyL } from "../../notifications";
import { CANNOT_ARCHIVE_TRAILER_WITH_ROUTES } from "dora-shared";

const prefix = "/app/FleetManagementPage";

const decodeRoutes = tPromise.decode(routesT);

export const getRoutesForTrailer = createErrorReportingAsyncThunk(
  `${prefix}/get-route`,
  async (trailerId: string) => {
    const axiosResult = await axios.get(`/api/trailers/${trailerId}/routes`, {
      params: { active: "true" },
    });
    const routes = await decodeRoutes(axiosResult.data);
    return { trailerId, routes };
  }
);

export const editTrailer = createErrorReportingAsyncThunk(
  `${prefix}/edit-trailer`,
  (trailerId: string, thunkApi) => {
    const trailer = thunkApi.getState().data.trailers.entities[trailerId];
    thunkApi.dispatch(dialogActions.editTrailer(trailer));
  }
);

export const archiveTrailer = createErrorReportingAsyncThunk(
  `${prefix}/archive-trailer`,
  (trailerId: string, thunkAPI) => {
    return axios
      .post(`/api/trailers/${trailerId}/archive`)
      .then(() => {
        thunkAPI.dispatch(dataActions.loadTrailers());
      })
      .catch((err) => {
        if (
          err.response.status === 400 &&
          err.response.data.message === CANNOT_ARCHIVE_TRAILER_WITH_ROUTES
        ) {
          thunkAPI.dispatch(
            notifyL({
              type: "error",
              namespace: "notifications",
              key: "cannotArchiveAssignedVehicle",
            })
          );
          return;
        }
        throw err;
      });
  }
);

export const activateTrailer = createErrorReportingAsyncThunk(
  `${prefix}/activate`,
  async (id: string, thunkAPI) => {
    return axios.post(`/api/trailers/${id}/activate`).then(() => {
      thunkAPI.dispatch(dataActions.loadTrailers());
    });
  }
);

export const saveTrailer = dataActions.updateTrailer;

export const getTrailers = createErrorReportingAsyncThunk(
  `${prefix}/get-trailers`,
  async (_: void, thunkApi) => {
    const trailers = await thunkApi
      .dispatch(dataActions.loadTrailers())
      .unwrap();
    trailers.forEach(({ id }) => thunkApi.dispatch(getRoutesForTrailer(id)));
    return trailers;
  }
);

interface State {
  routes: { [key: string]: Route[] };
  status: "UNINITIALIZED" | "LOADING" | "LOADED";
}

const initialState: State = {
  routes: {},
  status: "UNINITIALIZED",
};

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTrailers.pending, (state) => {
        state.status = "LOADING";
      })
      .addCase(getTrailers.fulfilled, (state, action) => {
        state.status = "LOADED";
      })
      .addCase(getRoutesForTrailer.fulfilled, (state, action) => {
        const { trailerId, routes } = action.payload;
        state.routes[trailerId] = routes;
      });
  },
});

export default slice.reducer;
