import { createSlice, createAction } from "@reduxjs/toolkit";
import {
  RouteViewModel,
  routeViewModelT,
  routeViewModelsT,
} from "dora-contracts";
import axios, { getData } from "../../../axios";
import { createErrorReportingAsyncThunk } from "../../helpers";
import * as tPromise from "io-ts-promise";
import keyBy from "lodash/keyBy";
import { moveCargo } from "../../dispatchActions";
import { addRoute, getAllArchivedRoutes, getAllRoutes } from "../../routes";
import { AppThunkAction } from "../../../redux-store";
import * as authSelectors from "../../auth/selectors";
import { cargoViewT, CargoView } from "../cargo-views/types";
import * as t from "io-ts";
import { loadCalendarViewData } from "../calendar-view";

const prefix = "data/route-views";

type State = {
  entities: Record<string, RouteViewModel | undefined>;
  routeIds: string[];
};

const initialState: State = { entities: {}, routeIds: [] };

export const loadRouteViewModels = createErrorReportingAsyncThunk(
  `${prefix}/load-all`,
  async () => {
    const result = axios
      .get("/api/routes/view-models")
      .then(getData)
      .then(tPromise.decode(routeViewModelsT));
    return result;
  }
);

export const routeViewModelDecoded = createAction<{
  route: RouteViewModel;
  cargos: CargoView[];
  userTeams: string[];
}>(`${prefix}/model-updated`);

const viewModelUpdatedT = t.strict({
  route: routeViewModelT,
  cargos: t.array(cargoViewT),
});

export const viewModelUpdated =
  (input: unknown): AppThunkAction =>
  (dispatch, getState) => {
    const result = viewModelUpdatedT.decode(input);
    switch (result._tag) {
      case "Left":
        throw new Error("Invalid input");
      case "Right":
        const userTeams = authSelectors.selectMyTeams(getState());
        dispatch(routeViewModelDecoded({ ...result.right, userTeams }));
        if (getState().data.calendarView.pageInView) {
          dispatch(loadCalendarViewData());
        }
    }
  };

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadRouteViewModels.fulfilled, (state, action) => {
        state.entities = { ...state.entities, ...keyBy(action.payload, "id") };
        state.routeIds = action.payload.map((x) => x.id);
      })
      .addCase(routeViewModelDecoded, (state, action) => {
        const { route, userTeams } = action.payload;
        const routeId = route.id;
        state.entities[routeId] = route;
        const userCanSeeRoute =
          userTeams.includes(route.assignedTeamId) && !route.archived;
        if (userCanSeeRoute) {
          if (!state.routeIds.includes(routeId)) {
            state.routeIds.unshift(routeId);
          }
        } else {
          state.routeIds = state.routeIds.filter((x) => x !== routeId);
        }
      })
      .addCase(moveCargo.fulfilled, (state, action) => {
        for (const route of action.payload.updatedCargoOrders) {
          const entity = state.entities[route.routeId];
          if (entity) {
            entity.cargoOrder = route.cargoOrder;
          }
        }
      })
      .addCase(getAllArchivedRoutes.fulfilled, (state, action) => {
        const routeViews = keyBy(action.payload.routeViews, "id");
        state.entities = { ...state.entities, ...routeViews };
      })
      .addCase(addRoute.fulfilled, (state, action) => {
        const routeId = action.payload.id;
        if (!state.entities[routeId]) {
          state.entities[routeId] = {
            id: routeId,
            cargoOrder: [],
            trailerId: null,
            note: null,
            ref: null,
            assignedTeamId: action.payload.assignedTeamId,
            lastStop: null,
            firstStop: null,
            startDate: null,
            endDate: null,
            name: null,
            drivers: [],
            archived: false,
            waypointNote: null,
          };
        }
        if (!state.routeIds.includes(routeId)) {
          state.routeIds.unshift(routeId);
        }
      });
  },
});

export default slice.reducer;
