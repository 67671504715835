import { useParams } from "react-router-dom";
import TrailerPositionHistoryDialog from "../FleetManagementPage/VehiclesTab/TrailerPositionHistoryDialog";
import { selectTrackerForTrailer } from "../../ducks/app/fleet-management/selectors";
import { useSelector } from "react-redux";

const VehiclePage = () => {
  const { vehicleId } = useParams();
  const trackedDevice = useSelector(selectTrackerForTrailer(vehicleId));
  if (!trackedDevice) {
    return <h1>No tracking information for vehicle</h1>;
  }
  return <TrailerPositionHistoryDialog trackerDeviceId={trackedDevice.id} />;
};

export default VehiclePage;
