import { RootState } from "../../../redux-store";
import { WeightAddedCargoEvent } from "./types";
import Decimal from "decimal.js-light";
import { DateTime, Duration } from "luxon";
import { createSelector } from "@reduxjs/toolkit";

export const selectCargoEvents = (state: RootState) =>
  state.data.cargoEvents.entities;

export const selectCargoDriverTimeTrackingTotalTime = createSelector(
  selectCargoEvents,
  (cargoEvents) => {
    const types = [
      "DRIVER_CARGO_TIME_TRACKING_STARTED",
      "DRIVER_CARGO_TIME_TRACKING_PAUSED",
      "DRIVER_CARGO_TIME_TRACKING_RESUMED",
      "DRIVER_CARGO_TIME_TRACKING_STOPPED",
    ];
    const timeTrackingCargoEvents = cargoEvents.filter((event) =>
      types.includes(event.type)
    );
    const hasStart = timeTrackingCargoEvents.find(
      (e) => e.type === "DRIVER_CARGO_TIME_TRACKING_STARTED"
    );
    if (!hasStart) {
      return null;
    }
    const hasStop = timeTrackingCargoEvents.find(
      (e) => e.type === "DRIVER_CARGO_TIME_TRACKING_STOPPED"
    );
    if (!hasStop) {
      return "-";
    }

    let totalTimeInMs = 0;
    for (let i = 0; i < timeTrackingCargoEvents.length - 1; i++) {
      const currentEvent = timeTrackingCargoEvents[i];
      const nextEvent = timeTrackingCargoEvents[i + 1];
      if (currentEvent.type === "DRIVER_CARGO_TIME_TRACKING_PAUSED") {
        continue;
      }
      totalTimeInMs += DateTime.fromISO(nextEvent.time).diff(
        DateTime.fromISO(currentEvent.time)
      ).milliseconds;
    }
    const diff = Duration.fromMillis(totalTimeInMs);
    let value = "";
    const hours = Math.floor(diff.as("hours"));
    const minutes = Math.floor(diff.as("minutes")) % 60;

    if (hours) {
      value += hours.toFixed(0) + "h ";
    }
    if (minutes) {
      value += minutes.toFixed(0) + "m";
    }
    return value;
  }
);

export const selectWeightCargoEvents = createSelector(
  selectCargoEvents,
  (events) =>
    events.filter(
      (event) => event.type === "CARGO_WEIGHT_ADDED"
    ) as WeightAddedCargoEvent[]
);

export const selectTotalWeight = createSelector(
  selectWeightCargoEvents,
  (events) =>
    events.reduce((acc, event) => acc.plus(event.weight), new Decimal(0))
);

export const selectTimeSpent = createSelector(selectCargoEvents, (events) => {
  // const { t } = useTranslation("shipmentModal");
  const timeRelevantEvents = events?.filter((e) =>
    [
      "CARGO_COLLECTED",
      "CARGO_DELIVERED",
      "DRIVER_ARRIVED",
      "DRIVER_DEPARTED",
    ].includes(e.type)
  );
  let value = "";
  if (timeRelevantEvents?.length) {
    timeRelevantEvents.sort((a, b) => {
      return new Date(a.time).getTime() - new Date(b.time).getTime();
    });
    const firstEvent = timeRelevantEvents[0];
    const lastEvent = timeRelevantEvents[timeRelevantEvents.length - 1];
    if (firstEvent !== lastEvent) {
      const lastEventTime = DateTime.fromISO(lastEvent.time).set({
        second: 0,
      });
      const firstEventTime = DateTime.fromISO(firstEvent.time).set({
        second: 0,
        millisecond: 0,
      });
      const diff = lastEventTime.diff(firstEventTime, ["hours", "minutes"]);
      const { hours, minutes } = diff.toObject();
      if (hours) {
        // value += hours.toFixed(0) + `${t("hoursAbbreviation")} `;
        value += hours.toFixed(0) + `t `;
      }
      if (minutes) {
        value += minutes.toFixed(0) + "m";
      }
    }
  }
  return value;
});
