import { useSelector } from "react-redux";
import * as authSelectors from "../../ducks/auth/selectors";
import { Grid, Container, Typography } from "@mui/material";
import { NoShadowCard } from "../../Components/dispatching-planning/common/no-shadow-card";

const UserAccountPage = () => {
  const user = useSelector(authSelectors.selectUser)!;
  return (
    <>
      <Container maxWidth={false}>
        <Typography variant="overline">
          Min konto {user.company_name}
        </Typography>
        <Grid container spacing={2} mt={1}>
          <Grid item xs>
            <NoShadowCard
              style={{
                minHeight: "calc(100vh - 147px)",
                maxHeight: "calc(100vh - 147px)",
                overflowY: "auto",
              }}
            >
              <Typography>Personlige oplysninger</Typography>
              <Typography>Organisation oplysninger</Typography>
              <Typography>Skift sprog</Typography>
              <Typography>Link til data processing + privacy policy</Typography>
            </NoShadowCard>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default UserAccountPage;
