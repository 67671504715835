import { RootState, useSelector } from "../../../redux-store";
import { createSelector } from "@reduxjs/toolkit";
import { selectFeature } from "../../features/selectors";
import { Product } from "./index";

export const selectProduct = (id?: string) => (state: RootState) =>
  id && state.data.products.entities[id];

export const selectSurcharge = (id?: string) => (state: RootState) =>
  id && state.data.products.surchargeEntities[id];

export const selectProductMap = (state: RootState) =>
  state.data.products.entities;

export const selectSurchargesMap = (state: RootState) =>
  state.data.products.surchargeEntities;

export const selectProducts = createSelector(
  selectProductMap,
  (state: RootState) => state.data.products.ids,
  (entities, ids) =>
    ids
      .map((id) => entities[id])
      .sort((a, b) => Number(a.productNumber) - Number(b.productNumber))
);

export const selectPricingAgreementsForClient =
  (clientId: string) => (state: RootState) => {
    const featureEnabled = state.features["pricing-agreements"]?.enabled;
    if (!featureEnabled) {
      return [];
    }
    return state.data.products.pricingAgreements[clientId] || [];
  };

export const selectSurcharges = (state: RootState) =>
  state.data.products.surcharges;
