import FeatureToggledFlespiConnector from "../../Components/FlespiConnector";
import VehicleDetailsPage from "./VehicleDetails/VehicleDetailsPage";
import LiveViewPage from "./LiveView";
import { Route, Routes } from "react-router-dom";

const LiveViewPages = () => {
  return (
    <>
      <FeatureToggledFlespiConnector />
      <Routes>
        <Route path="/" element={<LiveViewPage />} />
        <Route path="/:id" element={<VehicleDetailsPage />} />
      </Routes>
    </>
  );
};

export default LiveViewPages;
