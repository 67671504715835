import GenericPromptDialog from "../GenericPromptDialog/GenericPromptDialog";
import { useTranslation } from "react-i18next";

export const ConfirmDeleteLocationDialog = ({
  locationName,
  onCancelClick,
  onContinueClick,
}: {
  locationName: string;
  onCancelClick: () => void;
  onContinueClick: () => void;
}) => {
  const { t } = useTranslation("modals");
  return (
    <GenericPromptDialog
      title={t("confirmDeleteSavedLocation.remove")}
      text={t("confirmDeleteSavedLocation.message", {
        locationName: `<b>${locationName}</b>`,
      })}
      onCancelClick={onCancelClick}
      onContinueClick={onContinueClick}
      continueButtonColor="error"
      continueText={t("confirmDeleteSavedLocation.remove")}
    />
  );
};
