import { RootState } from "../../../redux-store";
import { createSelector } from "@reduxjs/toolkit";

export const selectClients = createSelector(
  (state: RootState) => state.data.clients.entities,
  (state: RootState) => state.data.clients.ids,
  (entities, ids) => ids.map((id) => entities[id])
);

export const selectClient = (id?: string | null) => (state: RootState) =>
  (id && state.data.clients.entities[id]) || null;

export const selectClientForCargo = (cargoId: string) => (state: RootState) => {
  const cargo = state.data.cargos.entities[cargoId];
  if (!cargo) {
    return null;
  }
  return cargo.clientId ? state.data.clients.entities[cargo.clientId] : null;
};

export const selectIsLoading = (state: RootState) => state.data.clients.loading;
