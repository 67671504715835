import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../redux-store";
import mapValues from "lodash/mapValues";
import { CustomerContact } from "./types";

const selectIdsByClientId = (state: RootState) =>
  state.data.economics.customerContacts.idsByClientId;
const selectEntities = (state: RootState) =>
  state.data.economics.customerContacts.entities;

export const selectEntity = (id: string) => (state: RootState) =>
  selectEntities(state)[id];

const selectDenormalizedContacts = createSelector(
  selectIdsByClientId,
  selectEntities,
  (idsByClientId, entities) =>
    mapValues(idsByClientId, (x) => x.map((id) => entities[id]))
);

export const selectCustomerContacts =
  (clientId: string | null) =>
  (state: RootState): CustomerContact[] | null =>
    (clientId && selectDenormalizedContacts(state)[clientId]) || null;
