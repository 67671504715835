import {
  ERR_CANNOT_DELETE_STOP_WITH_EVENTS,
  ERR_OUTDATED_CARGO_REVISION,
} from "dora-shared";
import axios, { getData } from "../../../axios";
import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";
import {
  CargoFormData,
  decodeCargo,
  editCargoOutputT,
  editCargoInputT,
  postCargoT,
  CargoData,
} from "./types";
import { omit } from "lodash";
import {
  apiCargoToFormData,
  defaultFormData,
  formDataStopToApiStop,
} from "./helpers";
import { notifyL } from "../../notifications";
import * as t from "io-ts";
import { AppDispatch, GetState } from "../../../redux-store";
import { createErrorReportingAsyncThunk } from "../../helpers";
import * as tPromise from "io-ts-promise";
import { selectCargoView } from "../../data/cargo-views/selectors";
import { deliveryT } from "../../data/deliveries/types";
import { deliveryUpdated } from "../../data/deliveries";
import { CargoFormDataBase } from "../cargo-and-template-shared";

export type CargoFromApi = t.TypeOf<typeof editCargoOutputT>;

const prefix = "app/cargo-dialog";

type CargoDialogStatus = "CREATE" | "EDIT" | "CLOSED";

interface CargoDialogState {
  status: CargoDialogStatus;
  initialFormData?: CargoFormData;
  resetFormData?: CargoFormData | CargoFormDataBase;
  previousFormData?: CargoFormData | CargoFormDataBase;
  isSaving: boolean;
  loadedDataRevisionNumber: string | null;
}

const initialState: CargoDialogState = {
  status: "CLOSED",
  isSaving: false,
  loadedDataRevisionNumber: null,
};

export const cargoDeleted = createAction<{
  cargoId: string;
  routeId: string | null;
}>(`${prefix}/cargo-deleted`);

export const deleteCargo = createErrorReportingAsyncThunk(
  `${prefix}/delete`,
  async (cargoId: string, thunkApi) => {
    const routeId = selectCargoView(cargoId)(
      thunkApi.getState()
    )?.assignedRouteId;
    await axios.delete<CargoFormData>(`/api/cargos/${cargoId}`);
    thunkApi.dispatch({
      type: "DELETE_CARGO",
      payload: { cargoId, routeId },
    });
    thunkApi.dispatch(cargoDeleted({ cargoId, routeId }));
  }
);

export const loadCargo = createErrorReportingAsyncThunk(
  `${prefix}/load`,
  async (
    cargoId: string,
    thunkAPI
  ): Promise<{
    cargoId: string;
    data: CargoFormData & { revision: string };
  }> => {
    try {
      const data = await axios
        .get(`/api/cargos/${cargoId}`)
        .then(getData)
        .then(decodeCargo)
        .then(apiCargoToFormData);
      return { cargoId, data: data as any };
    } catch (e) {
      thunkAPI.dispatch(
        notifyL({
          namespace: "notifications",
          key: "loadingCargoFailed",
          type: "error",
        })
      );
      throw e;
    }
  }
);

export const loadCopyOfCargo = createErrorReportingAsyncThunk(
  `${prefix}/load-cargo-copy`,
  async (cargoId: string, thunkAPI) => {
    try {
      const initialFormData = await axios
        .get<unknown>(`/api/cargos/${cargoId}`)
        .then(getData)
        .then(tPromise.decode(editCargoOutputT))
        .then(apiCargoToFormData)
        .then((x) => omit(x, "id"))
        .then(createCargoCopy);
      const assignTeamId = thunkAPI.getState().auth.user?.defaultTeamId;
      const newShipmentDaysAdjustment =
        thunkAPI.getState().data.orgSettings.newShipmentDate;

      return {
        cargoId,
        initialFormData,
        resetFormData: defaultFormData({
          assignedTeamId: assignTeamId!,
          newShipmentDateAdjustment: newShipmentDaysAdjustment,
        }),
      };
    } catch (e) {
      thunkAPI.dispatch(
        notifyL({
          namespace: "notifications",
          key: "loadingCargoFailed",
          type: "error",
        })
      );
      throw e;
    }
  }
);

export const submit = createErrorReportingAsyncThunk(
  `${prefix}/submit`,
  async (
    formData: CargoData & {
      keepAlive?: boolean;
      routeId?: string;
      submitType: "CREATE" | "UPDATE" | "DUPLICATE" | "CREATE_AND_MAKE_COPY";
      deleteStopsWithEvents?: boolean;
    },
    thunkAPI
  ) => {
    const cargoDialogState = thunkAPI.getState().app.cargoDialog;
    const actualFormData = omit(formData, ["submitType", "keepAlive"]);
    // const cargoId = selectCargoId(thunkAPI.getState());
    const cargoId = formData.id;
    const routeId = formData.routeId;
    const body = {
      ...actualFormData,
      minTemperature: formData.minTemperature || null,
      maxTemperature: formData.maxTemperature || null,
      type: formData.type,
      clientId: formData.clientId || null,
      pickupList: formData.pickupList.map(formDataStopToApiStop),
      dropoffList: formData.dropoffList.map(formDataStopToApiStop),
    };
    let data;
    if (formData.submitType === "UPDATE") {
      try {
        let url = `/api/cargos/${cargoId}?revisionNumber=${cargoDialogState.loadedDataRevisionNumber}`;
        if (formData.deleteStopsWithEvents) {
          url += "&deleteStopsWithEvents=true";
        }
        const result = await axios
          .put(
            url,
            editCargoInputT.encode({
              ...body,
              ref: formData.ref!,
            })
          )
          .then(getData)
          .then(tPromise.decode(t.strict({ delivery: deliveryT })));
        thunkAPI.dispatch(deliveryUpdated(result.delivery));
        thunkAPI.dispatch(
          notifyL({
            namespace: "notifications",
            key: "shipmentSaved",
            type: "success",
          })
        );
      } catch (err: any) {
        if (
          err?.response?.status === 409 &&
          err?.response?.data?.message === ERR_CANNOT_DELETE_STOP_WITH_EVENTS
        ) {
          return {
            requestFormData: actualFormData,
            keepAlive: formData.keepAlive,
            submitType: formData.submitType,
            error: ERR_CANNOT_DELETE_STOP_WITH_EVENTS,
          };
        }
        if (
          err?.response?.status === 409 &&
          err?.response?.data?.message === ERR_OUTDATED_CARGO_REVISION
        ) {
          return {
            requestFormData: actualFormData,
            keepAlive: formData.keepAlive,
            submitType: formData.submitType,
            error: ERR_OUTDATED_CARGO_REVISION,
          };
        }
        throw err;
      }
    } else {
      data = await axios.post(
        "/api/cargos",
        postCargoT.encode({
          ...body,
          route_id: routeId || null,
        })
      );
      thunkAPI.dispatch(
        notifyL({
          namespace: "notifications",
          key:
            formData.submitType === "DUPLICATE"
              ? "shipmentSavedAndDuplicated"
              : "shipmentCreated",
          type: "success",
        })
      );
    }
    return {
      ...(data && {
        id: data?.data.id,
      }),
      requestFormData: actualFormData,
      keepAlive: formData.keepAlive,
      submitType: formData.submitType,
    };
  }
);

const createCargoCopy = (data: CargoFormData): CargoFormDataBase => ({
  ...omit(data, "ref"),
  pickupList: data.pickupList.map((stop) => ({
    ...stop,
    id: null,
    cargo_id: null,
  })),
  dropoffList: data.dropoffList.map((stop) => ({
    ...stop,
    id: null,
    cargo_id: null,
  })),
});

export const createNewCargo =
  () => (dispatch: AppDispatch, getState: GetState) => {
    const state = getState().app.cargoDialog;
    const assignTeamId = getState().auth.user?.defaultTeamId;
    const newShipmentDateAdjustment =
      getState().data.orgSettings.newShipmentDate;
    const defaultData = defaultFormData({
      assignedTeamId: assignTeamId!,
      newShipmentDateAdjustment,
    });
    const initialFormData = state.previousFormData || defaultData;
    const resetFormData = defaultData;
    return dispatch(
      cargoDialogSlice.actions.createNewCargo({
        initialFormData,
        resetFormData,
      })
    );
  };

const cargoDialogSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    createNewCargo: (
      state,
      action: PayloadAction<{
        initialFormData: CargoFormDataBase;
        resetFormData: CargoFormData | CargoFormDataBase;
      }>
    ) => {
      state.status = "CREATE";
      state.initialFormData = action.payload.initialFormData;
      state.previousFormData = undefined;
      state.resetFormData = action.payload.resetFormData;
    },
    close: (state, action: PayloadAction<CargoFormData | undefined>) => {
      state.initialFormData =
        state.status === "EDIT" ? undefined : state.initialFormData;
      state.previousFormData =
        state.status === "CREATE" ? action.payload : undefined;
      state.status = "CLOSED";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submit.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(submit.fulfilled, (state, { payload }) => {
        state.isSaving = false;
        if (payload.error) {
          // this means it is a caught error, the action result is handled where the action was dispatched
          return;
        }
        if (payload.submitType === "CREATE_AND_MAKE_COPY") {
          state.status = "CREATE";
          const copy = createCargoCopy(payload.requestFormData);
          state.previousFormData = omit(copy, "ref");
          state.initialFormData = omit(copy, "ref");
        } else {
          state.status = "CLOSED";
          state.previousFormData = undefined;
          state.initialFormData = undefined;
        }
      })
      .addCase(submit.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(loadCargo.fulfilled, (state, action) => {
        state.initialFormData = action.payload.data;
        state.resetFormData = action.payload.data;
        state.loadedDataRevisionNumber = action.payload.data.revision;
        state.status = "EDIT";
      })
      .addCase(loadCopyOfCargo.fulfilled, (state, action) => {
        state.initialFormData = action.payload.initialFormData;
        state.resetFormData = action.payload.resetFormData;
        state.status = "CREATE";
      })
      .addCase(deleteCargo.fulfilled, (state) => {
        state.status = "CLOSED";
        state.previousFormData = undefined;
        state.initialFormData = undefined;
        state.resetFormData = undefined;
      });
  },
});

export const { close } = cargoDialogSlice.actions;
export default cargoDialogSlice.reducer;
