import axios from "../../../../axios";
import { createSlice } from "@reduxjs/toolkit";
import * as tPromise from "io-ts-promise";
import * as types from "./types";
import keyBy from "lodash/keyBy";
import { createErrorReportingAsyncThunk } from "../../../helpers";

const prefix = "data/economics/invoices";

export interface Invoice {
  id: string;
  clientId: string;
  total: string;
  sentToEconomic: boolean;
}

interface State {
  entities: { [key: string]: Invoice };
  ids: string[];
}

export const getInvoices = createErrorReportingAsyncThunk(
  `${prefix}/getInvoices`,
  async () => {
    const { data } = await axios.get("/api/e-conomic/invoices");
    return tPromise.decode(types.invoicesT)(data);
  }
);

export const resendInvoice = createErrorReportingAsyncThunk(
  `${prefix}/resendInvoice`,
  async (id: string, thunkAPI) => {
    await axios.post(`/api/e-conomic/invoices/${id}/resend`);
    await thunkAPI.dispatch(getInvoices());
  }
);

const initialState: State = {
  entities: {},
  ids: [],
};

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInvoices.fulfilled, (_state, action) => {
      return {
        entities: keyBy(action.payload, "id"),
        ids: action.payload.map((x) => x.id),
      };
    });
  },
});

export default slice.reducer;
