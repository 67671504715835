const OnboardingVideosPage = () => {
  return (
    <>
      <iframe
        src="https://doranordicaps.uqualio.com/site/17ba8870f4bd4cbeb70484c1bd926588"
        title="Online disponering, ruteplanlægning og fakturering"
        height="100%"
        width="100%"
        allow="autoplay; fullscreen"
      ></iframe>
    </>
  );
};

export default OnboardingVideosPage;
