import DocumentsList from "../components/DocumentsList";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useSelector } from "../../../../redux-store";
import GenericPromptDialog from "../../GenericPromptDialog/GenericPromptDialog";
import { useTranslation } from "react-i18next";
import { selectStopDocuments } from "../../../../ducks/data/stop-documents/selectors";
import { loadDocuments } from "../../../../ducks/data/stop-documents";
import axios from "../../../../axios";
import { notify } from "../../../../ducks/notifications";

type DocumentsListProps = {
  stopId: string;
  controlsDisabled: boolean;
};

const StopDocumentsList = (props: DocumentsListProps) => {
  const { stopId, controlsDisabled } = props;
  const { t } = useTranslation(["shipmentModal", "components"]);
  const [documentIdToDelete, setDocumentIdToDelete] = useState<string | null>(
    null
  );
  const documents = useSelector(selectStopDocuments(stopId));
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (stopId) {
      dispatch(loadDocuments(stopId));
    }
  }, [dispatch, stopId]);

  const removeDocument = async () => {
    await axios
      .delete(`/api/stops/${stopId}/documents/${documentIdToDelete}`)
      .then(() => {
        setDocumentIdToDelete(null);
        dispatch(loadDocuments(stopId));
        dispatch(
          notify({
            message: t(
              "shipmentModal:cards.documents.notificationRemoveSuccess"
            ),
            type: "success",
          })
        );
      })
      .catch((err) => {
        console.error(err);
        dispatch(
          notify({
            message: t("shipmentModal:cards.documents.notificationRemoveError"),
            type: "error",
          })
        );
      });
  };

  return (
    <>
      {documentIdToDelete && (
        <GenericPromptDialog
          title={t("shipmentModal:cards.documents.confirmRemoveDocumentTitle")}
          text={t("shipmentModal:cards.documents.confirmRemoveDocumentMessage")}
          continueButtonColor={"error"}
          continueText={t("components:buttonLabels.remove")}
          onCancelClick={() => setDocumentIdToDelete(null)}
          onContinueClick={() => removeDocument()}
        />
      )}
      <DocumentsList
        className="mt-2"
        documents={documents}
        onRemoveItem={setDocumentIdToDelete}
        controlsDisabled={controlsDisabled}
      />
    </>
  );
};

export default StopDocumentsList;
