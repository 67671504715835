import "./ShipmentDrivingReport.scss";
import React, { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useSelector } from "../../../../redux-store";
import { loadCargosReport } from "../../../../ducks/data/cargos-report";
import * as selectors from "../../../../ducks/data/cargos-report/selectors";
import { useL10n } from "../../../../l10n";
import { DateTime } from "luxon";
import { selectTrailer } from "../../../../ducks/data/trailers/selectors";
import { selectDriver } from "../../../../ducks/data/drivers/selectors";
import { useTranslation } from "react-i18next";
import { ShipmentDriverReportColumn } from "dora-contracts";
import axios from "../../../../axios";

type ShipmentDrivingReportProps = {
  cargoId: string;
  clientId: string | null;
};

const ShipmentDrivingReport = (input: ShipmentDrivingReportProps) => {
  const { cargoId, clientId } = input;
  const dispatch = useAppDispatch();
  const l10n = useL10n();
  const { t } = useTranslation("cargosReport");
  const [columns, setColumns] = useState<ShipmentDriverReportColumn[] | null>(
    null
  );

  useEffect(() => {
    const url = `/api/shipment-driver-report-template${clientId ? `?clientId=${clientId}` : ""}`;
    axios.get(url).then((response) => {
      setColumns(response.data.data.columns);
    });
  }, [clientId]);

  useEffect(() => {
    dispatch(
      loadCargosReport({
        from: null,
        to: null,
        customerId: null,
        cargoId,
      })
    );
  }, [dispatch, cargoId]);

  const data = useSelector(selectors.selectCargosReportRows);

  const columnsAndTranslations = useMemo(() => {
    return {
      date: t("table.rowDate"),
      arrived: t("table.rowArrival"),
      departed: t("table.rowDeparture"),
      address: t("table.rowAddress"),
      note: t("table.rowNote"),
      weight: t("table.rowWeight"),
      driver: t("table.rowDriver"),
      truck: t("table.rowTruck"),
      volume: t("table.rowVolume"),
    };
  }, [t]);

  // TODO: this filtering should happen on the server when we generate the data, for now its simpler to do it here
  const dataToRender = useMemo(
    () =>
      data.filter((x) => {
        const entryColumns: { [key: string]: string | null } = {};
        columns?.forEach((column) => {
          switch (column) {
            case "driver":
              entryColumns.driver = x.driverId;
              break;
            case "truck":
              entryColumns.truck = x.trailerId;
              break;
            case "arrived":
              entryColumns.arrived = x.arrivalDate;
              break;
            case "departed":
              entryColumns.departed = x.departureDate;
              break;
            case "volume":
              entryColumns.volume = x.cubicMeters;
              break;
            default:
              entryColumns[column] = x[column];
              break;
          }
        });
        if (Object.values(entryColumns).some((v) => v)) {
          return true;
        }
      }),
    [data, columns]
  );

  if (!columns) {
    return null;
  }

  return (
    <>
      <div>{t("headingSingular")}</div>
      {dataToRender.length ? (
        <div className="shipment-driving-report-table">
          <table className="w-full">
            <thead>
              <tr>
                {columns.map((column) => (
                  <th key={column}>{columnsAndTranslations[column]}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataToRender.map((x, i) => (
                <tr key={i}>
                  {columns.map((column) => {
                    switch (column) {
                      case "date":
                        return (
                          <td key={column}>
                            {x.date ? l10n.formatDate(x.date) : ""}
                          </td>
                        );
                      case "arrived":
                        return (
                          <td key={column}>
                            {x.arrivalDate
                              ? DateTime.fromISO(x.arrivalDate).toFormat(
                                  "HH:mm"
                                )
                              : ""}
                          </td>
                        );
                      case "departed":
                        return (
                          <td key={column}>
                            {x.departureDate
                              ? DateTime.fromISO(x.departureDate).toFormat(
                                  "HH:mm"
                                )
                              : ""}
                          </td>
                        );
                      case "address":
                        return <td key={column}>{x.address}</td>;
                      case "note":
                        return <td key={column}>{x.note}</td>;
                      case "weight":
                        return <td key={column}>{x.weight}</td>;
                      case "volume":
                        return <td key={column}>{x.cubicMeters}</td>;
                      case "driver":
                        return (
                          <td key={column}>
                            {x.driverId ? <Driver driverId={x.driverId} /> : ""}
                          </td>
                        );
                      case "truck":
                        return (
                          <td key={column}>
                            {x.trailerId ? (
                              <Trailer trailerId={x.trailerId} />
                            ) : (
                              ""
                            )}
                          </td>
                        );
                      default:
                        return "";
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="mt-4 mb-7 text-gray-500">{t("noData")}</div>
      )}
    </>
  );
};

// TODO: move those helpers somewhere else
const Trailer = (props: { trailerId: string }) => {
  const trailer = useSelector(selectTrailer(props.trailerId));
  return <>{trailer?.number}</>;
};

const Driver = (props: { driverId: string }) => {
  const driver = useSelector(selectDriver(props.driverId));
  return <>{driver ? `${driver.firstName} ${driver.lastName}` : null}</>;
};

export default ShipmentDrivingReport;
