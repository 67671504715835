import * as i18n from "../i18n/i18n";
import { getPlatform } from "./here-maps";
import { useFeature } from "../hooks";
import { useCallback } from "react";

export const useCreateMap = (enableTraffic: boolean) => {
  const showTrafficIncidents = useFeature("map-show-traffic");
  const language = (() => {
    switch (i18n.getLanguage()) {
      case "de":
        return "de-DE";
      case "da":
      case "se":
        // Danish and Swedish not supported by default currently
        return "en-US";
      default:
        return "en-US";
    }
  })();
  return useCallback(
    (mapDiv: HTMLDivElement) => {
      const defaultLayers = getPlatform().createDefaultLayers();
      const map = new H.Map(mapDiv!, defaultLayers.vector.normal.map, {
        center: { lng: 13.4, lat: 52.51 },
        zoom: 5,
        padding: { top: 50, left: 50, bottom: 50, right: 50 },
      });
      if (enableTraffic && showTrafficIncidents) {
        map.addLayer(defaultLayers.vector.normal.traffic);
        map.addLayer(defaultLayers.vector.normal.trafficincidents);
      }
      const mapUi = H.ui.UI.createDefault(map, defaultLayers, language);

      return { map, defaultLayers, mapUi };
    },
    [showTrafficIncidents, language]
  );
};
