import { RootState } from "../../../redux-store";

export const selectWebBookers = (state: RootState) =>
  state.data.webBookers.webBookers;

export const selectWebBookersForClient =
  (clientId: string) => (state: RootState) =>
    state.data.webBookers.webBookers.filter((x) => x.clientId === clientId);

export const selectWebBooker = (id: string) => (state: RootState) =>
  state.data.webBookers.webBookers.find((x) => x.id === id);
