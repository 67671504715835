import axios, { getData } from "../../../axios";
import { createSlice } from "@reduxjs/toolkit";
import { Team, teamInputT, teamsT, teamT } from "dora-contracts";
import { createErrorReportingAsyncThunk } from "../../helpers";
import * as t from "io-ts";
import * as tPromise from "io-ts-promise";

type TeamInput = t.TypeOf<typeof teamInputT>;

type State = {
  teams: Team[];
};

const initialState: State = { teams: [] };

const prefix = "data/teams";

export const addTeam = createErrorReportingAsyncThunk(
  `${prefix}/add-team`,
  async (team: TeamInput): Promise<Team> => {
    return axios
      .post("/api/teams", team)
      .then(getData)
      .then(tPromise.decode(teamT));
  }
);

export const loadTeams = createErrorReportingAsyncThunk(
  `${prefix}/load-teams`,
  async (): Promise<Team[]> => {
    return await axios
      .get("/api/teams")
      .then(getData)
      .then(tPromise.decode(teamsT));
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addTeam.fulfilled, (state, action) => {
        state.teams.push(action.payload);
      })
      .addCase(loadTeams.fulfilled, (state, action) => {
        state.teams = action.payload;
      });
  },
});

export default slice.reducer;
