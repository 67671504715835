import axios, { getData } from "../../../axios";
import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";
import {
  decodeCargoTemplate,
  editCargoTemplateOutputT,
  editCargoTemplateInputT,
  postCargoTemplateT,
  CargoTemplateData,
} from "./types";
import { omit } from "lodash";
import {
  apiCargoTemplateToFormData,
  defaultFormData,
  formDataStopToApiStop,
} from "./helpers";
import { notifyL } from "../../notifications";
import * as t from "io-ts";
import { AppDispatch, GetState } from "../../../redux-store";
import { createErrorReportingAsyncThunk } from "../../helpers";
import { loadSchedule } from "../../data/schedules";
import { CargoFormData } from "../cargo-dialog/types";

export type CargoTemplateFromApi = t.TypeOf<typeof editCargoTemplateOutputT>;

const prefix = "app/cargo-template-dialog";

type CargoDialogStatus = "CREATE" | "EDIT" | "CLOSED";

interface CargoDialogState {
  status: CargoDialogStatus;
  initialFormData?: CargoFormData;
  resetFormData?: CargoFormData;
  previousFormData?: CargoFormData;
  isSaving: boolean;
}

const initialState: CargoDialogState = {
  status: "CLOSED",
  isSaving: false,
};

export const cargoTemplateDeleted = createAction<{
  cargoTemplateId: string;
}>(`${prefix}/cargo-template-deleted`);

export const deleteCargoTemplate = createErrorReportingAsyncThunk(
  `${prefix}/delete`,
  async (
    data: {
      cargoTemplateId: string;
      scheduleId: string;
    },
    thunkApi
  ) => {
    const { cargoTemplateId, scheduleId } = data;
    await axios.delete<CargoFormData>(
      `/api/cargo-templates/${cargoTemplateId}?scheduleId=${scheduleId}`
    );
    thunkApi.dispatch({
      type: "DELETE_CARGO_TEMPLATE",
      payload: { cargoTemplateId },
    });
    thunkApi.dispatch(cargoTemplateDeleted({ cargoTemplateId }));
    thunkApi.dispatch(loadSchedule(scheduleId));
  }
);

export const loadCargoTemplate = createErrorReportingAsyncThunk(
  `${prefix}/load`,
  async (
    cargoTemplateId: string,
    thunkAPI
  ): Promise<{ cargoTemplateId: string; data: CargoFormData }> => {
    try {
      const data = await axios
        .get(`/api/cargo-templates/${cargoTemplateId}`)
        .then(getData)
        .then(decodeCargoTemplate)
        .then(apiCargoTemplateToFormData);
      return { cargoTemplateId, data };
    } catch (e) {
      thunkAPI.dispatch(
        notifyL({
          namespace: "notifications",
          key: "loadingCargoTemplateFailed",
          type: "error",
        })
      );
      throw e;
    }
  }
);

export const submit = createErrorReportingAsyncThunk(
  `${prefix}/submit`,
  async (
    formData: CargoTemplateData & {
      keepAlive?: boolean;
      routeId?: string;
      scheduleId: string;
      routeTemplateId: string | null;
      submitType: "CREATE" | "UPDATE" | "DUPLICATE" | "CREATE_AND_MAKE_COPY";
    },
    thunkAPI
  ) => {
    const actualFormData = omit(formData, ["submitType", "keepAlive"]);
    // const cargoId = selectCargoId(thunkAPI.getState());
    const cargoTemplateId = formData.id;
    const scheduleId = formData.scheduleId;
    // const routeId = formData.routeId;
    const body = {
      ...actualFormData,
      minTemperature: formData.minTemperature || null,
      maxTemperature: formData.maxTemperature || null,
      type: formData.type,
      clientId: formData.clientId || null,
      pickupList: formData.pickupList.map(formDataStopToApiStop),
      dropoffList: formData.dropoffList.map(formDataStopToApiStop),
    };
    let data;
    if (cargoTemplateId) {
      await axios
        .put(
          `/api/cargo-templates/${cargoTemplateId}`,
          editCargoTemplateInputT.encode(body)
        )
        .then(getData);
      thunkAPI.dispatch(
        notifyL({
          namespace: "notifications",
          key: "shipmentTemplateSaved",
          type: "success",
        })
      );
    } else {
      if (formData.routeTemplateId) {
        data = await axios.post(
          `/api/schedules/${scheduleId}/route-templates/${formData.routeTemplateId}/cargo-templates`,
          postCargoTemplateT.encode({
            ...body,
          })
        );
      } else {
        data = await axios.post(
          `/api/cargo-templates?scheduleId=${scheduleId}`,
          postCargoTemplateT.encode({
            ...body,
          })
        );
      }
      thunkAPI.dispatch(
        notifyL({
          namespace: "notifications",
          key: "shipmentTemplateCreated",
          type: "success",
        })
      );
    }
    return {
      ...(data && {
        id: data?.data.id,
      }),
      requestFormData: actualFormData,
      keepAlive: formData.keepAlive,
      submitType: formData.submitType,
    };
  }
);

export const createNewCargoTemplate =
  () => (dispatch: AppDispatch, getState: GetState) => {
    const state = getState().app.cargoTemplateDialog;
    const assignTeamId = getState().auth.user?.defaultTeamId;
    const newShipmentDateAdjustment =
      getState().data.orgSettings.newShipmentDate;
    const defaultData = defaultFormData({
      assignedTeamId: assignTeamId!,
      newShipmentDateAdjustment,
    });
    const initialFormData = state.previousFormData || defaultData;
    const resetFormData = defaultData;
    return dispatch(
      cargoTemplateDialogSlice.actions.createNewCargoTemplate({
        initialFormData,
        resetFormData,
      })
    );
  };

const cargoTemplateDialogSlice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    createNewCargoTemplate: (
      state,
      action: PayloadAction<{
        initialFormData: CargoFormData;
        resetFormData: CargoFormData;
      }>
    ) => {
      state.status = "CREATE";
      state.initialFormData = action.payload.initialFormData;
      state.previousFormData = undefined;
      state.resetFormData = action.payload.resetFormData;
    },
    close: (
      state,
      action: PayloadAction<CargoFormData | undefined>
    ) => {
      state.initialFormData =
        state.status === "EDIT" ? undefined : state.initialFormData;
      state.previousFormData =
        state.status === "CREATE" ? action.payload : undefined;
      state.status = "CLOSED";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(submit.pending, (state) => {
        state.isSaving = true;
      })
      .addCase(submit.fulfilled, (state, { payload }) => {
        state.isSaving = false;
        state.status = "CLOSED";
        state.previousFormData = undefined;
        state.initialFormData = undefined;
      })
      .addCase(submit.rejected, (state) => {
        state.isSaving = false;
      })
      .addCase(loadCargoTemplate.fulfilled, (state, action) => {
        state.initialFormData = action.payload.data;
        state.resetFormData = action.payload.data;
        state.status = "EDIT";
      })
      .addCase(deleteCargoTemplate.fulfilled, (state) => {
        state.status = "CLOSED";
        state.initialFormData = undefined;
        state.previousFormData = undefined;
        state.resetFormData = undefined;
      });
  },
});

export const { close } = cargoTemplateDialogSlice.actions;
export default cargoTemplateDialogSlice.reducer;
