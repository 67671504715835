export const pickupSort = "Læssedato";
export const dropoffSort = "Lossedato";
export const ldmSort = "Ladmeter";
export const createdAtSort = "Tilføjet";
export const pickupPostCodeSort = "PickupPostcode";
export const dropoffPostCodeSort = "DropoffPostcode";
export const clientSort = "Client";

export type SortOrder = "ASC" | "DESC";

export type SortProperty =
  | typeof pickupSort
  | typeof dropoffSort
  | typeof ldmSort
  | typeof createdAtSort
  | typeof pickupPostCodeSort
  | typeof dropoffPostCodeSort
  | typeof clientSort;

export type FilterType = "cargos" | "routes";
