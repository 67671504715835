import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createErrorReportingAsyncThunk } from "../../helpers";

const prefix = "data/web-bookers";

export type WebBooker = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  clientId: string;
};

type State = {
  webBookers: WebBooker[];
};

export const createWebBooker = createErrorReportingAsyncThunk(
  `${prefix}/create`,
  async (input: {
    clientId: string;
    firstName: string;
    lastName: string;
    email: string;
  }) => {
    const result = await fetch("/api/web-bookers", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(input),
    });
    if (!result.ok) {
      throw new Error("Error posting");
    }
    return await result.json();
  }
);

export const getAll = createAsyncThunk(`${prefix}/get-all`, async () => {
  const result = await fetch("/api/web-bookers");
  if (!result.ok) {
    throw new Error("Bad result");
  }
  const data = await result.json();
  return data;
});

const initialState: State = {
  webBookers: [],
};

const slice = createSlice({
  initialState,
  name: prefix,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createWebBooker.fulfilled, (state, action) => {
        state.webBookers.push(action.payload);
      })
      .addCase(getAll.fulfilled, (state, action) => {
        state.webBookers = action.payload;
      });
  },
});

export default slice.reducer;
