import DoraButton from "../../../Toolkit/DoraButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileInvoice,
  faLock,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import * as actions from "../../../../ducks/data/deliveries";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import {
  DataGrid,
  GridColDef,
  GridRowParams,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useAppDispatch, useSelector } from "../../../../redux-store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { loadExpenseAccounts } from "../../../../ducks/data/expenseAccounts";
import {
  selectExpenses,
  selectInvoicable,
} from "../../../../ducks/data/deliveries/selectors";
import { selectEntities } from "../../../../ducks/data/expenseAccounts/selectors";
import { useTranslation } from "react-i18next";
import { useL10n } from "../../../../l10n";
import { Expense } from "../../../../ducks/data/deliveries/types";
import { Account } from "../../../../ducks/data/expenseAccounts/types";
import Decimal from "decimal.js-light";
import { formatBasicStringDate } from "../../../../helpers/date-helper";
import { sendExpenses } from "../../../../ducks/data/deliveries";
import EditExpenseLineModal from "./EditExpenseLineModal";

type ExpensesProps = {
  deliveryId: string;
};

const ExpensesNew = (props: ExpensesProps) => {
  const { deliveryId } = props;
  const [editExpenseId, setEditExpenseId] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadExpenseAccounts());
  }, [dispatch]);
  const expenseAccountEntities = useSelector(selectEntities);
  const expenses = useSelector(selectExpenses(deliveryId));
  const initialLineIds = useMemo(
    () => expenses.filter((x) => !x.locked).map((x) => x.id),
    [expenses]
  );
  const invoicable = useSelector(selectInvoicable(deliveryId));
  const [selection, setSelection] =
    useState<GridRowSelectionModel>(initialLineIds);

  const onSendExpenses = useCallback(() => {
    if (selection.some((x) => typeof x !== "string")) {
      throw new Error("Contains wrong data");
    }
    dispatch(sendExpenses({ deliveryId, expenseIds: selection as string[] }));
    setSelection([]);
    // TODO: only deselect on success. show toast if success
  }, [dispatch, selection, deliveryId]);
  const { t } = useTranslation(["expenses", "shipmentModal"]);

  const formatAccount = (a: Account) =>
    (a && `${a.accountNumber} - ${a.name}`) || "";

  const l10n = useL10n();

  const columns: GridColDef<Expense>[] = [
    {
      field: "accountId",
      headerName: `${t("expenses:expenseLine.account")}`,
      headerClassName: "invoice-data-grid__col",
      valueFormatter: (e) =>
        formatAccount(expenseAccountEntities[e.value as string]),
      flex: 2,
    },
    {
      field: "date",
      type: "date",
      headerClassName: "invoice-data-grid__col",
      headerAlign: "right",
      align: "right",
      headerName: `${t("expenses:expenseLine.date")}`,
      editable: false,
      valueFormatter: (x) => formatBasicStringDate(x.value as string),
    },
    {
      field: "amount",
      type: "number",
      headerClassName: "invoice-data-grid__col",
      headerName: `${t("expenses:expenseLine.amount")}`,
      flex: 1,
      editable: false,
      valueGetter: ({ row: { amount } }) =>
        l10n.formatCurrency(new Decimal(amount)),
    },
    {
      field: "currency",
      type: "string",
      headerClassName: "invoice-data-grid__col",
      headerName: `${t("expenses:expenseLine.currency")}`,
      flex: 1,
      editable: false,
      valueGetter: ({ row: { currency } }) => currency,
    },
    {
      field: "locked",
      headerClassName: "invoice-data-grid__col",
      headerName: "",
      align: "right",
      width: 50,
      maxWidth: 50,
      flex: 1,
      renderCell: (params) =>
        params.value ? (
          <FontAwesomeIcon style={{ color: "var(--gray-400)" }} icon={faLock} />
        ) : (
          <DoraButton
            variant="ghost"
            style={{ paddingRight: 0, color: "var(--gray-400)" }}
            onClick={() =>
              dispatch(
                actions.deleteExpense({
                  deliveryId,
                  expenseId: params.id as string,
                })
              )
            }
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </DoraButton>
        ),
    },
  ];

  const onAddExpense = () => setEditExpenseId("new");

  return (
    <>
      {selection.length ? (
        <div className="shipment-dialog-finances__multiple-selected">
          <div>
            {t("shipmentModal:cards.finances.selected", {
              count: selection.length,
            })}
          </div>
          <div className="shipment-dialog-finances__multiple-selected-separator"></div>
          <DoraButton variant="primary" onClick={onSendExpenses}>
            <div className="flex items-center gap-2">
              <FontAwesomeIcon icon={faFileInvoice} />
              <div> {t("shipmentModal:cards.finances.sendSelected")}</div>
            </div>
          </DoraButton>
          <div className="flex-grow"></div>
          <DoraButton
            variant="ghost"
            className="shipment-dialog-finances__multiple-selected-close"
            onClick={() => setSelection([])}
          >
            <FontAwesomeIcon icon={faTimes} />
          </DoraButton>
        </div>
      ) : null}
      <DataGrid
        sx={{
          border: "none",
          backgroundColor: "neutral",
        }}
        autoHeight
        rows={expenses}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        isRowSelectable={(params: GridRowParams) => !params.row.locked}
        onRowSelectionModelChange={setSelection}
        rowSelectionModel={selection}
        slots={{
          footer: Footer,
          baseCheckbox: (props) => <input {...props} type="checkbox" />,
        }}
        slotProps={{
          footer: {
            selection,
            onSendExpenses,
            invoicable,
            onAddExpense,
          } as any,
        }}
      />
      {editExpenseId && (
        <EditExpenseLineModal
          id={{ deliveryId, expenseId: editExpenseId }}
          onClose={() => setEditExpenseId(null)}
        />
      )}
    </>
  );
};

const Footer = (props: { onAddExpense: () => void }) => {
  const { onAddExpense } = props;
  const { t } = useTranslation("expenses");
  return (
    <Box
      sx={{
        padding: "16px 0",
        display: "flex",
      }}
    >
      <DoraButton variant="primary" onClick={onAddExpense}>
        {t("expenseLine.newExpense")}
      </DoraButton>
    </Box>
  );
};

export default ExpensesNew;
