import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../../redux-store";
import keyBy from "lodash/keyBy";
import { FinanceUnit } from "./types";
import { Dictionary } from "lodash";

export const selectUnits = (state: RootState) => state.data.finance.units;

const selectUnitMap = createSelector(
  selectUnits,
  (units): Dictionary<FinanceUnit> => {
    if (!units) {
      return {};
    }
    return keyBy(units, "id");
  }
);

export const selectUnit = (id: string) => (state: RootState) => {
  const map = selectUnitMap(state);
  return map[id] || null;
};
