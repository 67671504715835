import { useState } from "react";
import {
  // Divider,
  Menu,
  IconButton,
  ListItemText,
  // ListItemIcon,
  Stack,
  MenuItem,
} from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { MuiTooltip } from "./nav-styles";
import { useTranslation } from "react-i18next";
import NoNotifications from "./NoNotifications.svg";

export default function Notifications() {
  const { t } = useTranslation("navigation");
  const [anchorElNotifications, setAnchorElNotifications] =
    useState<null | HTMLElement>(null);

  const handleOpenNotificationsMenu = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElNotifications(event.currentTarget);
  };
  const handleCloseNotificationsMenu = () => {
    setAnchorElNotifications(null);
  };

  return (
    <>
      <MuiTooltip title={t("topbar.notifications")} placement="bottom">
        <IconButton
          color="inherit"
          size="large"
          onClick={handleOpenNotificationsMenu}
        >
          <NotificationsIcon />
        </IconButton>
      </MuiTooltip>
      <Menu
        anchorEl={anchorElNotifications}
        transformOrigin={{
          horizontal: "center",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        keepMounted
        open={Boolean(anchorElNotifications)}
        onClose={handleCloseNotificationsMenu}
        sx={{ cursor: "pointer" }}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 300,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 145,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        <MenuItem disabled>
          {/* <ListItemIcon>
                        <NotificationsIcon fontSize="small" />
                    </ListItemIcon> */}
          <Stack
            width="100%"
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <img
              src={NoNotifications}
              alt=""
              style={{ width: 100, padding: 20 }}
            />
            <ListItemText>{t("topbar.noNewNotifications")}</ListItemText>
          </Stack>
        </MenuItem>
        {/* <Divider />
                <MenuItem>
                    <ListItemIcon>
                        <NotificationsIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Notifikation 2</ListItemText>
                </MenuItem>
                <Divider /> */}
      </Menu>
    </>
  );
}
