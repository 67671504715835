import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../redux-store";

export const selectBC = (state: RootState) => state.app.businessCentral;

export const selectLoaded = (state: RootState) => {
  return !!state.app.businessCentral.configuration;
};

export const selectAccessTokenStatus = (state: RootState) => {
  return state.app.businessCentral.configuration?.accessTokenStatus;
};

export const selectEnvironments = createSelector(selectBC, (bc) => {
  const environmentName = bc.configuration?.environmentName || "";
  const environments = bc.environments;
  if (
    !bc.configuration ||
    bc.configuration.accessTokenStatus !== "INITIALIZED"
  ) {
    return {
      status: "NOT_CONFIGURED" as const,
    };
  }
  return environments
    ? {
        status: "LOADED" as const,
        environmentName,
        environments,
      }
    : { status: "LOADING" as const };
});

export const selectCompanies = createSelector(selectBC, (bc) => {
  const companyId = bc.configuration?.companyId || "";
  const companies = bc.companies;
  if (
    !bc.configuration?.environmentName ||
    bc.configuration?.accessTokenStatus !== "INITIALIZED"
  ) {
    return { status: "NOT_CONFIGURED" as const };
  }
  return companies
    ? {
        status: "LOADED" as const,
        companyId,
        companies,
      }
    : { status: "LOADING" as const };
});

export const selectShowWarningForDifferentTenant = (state: RootState) =>
  !!selectBC(state)?.unconfirmedAccessToken;
