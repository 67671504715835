import axios, { AxiosResponse } from "axios";

var instance = axios.create({
  xsrfCookieName: "mytoken",
  xsrfHeaderName: "csrf-token",
});

export default instance;
export const isAxiosError = axios.isAxiosError;
export const getData = <T extends AxiosResponse<any, any>>(response: T) =>
  response.data;
