import axios, { getData } from "../axios";
import { useState, useEffect, useContext } from "react";
import { Container, Typography } from "@mui/material";
import { SocketContext } from "../SocketConnection";

type State = {
  status: "LOADING" | "LOADED" | "ERROR";
  systemStatus?: Record<string, any>;
};

const PingPage = () => {
  const [state, setState] = useState<State>({ status: "LOADING" });
  const [pong, setPong] = useState(false);
  const socket = useContext(SocketContext);
  useEffect(() => {
    if (!socket) {
      return;
    }
    const handler = () => {
      setPong(true);
    };
    socket.on("pong", handler);
    const interval = setInterval(() => {
      axios.get(`/api/ping/socket`);
    }, 1000);

    return () => {
      clearInterval(interval);
      socket.removeListener("pong", handler);
    };
  }, [socket]);

  useEffect(() => {
    axios
      .get("/api/ping")
      .then(getData)
      .then(
        (data) => setState({ status: "LOADED", systemStatus: data }),
        (_e) => setState({ status: "ERROR" })
      );
  }, []);
  return (
    <Container>
      <Typography variant="h1">System Status</Typography>
      <div id="status">{state.status}</div>
      {state.status === "LOADED" && (
        <div>
          <span id="database-status-label">DB Status: </span>
          <span id="database-status">{state.systemStatus?.dbStatus}</span>
        </div>
      )}
      <div>
        <span id="socket-status-label">Socket:</span>{" "}
        <span id="socket-status">{pong && "OK"}</span>
      </div>
      <div>
        <span id="api-status-label">PDF Fonts Status:</span>{" "}
        <span id="api-status">{state.systemStatus?.fontsStatus}</span>
      </div>
    </Container>
  );
};

export default PingPage;
