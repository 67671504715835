import { useEffect } from "react";
import { useSelector } from "react-redux";
import { loadTeams } from ".";
import { useAppDispatch } from "../../../redux-store";
import { selectTeams } from "./selectors";
import * as authSelectors from "../../auth/selectors";

export const useTeams = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadTeams());
  }, [dispatch]);
  return useSelector(selectTeams);
};

export const useMyTeams = () => {
  const myTeams = useSelector(authSelectors.selectMyTeams);
  const teams = useTeams();
  return teams.filter((team) => myTeams.includes(team.id));
};
