import * as t from "io-ts";
import * as tPromise from "io-ts-promise";
import { createCargoTypes } from "dora-contracts";
import { CargoFormDataBase } from "../cargo-and-template-shared";

export const {
  editCargoInputT,
  editCargoOutputT,
  editStopOutputT,
  stopT,
  postCargoT,
} = createCargoTypes({
  decimal: t.string,
  date: t.string,
});

export const cargosT = t.array(editCargoOutputT);
export const decodeCargo = tPromise.decode(editCargoOutputT);
export type StopApi = t.TypeOf<typeof stopT>;
export type Stop2 = t.TypeOf<typeof editStopOutputT>;

export interface CargoFormData extends CargoFormDataBase {
  ref?: string;
}

export interface CargoData extends CargoFormData {
  id?: string; // cargo id
  userId?: string | null; // created by dispatcher id
  deliveryId?: string;
  canBeDeleted?: boolean;
  route_id?: string;
}
