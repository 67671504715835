import { createSlice, PayloadAction, createAction } from "@reduxjs/toolkit";
import { RootState } from "../../redux-store";
import axios, { getData } from "../../axios";
import * as types from "./types";
import * as cargoActions from "../cargo";
import * as tPromise from "io-ts-promise";
import keyBy from "lodash/keyBy";
import sortBy from "lodash/sortBy";
import { cargoDeleted } from "../app/cargo-dialog";
import {
  routeT,
  archivedRoutesResponseT,
  routeInfoStopsT,
  updateRouteTrailerRequestT,
} from "dora-contracts";
import { notifyL } from "../notifications";
import { createErrorReportingAsyncThunk, unwrap } from "../helpers";
import pickBy from "lodash/pickBy";
import { RouteInfoStop, WaypointData } from "../app/route-info/stops/types";
import * as cargoViewActions from "../data/cargo-views";
import { loadRouteViewModels } from "../data/route-views";
import * as dispatchingActions from "../app/dispatching";
import { SortOrder } from "./types";

const prefix = "routes";

export const getRoutes = createErrorReportingAsyncThunk(
  `${prefix}/getRoutes`,
  async () =>
    axios
      .get("/api/legacy/routes")
      .then(getData)
      .then(tPromise.decode(types.routesT))
);

export const getAllRoutes = createErrorReportingAsyncThunk(
  `${prefix}/getAllRoutes`,
  async (_, { dispatch }) => {
    await Promise.all([
      dispatch(loadRouteViewModels()).unwrap(),
      dispatch(cargoViewActions.loadActiveCargo()).unwrap(),
      dispatch(getRoutes()).unwrap(),
    ]);
  }
);

export const routeUpdated = createAction<types.RouteT>(
  `${prefix}/route-updated`
);

export const stopOrderUpdated = createAction(
  `${prefix}/stop-order-updated`,
  (input: { routeId: string; stops: RouteInfoStop[] }) => {
    return { payload: input };
  }
);

export const addDriver = createErrorReportingAsyncThunk(
  "route/addDriver",
  async (input: { routeId: string; driverId: string }, { dispatch }) => {
    const { routeId, driverId } = input;
    try {
      await axios.put(`/api/routes/${routeId}/drivers/${driverId}`);
    } catch (e) {
      dispatch(
        notifyL({
          namespace: "notifications",
          key: "addDriverToRouteFailed",
          type: "error",
        })
      );
      throw e;
    }
  },
  { skipNotification: true } as any
);

export const removeDriver = createErrorReportingAsyncThunk(
  "route/removeDriver",
  async (input: { routeId: string; driverId: string }, { dispatch }) => {
    const { routeId, driverId } = input;
    try {
      await axios.delete(`/api/routes/${routeId}/drivers/${driverId}`);
    } catch (e) {
      dispatch(
        notifyL({
          namespace: "notifications",
          key: "removeDriverFromRouteFailed",
          type: "error",
        })
      );
      throw e;
    }
  },
  { skipNotification: true } as any
);

export const getRouteStops = createErrorReportingAsyncThunk(
  `${prefix}/getStops`,
  async (routeId: string) => {
    const data = await axios
      .get(`/api/routes/${routeId}/stops`)
      .then(getData)
      .then(tPromise.decode(routeInfoStopsT));
    return data.map((x) => ({ ...x, stopId: (x as any).stopId || x.id }));
  }
);

export const getAllArchivedRoutes = createErrorReportingAsyncThunk(
  `${prefix}/getArchivedRoutesBySearch`,
  async (searchInputs: types.SearchInputs) => {
    const result = await axios.get("/api/routes/archived", {
      params: pickBy(searchInputs, (x) => x),
    });
    const { routes, cargoViews, routeViews } = await tPromise.decode(
      archivedRoutesResponseT,
      result.data
    );
    return { routes, cargoViews, routeViews };
  }
);

export const unarchiveRoute = createErrorReportingAsyncThunk(
  `${prefix}/unarchiveRoute`,
  async (route: { id: string }) => {
    await axios.post(`/api/routes/${route.id}/unarchive`, {
      id: route.id,
    });
  }
);

export const addWaypoint = createErrorReportingAsyncThunk(
  `${prefix}/addWaypoint`,
  async (input: { routeId: string; waypoint: WaypointData }) => {
    const { routeId, waypoint } = input;
    await axios.post(`/api/routes/${routeId}/waypoints`, waypoint);
  }
);

export const editWaypoint = createErrorReportingAsyncThunk(
  `${prefix}/editWaypoint`,
  async (input: { routeId: string; waypoint: WaypointData }) => {
    const { routeId, waypoint } = input;
    await axios.put(
      `/api/routes/${routeId}/waypoints/${waypoint.id}`,
      waypoint
    );
  }
);

export const deleteWaypoint = createErrorReportingAsyncThunk(
  `${prefix}/deleteWaypoint`,
  async (input: { routeId: string; waypointId: string }) => {
    const { routeId, waypointId } = input;
    await axios.delete(`/api/routes/${routeId}/waypoints/${waypointId}`);
  }
);

export const updateRouteName = createErrorReportingAsyncThunk(
  `${prefix}/ROUTE_UPDATE_NAME`,
  async (route: { id: string; route_name: string }) => {
    await axios.put(`/api/routes/update-name/`, {
      id: route.id,
      route_name: route.route_name,
    });
  }
);

export const assignToTeam = createErrorReportingAsyncThunk(
  `${prefix}/assign-to-team`,
  async ({ routeId, teamId }: { routeId: string; teamId: string }) => {
    return axios
      .patch(`/api/routes/${routeId}`, { teamId })
      .then(getData)
      .then(tPromise.decode(routeT));
  }
);

export interface UpdateRadiusArgs {
  routeId: string;
  radius: number;
}
export const updateRouteRadius = createErrorReportingAsyncThunk(
  `${prefix}/ROUTE_UPDATE_RADIUS`,
  async (args: UpdateRadiusArgs) => {
    await axios.post(`/api/routes/radius`, {
      id: args.routeId,
      radius: args.radius,
    });
  }
);

export const updateRouteTrailer = createErrorReportingAsyncThunk(
  `${prefix}/ROUTE_UPDATE_TRAILER`,
  async (route: { id: string; trailer_id: string }, { dispatch }) => {
    const res = await axios.post(
      `/api/routes/update-trailer`,
      updateRouteTrailerRequestT.encode({
        id: route.id,
        trailer_id: route.trailer_id,
      })
    );
    if (res.data.warnMultipleActiveRoutes) {
      dispatch(
        notifyL({
          namespace: "notifications",
          key: "warnMultipleActiveRoutes",
          type: "warning",
        })
      );
    }
    dispatch(getRouteStops(route.id));
  }
);

export const removeRouteTrailer = createErrorReportingAsyncThunk(
  `${prefix}/ROUTE_REMOVE_TRAILER`,
  async (route: { id: string }, { dispatch }) => {
    await axios.post(`/api/routes/remove-trailer`, {
      id: route.id,
    });
    dispatch(getRouteStops(route.id));
  }
);

export const archiveRoute = createErrorReportingAsyncThunk(
  `${prefix}/ROUTE_ARCHIVE`,
  async ({ id }: { id: string }, { dispatch }) => {
    await axios.post(`/api/routes/archive`, { id });
    dispatch(
      notifyL({
        namespace: "notifications",
        key: "routeArchived",
        type: "success",
      })
    );
    // dispatch(getAllRoutes());
  }
);

export const addRoute = createErrorReportingAsyncThunk(
  `${prefix}/add-route`,
  async (data: { trailerId?: string }) => {
    return axios
      .post("/api/routes", data || {})
      .then(getData)
      .then(tPromise.decode(routeT));
    // await dispatch(getAllRoutes());
  }
);

const archiveRouteAndCreateNew = createErrorReportingAsyncThunk(
  `${prefix}/add-route-with-trailer`,
  async (data: { routeId: string }, { dispatch }) => {
    const route = await axios
      .post(`/api/routes/${data.routeId}/archive-and-create-new`, data)
      .then(getData)
      .then(tPromise.decode(routeT));
    dispatch(
      notifyL({
        namespace: "notifications",
        key: "routeArchived",
        type: "success",
      })
    );
    return { route, archivedRouteId: data.routeId };
  }
);

export const addRouteWithTrailerAndWaypoint = unwrap(archiveRouteAndCreateNew);

export const updateRouteNote = createErrorReportingAsyncThunk(
  `${prefix}/ROUTE_UPDATE_NOTE`,
  async (route: { id: string; note?: string }) => {
    await axios.put(`/api/routes/update-note`, {
      id: route.id,
      note: route.note,
    });
  }
);

export const SORT_PROPERTY_KEY = "undispatchedRoutesSortProperty";
export const SORT_ORDER_KEY = "undispatchedRoutesSortOrder";

const sortPropertyLocalStorage = localStorage.getItem(SORT_PROPERTY_KEY) as any;
const sortOrderLocalStorage = localStorage.getItem(SORT_ORDER_KEY) as any;

const initialState: types.RoutesState = {
  routeEntities: {},
  routeUpdates: {},
  routeIds: [],
  offeredCargoIds: [],
  sortProperty: sortPropertyLocalStorage || undefined,
  routesSortOrder: sortOrderLocalStorage || "DESC",
  isSearchForArchived: false,
};

const sortRoutes = createErrorReportingAsyncThunk(
  `${prefix}/sort-routes`,
  async (
    arg: {
      sortProperty?: types.RouteSortProperty;
      sortOrder?: SortOrder;
    },
    thunkAPI
  ) => {
    const state: RootState = thunkAPI.getState();
    const routeIds = state.routes.routeIds;
    const trailers = state.data.trailers.entities;
    const sortProperty = arg.sortProperty || state.routes.sortProperty;
    const sortOrder: SortOrder =
      typeof arg.sortOrder !== "undefined"
        ? arg.sortOrder
        : state.routes.routesSortOrder;
    if (!sortProperty) {
      return { sortProperty, sortOrder, routeIds };
    }
    const routes = routeIds.map((routeId) => ({
      id: routeId,
      viewModel: state.data.routeViewModels.entities[routeId],
    }));
    const emptyRouteDate = sortOrder === "ASC" ? "0000-01-01" : "9999-12-31";
    const preSorted = sortBy(routes, "id");
    const sorted = (() => {
      switch (sortProperty) {
        case "PICKUPDATE":
          return sortBy(
            preSorted,
            ({ viewModel }) => viewModel?.startDate || emptyRouteDate
          );
        case "DROPOFFDATE":
          return sortBy(
            preSorted,
            ({ viewModel }) => viewModel?.endDate || emptyRouteDate
          );
        case "TRUCKNUMBER":
          return sortBy(
            preSorted,
            ({ viewModel }) =>
              viewModel?.trailerId && trailers[viewModel?.trailerId]?.number
          );
        default:
          return routes;
      }
    })().map((x) => x.id);
    if (sortOrder === "DESC") {
      sorted.reverse();
    }
    thunkAPI.dispatch(
      routesSlice.actions.setRoutes({
        sortProperty,
        sortOrder,
        routeIds: sorted,
      })
    );
  }
);

export const setUnassignedCargoSortProperty =
  dispatchingActions.setUnassignedCargoSortProperty;

export const setUnassignedCargoSortOrder =
  dispatchingActions.setUnassignedCargoSortOrder;

export const setRoutesSortProperty = (sortProperty: types.RouteSortProperty) =>
  sortRoutes({ sortProperty });

export const setRoutesSortOrder = (o: SortOrder | boolean) => {
  const sortOrder = typeof o === "boolean" ? (o ? "ASC" : "DESC") : o;
  return sortRoutes({ sortOrder });
};

const routesSlice = createSlice({
  name: "routes",
  initialState,
  reducers: {
    setRoutesSortProperty: (
      state,
      action: PayloadAction<types.RouteSortProperty>
    ) => {
      state.sortProperty = action.payload;
    },
    setRoutesSortOrder: (state, action: PayloadAction<SortOrder>) => {
      state.routesSortOrder = action.payload;
    },
    setRoutes: (
      state,
      action: PayloadAction<{
        sortProperty?: types.RouteSortProperty;
        sortOrder: SortOrder;
        routeIds: string[];
      }>
    ) => {
      state.sortProperty = action.payload.sortProperty;
      state.routesSortOrder = action.payload.sortOrder;
      state.routeIds = action.payload.routeIds;
    },
    setIsSearchingForArchived: (state, action: PayloadAction<boolean>) => {
      state.isSearchForArchived = action.payload;
      if (!action.payload) {
        delete state.archivedSearchRouteIds;
      }
    },
  },
  extraReducers: (builder) => {
    // Get
    builder.addCase(getRoutes.fulfilled, (state, action) => {
      const routes = action.payload;
      state.routeIds = routes.map((x) => x.id);
      state.routeEntities = keyBy(routes, "id");
    });
    builder.addCase(addRoute.fulfilled, (state, action) => {
      const route = action.payload;
      if (!state.routeIds.includes(route.id)) {
        state.routeIds.unshift(route.id);
      }
      state.routeEntities[route.id] = route;
    });
    // Update
    builder.addCase(updateRouteRadius.fulfilled, (state, action) => {
      const { routeId, radius } = action.meta.arg;
      const route = state.routeEntities[routeId];
      if (route) {
        route.radius = radius;
      }
    });
    builder.addCase(updateRouteTrailer.fulfilled, (state, action) => {
      const { id, trailer_id } = action.meta.arg;
      const route = state.routeEntities[id];
      if (route) {
        route.trailer_id = trailer_id;
      }
    });
    builder.addCase(removeRouteTrailer.fulfilled, (state, action) => {
      const { id } = action.meta.arg;
      const route = state.routeEntities[id];
      if (route) {
        route.trailer_id = null;
      }
    });
    builder.addCase(updateRouteNote.fulfilled, (state, action) => {
      const { id, note } = action.meta.arg;
      const route = state.routeEntities[id];
      if (route) {
        route.note = note;
      }
    });
    builder.addCase(updateRouteName.fulfilled, (state, action) => {
      const { id, route_name } = action.meta.arg;
      const route = state.routeEntities[id];
      if (route) {
        route.route_name = route_name;
      }
    });
    builder.addCase(cargoActions.updateSmartMatch.pending, (state, action) => {
      const { cargoId, smartMatch } = action.meta.arg;
      if (smartMatch) {
        state.offeredCargoIds.push(cargoId);
      } else {
        state.offeredCargoIds = state.offeredCargoIds.filter(
          (x) => x !== cargoId
        );
      }
    });
    builder.addCase(cargoDeleted, (state, action) => {
      const { cargoId } = action.payload;
      {
        const idx = state.offeredCargoIds.indexOf(cargoId);
        if (idx !== -1) state.offeredCargoIds.splice(idx, 1);
      }
    });
    builder.addCase(getAllArchivedRoutes.fulfilled, (state, action) => {
      // Overwrite unarchived routes, with archived routes
      const { routes } = action.payload;
      const routeIds = routes.map((x) => x.id);
      state.routeIds = routeIds;
      state.offeredCargoIds = [];
      state.routeEntities = keyBy(routes, "id");
      state.archivedSearchRouteIds = routeIds;
    });
    builder.addCase(archiveRouteAndCreateNew.fulfilled, (state, action) => {
      const { archivedRouteId, route } = action.payload;
      state.routeIds = [
        ...state.routeIds.filter((id) => id !== archivedRouteId),
        route.id,
      ];
      state.routeEntities[route.id] = route;
      delete state.routeEntities[archivedRouteId];
    });
    builder
      .addCase(unarchiveRoute.fulfilled, (state, action) => {
        // Remove unarchived route from archived route ids
        const { id } = action.meta.arg;
        state.archivedSearchRouteIds = state.archivedSearchRouteIds?.filter(
          (x) => x !== id
        );
      })
      .addCase(addDriver.pending, (state, action) => {
        const { routeId, driverId } = action.meta.arg;
        const route = state.routeEntities[routeId];
        state.routeUpdates[routeId] = route;
        state.routeEntities[routeId] = route && {
          ...route,
          drivers: [...route.drivers, driverId],
        };
      })
      .addCase(addDriver.fulfilled, (state, action) => {
        const { routeId } = action.meta.arg;
        delete state.routeUpdates[routeId];
      })
      .addCase(addDriver.rejected, (state, action) => {
        const { routeId } = action.meta.arg;
        const route = state.routeUpdates[routeId];
        if (route) {
          state.routeEntities[routeId] = route;
          delete state.routeUpdates[routeId];
        }
      })
      .addCase(removeDriver.pending, (state, action) => {
        const { routeId, driverId } = action.meta.arg;
        const route = state.routeEntities[routeId];
        state.routeUpdates[routeId] = route;
        state.routeEntities[routeId] = route && {
          ...route,
          drivers: route.drivers.filter((x) => x !== driverId),
        };
      })
      .addCase(assignToTeam.fulfilled, (state, action) => {
        const { routeId } = action.meta.arg;
        state.routeEntities[routeId] = action.payload;
      })
      .addCase(removeDriver.fulfilled, (state, action) => {
        const { routeId } = action.meta.arg;
        delete state.routeUpdates[routeId];
      })
      .addCase(removeDriver.rejected, (state, action) => {
        const { routeId } = action.meta.arg;
        const route = state.routeUpdates[routeId];
        if (route) {
          state.routeEntities[routeId] = route;
          delete state.routeUpdates[routeId];
        }
      })
      .addCase(dispatchingActions.clear, (state) => {
        delete state.sortProperty;
      });
  },
});

export const { setIsSearchingForArchived } = routesSlice.actions;
export default routesSlice.reducer;
