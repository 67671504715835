import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { Settings } from "luxon";
import { initReactI18next } from "react-i18next";
import da from "./da";
import en from "./en";
import de from "./de";
import se from "./se";

export const Languages = {
  da: "Dansk",
  en: "English",
  de: "Deutsch",
  se: "Svenska",
} as const;

export const supportedDriverLanguages = [
  "da",
  "en",
  "de",
  "se",
  "ru",
  "ro",
  "it",
  "ee",
] as const;

export type SupportedDriverLanguage = (typeof supportedDriverLanguages)[number];

export type LanguageType = keyof typeof Languages;
export const supportedLanguages: LanguageType[] = Object.keys(
  Languages
) as LanguageType[];

// With this syntax, only translations defined in all resources can be used,
// thus the compiler will tell if translations are missing
type CoreResources = typeof da;

declare module "i18next" {
  interface CustomTypeOptions {
    defaultNS: "translation";
    resources: CoreResources;
  }
}

// NB. When adding a NEW TRANSLATION, REMEMBER to IMPORT the corresponding MOMENT and dayjs LOCALE at the top!
const i18nResources = { da, en, de, se };

const setDateLanguage = (language: string) => {
  // Luxon
  Settings.defaultLocale = i18n.language;
};

export type i18nTranslationKey = any;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      lng: "da",
      defaultNS: "translation",
      fallbackLng: "da",
      resources: i18nResources,
      nsSeparator: ":",
      interpolation: {
        escapeValue: false, // not needed for react as it does escape per default to prevent xss!
      },
    },
    (err, t) => {
      setDateLanguage(i18n.language);
      document.documentElement.lang = i18n.language;
    }
  );

export const { t } = i18n;

export const changeLanguage = async (language: LanguageType) => {
  await i18n.changeLanguage(language);
  setDateLanguage(language);
  document.documentElement.lang = i18n.language;
};

export const getLanguage = () => i18n.language as LanguageType;
