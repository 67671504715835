import * as t from "io-ts";
import { obsoleteDriverSessionT } from "dora-contracts";

export const sessionT = obsoleteDriverSessionT;

export const sessionsT = t.array(sessionT);

export const responseT = t.strict({
  sessions: sessionsT,
});

export type Session = t.TypeOf<typeof sessionT>;
