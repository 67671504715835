import { createCargoReportTypes } from "dora-contracts";
import * as t from "io-ts";

const { cargosReportTableDataT, cargosReportRowT } = createCargoReportTypes({
  dateTime: t.string,
});

export type CargosReportTableData = t.TypeOf<typeof cargosReportTableDataT>;
export type CargosReportTableRow = t.TypeOf<typeof cargosReportRowT>;

export { cargosReportTableDataT };
