import { useMemo } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function FeaturesList(props: {
  qualities: string[];
  qualityOptions: { id: string; label: string }[];
  cargoDemands?: boolean;
  label: string;
  onChange: (value: any) => void;
}) {
  const { qualities, qualityOptions, label } = props;
  const values = useMemo(
    () => qualityOptions.filter((x) => qualities.includes(x.id)),
    [qualities, qualityOptions]
  );

  return (
    <div>
      <Autocomplete
        multiple
        size="small"
        options={qualityOptions}
        value={values}
        disableCloseOnSelect
        selectOnFocus
        autoHighlight
        handleHomeEndKeys
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.id}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.label}
          </li>
        )}
        onChange={(e, option) => {
          props.onChange(option.map((selected) => selected.id));
        }}
        renderInput={(params) => (
          <TextField {...params} label={label} margin="dense" size="small" />
        )}
      />
    </div>
  );
}
