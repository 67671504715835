import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../redux-store";

export const selectStatus = (state: RootState) => state.data.drivers.status;
export const selectDrivers = createSelector(
  (state: RootState) => state.data.drivers.driverEntities,
  (state: RootState) => state.data.drivers.driverIds,
  (entities, ids) => ids.map((id) => entities[id])
);

export const selectDriverFromUser =
  (user?: { userType: string; driverId?: string }) => (state: RootState) => {
    if (user?.userType !== "DRIVER") return undefined;

    return selectDriver(user.driverId)(state);
  };

export const selectDriver = (id?: string) => (state: RootState) =>
  (id && state.data.drivers.driverEntities[id]) || null;

export const selectSpecificDrivers = (ids: string[]) => (state: RootState) =>
  ids.map((id) => state.data.drivers.driverEntities[id]);

export const selectDriverNos = createSelector(selectDrivers, (drivers) =>
  drivers.map((x) => x.number)
);
