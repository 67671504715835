import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./new-dora-inputs.scss";

type DoraTextInputProps = {
  id?: string;
  icon?: IconProp;
  placeholder?: string;
  className?: string;
  onChange?: (e: any) => void;
  disabled?: boolean;
  suffix?: string;
  register?: any;
  error?: boolean;
};

type UseFormsInputProps = {
  register: any;
};

const DoraNumberInput = (props: DoraTextInputProps) => {
  const {
    id,
    icon,
    placeholder,
    className,
    error,
    register,
    onChange,
    disabled,
    suffix,
  } = props;
  const wrapperClasses = classnames(
    "dora-text-input-wrapper",
    disabled ? "dora-text-input-wrapper--disabled" : null,
    error ? "dora-text-input-wrapper--error" : null,
    className
  );
  const classes = classnames("dora-text-input__input", {
    "dora-text-input__input--with-icon": !!icon,
  });

  const onChangeProp = !props.register
    ? {
        onChange: (e: any) => onChange && onChange(e.target.value),
      }
    : {};

  return (
    <div className={wrapperClasses}>
      {icon && (
        <div className="dora-text-input__icon">
          <FontAwesomeIcon icon={icon} />
        </div>
      )}
      {suffix && <div className="dora-text-input__suffix">{suffix}</div>}
      <input
        type="number"
        {...(id && { id })}
        {...register}
        {...onChangeProp}
        disabled={disabled}
        className={classes}
        placeholder={placeholder}
      />
    </div>
  );
};

const UFDoraNumberInput = (props: DoraTextInputProps & UseFormsInputProps) => {
  const { register, ...rest } = props;
  return <DoraNumberInput register={register} {...rest} />;
};

export { DoraNumberInput, UFDoraNumberInput };
