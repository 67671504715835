import { Box } from "@mui/material";
import styled from "styled-components";
import NavBar from "./nav/navbar";
import ErrorBoundary from "./ErrorBoundary";

const PageContainer = styled.div`
  padding-top: 48px;
  height: 100vh;
  flex-grow: 1;
  overflow-y: auto;
`;

const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
      }}
    >
      <NavBar />

      <PageContainer>
        <div style={{ height: "100%" }}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </div>
      </PageContainer>
    </Box>
  );
};

export default Layout;
