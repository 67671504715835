import { Dialog } from "@mui/material";
import SavedLocation, { SavedLocationType } from "./SavedLocation";
import "./index.css";
import { useState } from "react";
import AddEditSavedLocation from "./AddEditSavedLocation";
import ManageFavoritesAndTerminals from "./ManageFavoritesAndTerminals";

const ManageFavoritesAndTerminalsDialog = ({
  onClose,
  addTerminalOnly,
}: {
  onClose: () => void;
  addTerminalOnly?: boolean;
}) => {
  const [type, setType] = useState<SavedLocationType>("FAVORITE");
  const [savedLocationForDialog, setSavedLocationForDialog] = useState<
    SavedLocation | "NEW" | null
  >(addTerminalOnly ? "NEW" : null);

  const onAddButtonClick = () => setSavedLocationForDialog("NEW");

  return (
    <Dialog open onClose={onClose}>
      {savedLocationForDialog ? (
        <AddEditSavedLocation
          addTerminalOnly={addTerminalOnly}
          location={savedLocationForDialog}
          type={type}
          onClose={onClose}
          onBack={() => setSavedLocationForDialog(null)}
        />
      ) : (
        <ManageFavoritesAndTerminals
          onClose={onClose}
          type={type}
          onTypeChange={setType}
          onAddClick={onAddButtonClick}
          onEditClick={(location) => setSavedLocationForDialog(location)}
        />
      )}
    </Dialog>
  );
};

export default ManageFavoritesAndTerminalsDialog;
