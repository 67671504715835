import { RootState } from "../../../redux-store";
import { DateTime } from "luxon";
import { createSelector } from "@reduxjs/toolkit";

export const selectCargoView = (cargoId: string) => (state: RootState) =>
  state.data.cargoViews.entities[cargoId];

export const selectCargoViews = (cargoIds: string[]) =>
  createSelector(selectAllCargoViews, (cargoViews) =>
    cargoIds.map((id) => cargoViews[id])
  );

const selectAllCargoViews = (state: RootState) =>
  state.data.cargoViews.entities;

export const selectCargoViewsBetweenDates = (
  cargoIds: string[],
  fromDate: DateTime | null,
  toDate: DateTime | null
) =>
  createSelector(selectAllCargoViews, (entities) =>
    cargoIds
      .map((id) => entities[id])
      .filter((c) => {
        if (
          fromDate &&
          c.firstStop.date &&
          c.lastStop?.date &&
          DateTime.fromISO(c.firstStop.date) < fromDate &&
          DateTime.fromISO(c.lastStop.date) < fromDate
        ) {
          return false;
        }
        return !(
          toDate &&
          c.lastStop?.date &&
          c.firstStop.date &&
          DateTime.fromISO(c.lastStop.date) > toDate &&
          DateTime.fromISO(c.firstStop.date) > toDate
        );
      })
  );
