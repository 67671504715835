import { RootState } from "../../../redux-store";
import { createSelector } from "@reduxjs/toolkit";

// export const isLocationWithCoordsSaved =
//   (coords: { lon: number; lat: number }) => (state: RootState) =>
//     state.data.savedLocations.locations.some(
//       (l) =>
//         l.place.coord.lon === coords.lon && l.place.coord.lat === coords.lat
//     );

const selectLocations = (state: RootState) =>
  state.data.savedLocations.locations;

export const selectFavoriteLocations = createSelector(
  selectLocations,
  (locations) => locations.filter((l) => l.type === "FAVORITE")
);

export const selectTerminalLocations = createSelector(
  selectLocations,
  (locations) => locations.filter((l) => l.type === "TERMINAL")
);
