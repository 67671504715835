import { CloseButton } from "../../dispatching-planning/common/close-button";
import { Button } from "@mui/material";
import SavedLocationRow from "./SavedLocationRow";
import SavedLocation, { SavedLocationType } from "./SavedLocation";
import { useEffect, useState } from "react";
import SavedLocationTypeSelectionOption from "./SavedLocationTypeSelectionOption";
import { useAppDispatch, useSelector } from "../../../redux-store";
import {
  selectFavoriteLocations,
  selectTerminalLocations,
} from "../../../ducks/data/saved-locations/selectors";
import { loadSavedLocations } from "../../../ducks/data/saved-locations";
import { useTranslation } from "react-i18next";

const ManageFavoritesAndTerminals = ({
  onAddClick,
  onEditClick,
  onTypeChange,
  type,
  onClose,
}: {
  onAddClick: () => void;
  onEditClick: (location: SavedLocation) => void;
  onTypeChange: (type: SavedLocationType) => void;
  type: SavedLocationType;
  onClose: () => void;
}) => {
  const { t } = useTranslation("modals");

  const [selectionType, setSelectionType] = useState<SavedLocationType>(type);
  const [page, setPage] = useState(1);

  const terminals = useSelector(selectTerminalLocations);
  const favorites = useSelector(selectFavoriteLocations);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadSavedLocations());
  }, [dispatch]);

  const onCategoryClick = (type: SavedLocationType) => {
    setPage(1);
    setSelectionType(type);
    onTypeChange(type);
    dispatch(loadSavedLocations());
  };

  const getLocations = () => {
    const locations = selectionType === "TERMINAL" ? terminals : favorites;
    return locations.slice((page - 1) * 7, page * 7);
  };

  const totalPages = Math.ceil(
    (selectionType === "TERMINAL" ? terminals : favorites).length / 7
  );

  return (
    <div className="favorites-terminals-dialog__container">
      <div className="favorites-terminals-dialog__title">
        <div>{t("manageSavedLocations.manageTitle")}</div>
        <CloseButton onClick={onClose} />
      </div>
      <div className="favorites-terminals-dialog__subtitle">
        {t("manageSavedLocations.description")}
      </div>
      <div className="favorites-terminals-dialog__first-row">
        <div style={{ display: "flex" }} role="radiogroup">
          <SavedLocationTypeSelectionOption
            type={"favorite"}
            active={selectionType === "FAVORITE"}
            onClick={() => onCategoryClick("FAVORITE")}
          />
          <SavedLocationTypeSelectionOption
            type={"terminal"}
            active={selectionType === "TERMINAL"}
            onClick={() => onCategoryClick("TERMINAL")}
          />
        </div>
        <Button variant="contained" onClick={onAddClick}>
          + {t("manageSavedLocations.addButton")}
        </Button>
      </div>
      <div className="favorites-terminals-dialog__location-text">
        {t("manageSavedLocations.locations")}
      </div>
      {getLocations().map((location, i) => (
        <div key={i}>
          <hr className="favorites-terminals-dialog__hr" />
          <SavedLocationRow
            location={location}
            onEditClick={() => onEditClick(location)}
          ></SavedLocationRow>
        </div>
      ))}
      <hr className="favorites-terminals-dialog__hr" />
      <div className="favorites-terminals-dialog__bottom-row">
        <div>
          {t("manageSavedLocations.pagination", {
            currentPage: page,
            totalPages,
          })}
        </div>
        <div className="favorites-terminals-dialog__pagination-buttons">
          <Button
            variant="outlined"
            onClick={() => setPage(page - 1)}
            disabled={page === 1}
          >
            {t("manageSavedLocations.previousButton")}
          </Button>
          <Button
            variant="outlined"
            onClick={() => setPage(page + 1)}
            disabled={page === totalPages || totalPages === 0}
          >
            {t("manageSavedLocations.nextButton")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ManageFavoritesAndTerminals;
