import { Dialog, TextField } from "@mui/material";
import DoraButton from "../../../Toolkit/DoraButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import { faArrowLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UFDoraSelect } from "../../../Toolkit/DoraSelect";
import { FormProvider, useForm } from "react-hook-form";
import { nanoid } from "nanoid";
import { selectClients } from "../../../../ducks/data/clients/selectors";
import { useAppDispatch, useSelector } from "../../../../redux-store";
import { selectCustomerContacts } from "../../../../ducks/data/economics/customer-contacts/selectors";
import { selectDepartments } from "../../../../ducks/data/departments/selectors";
import { selectDelivery } from "../../../../ducks/data/deliveries/selectors";
import useFeature from "../../../../hooks/useFeature";
import * as actions from "../../../../ducks/data/economics/customer-contacts";
import { loadDepartments } from "../../../../ducks/data/departments";
import { sendInvoice } from "../../../../ducks/data/deliveries";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DoraCheckbox } from "../../../Toolkit/DoraCheckbox";
import { selectCargoDocuments } from "../../../../ducks/data/cargo-documents/selectors";
import { formatBasicStringDate } from "../../../../helpers/date-helper";
import { useTranslation } from "react-i18next";
import ClientSelect from "../ClientSelect";
import "./InvoiceDialog.scss";
import { selectTrailerDepartmentIdForCargo } from "../../../../ducks/data/trailers/selectors";
import { loadCargo } from "../../../../ducks/app/cargo-dialog";
import { Document } from "../../../../ducks/data/cargo-documents/types";
import clsx from "clsx";

type InvoiceDialogProps = {
  type: "DIRECT" | "CONSOLIDATED";
  linesIds: string[];
  deliveryId: string;
  cargoId: string;
  onClose: (linesInvoiced?: boolean) => void;
  clientId?: string;
  clientContact: string | null;
  invoiceRef: string | null;
};

type InvoiceDialogFormValues = {
  client: string | null;
  contactPerson: string | null;
  department: string | null;
  invoiceRef: string | null;
  attachmentIds: string[];
};

const schema = yup.object().shape({
  client: yup.string().required(),
  contactPerson: yup.string().nullable(),
  department: yup.string().nullable(),
});

const InvoiceDialog = (props: InvoiceDialogProps) => {
  const { type, deliveryId, linesIds, onClose, cargoId } = props;
  const [step, setStep] = useState(1);
  const { t } = useTranslation(["shipmentModal", "components"]);
  const documents = useSelector(selectCargoDocuments(cargoId));
  const trailerDepartmentId = useSelector(
    selectTrailerDepartmentIdForCargo(cargoId)
  );

  const dispatch = useAppDispatch();
  const [documentsIdsSelection, setDocumentsIdsSelection] = useState<string[]>(
    []
  );

  useEffect(() => {
    dispatch(loadDepartments());
    dispatch(loadCargo(cargoId));
  }, [dispatch, cargoId]);

  const formMethods = useForm<InvoiceDialogFormValues>({
    defaultValues: {
      client: props.clientId || null,
      contactPerson: props.clientContact || null,
      department: trailerDepartmentId,
      attachmentIds: [],
    },
    resolver: yupResolver(schema),
  });
  const {
    formState: { errors, isValid },
    control,
    watch: watchFormChanges,
    setValue,
    getValues,
  } = formMethods;

  useEffect(() => {
    setValue("department", trailerDepartmentId);
  }, [trailerDepartmentId, setValue]);

  const delivery = useSelector(selectDelivery(deliveryId));
  const clients = useSelector(selectClients);
  const [
    shouldShowInvoiceDepartmentDropdown,
    setShouldShowInvoiceDepartmentDropdown,
  ] = useState(false);
  const departments = useSelector(selectDepartments);
  const isDepartmentsFeatureEnabled = useFeature("invoice-departments");
  const contacts = useSelector(
    selectCustomerContacts(watchFormChanges("client"))
  );

  const resolveContactPerson = useCallback(
    (clientId?: string | null) => {
      setValue(
        "contactPerson",
        clientId === delivery.clientId ? delivery.customerContactId : null
      );
    },
    [setValue, delivery]
  );

  const reloadCustomerContacts = useCallback(
    (clientId?: string | null) => {
      if (clientId) {
        dispatch(actions.loadCustomerContacts(clientId));
        setValue("contactPerson", null);
      }
    },
    [dispatch, setValue]
  );

  useEffect(() => {
    const subscription = watchFormChanges((value, changes) => {
      const formClientId = value.client;
      if (changes.name?.includes("client")) {
        resolveContactPerson(formClientId);
        reloadCustomerContacts(formClientId);
      }
    });
    return () => subscription.unsubscribe();
  }, [watchFormChanges, resolveContactPerson, reloadCustomerContacts]);

  useEffect(() => {
    if (isDepartmentsFeatureEnabled && departments.length > 0) {
      setShouldShowInvoiceDepartmentDropdown(true);
    }
  }, [departments, isDepartmentsFeatureEnabled]);

  const onSendInvoiceClick = async () => {
    const { client, contactPerson, department } = getValues();
    dispatch(
      sendInvoice({
        clientId: client!,
        type,
        deliveryId,
        attachmentFileIds: documentsIdsSelection,
        customerContactId: contactPerson || null,
        selectedLineIds: linesIds,
        departmentId: department || null,
      })
    );
    // TODO: error handling, keep modal open and show toast?
    onClose(true);
  };

  const clientInputId = useMemo(() => nanoid(), []);
  const contactInputId = useMemo(() => nanoid(), []);
  const departmentInputId = useMemo(() => nanoid(), []);
  const invoiceRefInputId = useMemo(() => nanoid(), []);

  const onDocumentSelection = (id: string) => () => {
    let selection = [...documentsIdsSelection];
    if (selection.includes(id)) {
      selection = selection.filter((i) => i !== id);
    } else {
      selection.push(id);
    }
    setDocumentsIdsSelection(selection);
  };

  const isAttachableDocument = (document: Document) => {
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
    return allowedTypes.includes(document.fileType);
  };

  const attachableDocuments = useMemo(
    () => documents.filter(isAttachableDocument),
    [documents]
  );

  const onAllDocumentsSelection = () => {
    if (documentsIdsSelection.length === attachableDocuments.length) {
      setDocumentsIdsSelection([]);
    } else {
      setDocumentsIdsSelection(attachableDocuments.map((d) => d.id));
    }
  };

  const onBackButtonClick = () => {
    setStep(1);
    setDocumentsIdsSelection([]);
  };

  return (
    <Dialog open onClose={() => onClose()}>
      <div className="invoice-dialog">
        {step === 1 && (
          <FormProvider {...formMethods}>
            <form onSubmit={() => setStep(2)}>
              <div className="invoice-dialog__header">
                <h2 className="invoice-dialog__header-title">
                  {type === "DIRECT"
                    ? t("shipmentModal:cards.finances.invoiceButton")
                    : t("shipmentModal:cards.finances.sendConsolidatedButton")}
                </h2>
                <DoraButton
                  variant="ghost"
                  type="button"
                  className="invoice-dialog__header-close"
                  onClick={() => onClose()}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </DoraButton>
              </div>
              <div className="invoice-dialog__body">
                <div className="invoice-dialog__input-row">
                  <div>
                    <label htmlFor={clientInputId}>
                      {t("shipmentModal:inputs.clientLabel")}
                      <span aria-hidden>*</span>
                    </label>
                  </div>
                  <ClientSelect
                    autofocus
                    autoselect
                    name="client"
                    refreshButtonLabel={t(
                      "shipmentModal:inputs.clientRefreshButtonLabel"
                    )}
                    control={control}
                    clients={clients}
                    id={clientInputId}
                    className="w-full"
                    placeholder={t("shipmentModal:cards.finances.chooseClient")}
                    error={!!errors.client}
                  />
                </div>
                <div className="invoice-dialog__input-row">
                  <div>
                    <label htmlFor={contactInputId}>
                      {t("shipmentModal:inputs.contactPlaceholder2")}
                    </label>
                  </div>
                  <UFDoraSelect
                    fullWidth
                    autoselect
                    placeholder={t("shipmentModal:inputs.contactPlaceholder2")}
                    className="w-full"
                    id={contactInputId}
                    control={control}
                    name={"contactPerson"}
                    options={
                      contacts?.map((contact) => ({
                        label: contact.name,
                        value: contact.id,
                      })) || []
                    }
                  ></UFDoraSelect>
                </div>
                {shouldShowInvoiceDepartmentDropdown && (
                  <div className="invoice-dialog__input-row">
                    <div>
                      <label htmlFor={departmentInputId}>
                        {t("shipmentModal:cards.finances.department")}
                      </label>
                    </div>
                    <UFDoraSelect
                      fullWidth
                      autoselect
                      className="w-full"
                      placeholder={t("shipmentModal:cards.finances.department")}
                      id={departmentInputId}
                      options={departments.map((department) => ({
                        label: department.name,
                        value: department.id,
                      }))}
                      name="department"
                      control={control}
                    />
                  </div>
                )}
                <div className="invoice-dialog__input-row">
                  <div>
                    <label htmlFor={invoiceRefInputId}>
                      {t("shipmentModal:inputs.invoiceRefLabel")}
                    </label>
                  </div>
                  <TextField
                    disabled
                    value={props.invoiceRef}
                    id={invoiceRefInputId}
                    placeholder={t("shipmentModal:inputs.invoiceRefLabel")}
                  />
                </div>
              </div>
              <div className="invoice-dialog__footer">
                <DoraButton
                  variant="primary"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    onClose();
                  }}
                >
                  {t("components:buttonLabels.cancel")}
                </DoraButton>
                <DoraButton
                  variant="primary-filled"
                  type="submit"
                  disabled={!isValid}
                >
                  {t("components:buttonLabels.next")}
                </DoraButton>
              </div>
            </form>
          </FormProvider>
        )}
        {step === 2 && (
          <>
            <div className="invoice-dialog__header">
              <DoraButton
                className="invoice-dialog__back-button"
                variant="primary"
                onClick={onBackButtonClick}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </DoraButton>
              <h2 className="invoice-dialog__header-title">
                {t("shipmentModal:cards.finances.attachDocuments")}
              </h2>
              <DoraButton
                variant="ghost"
                className="invoice-dialog__header-close"
                onClick={() => onClose()}
              >
                <FontAwesomeIcon icon={faTimes} />
              </DoraButton>
            </div>
            {documents.length ? (
              <table className="invoice-dialog__table">
                <thead>
                  <tr style={{ paddingTop: 12 }}>
                    <th>
                      <DoraCheckbox
                        className="flex justify-center"
                        checked={
                          documentsIdsSelection.length ===
                          attachableDocuments.length
                        }
                        onChange={onAllDocumentsSelection}
                      />
                    </th>
                    <th style={{ padding: 12 }}>
                      {t("shipmentModal:cards.finances.fileName")}
                    </th>
                    <th style={{ padding: 12 }}>
                      {t("shipmentModal:cards.finances.dateUploaded")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {documents.map((document, i) => {
                    const isAttachable = isAttachableDocument(document);
                    const isSelected = documentsIdsSelection.includes(
                      document.id
                    );
                    const classes = clsx({
                      "invoice-document__unselectable": !isAttachable,
                      "invoice-document__selected": isSelected,
                    });
                    return (
                      <tr key={i} className={classes}>
                        <td>
                          <DoraCheckbox
                            className="flex justify-center"
                            checked={documentsIdsSelection.includes(
                              document.id
                            )}
                            disabled={!isAttachable}
                            onChange={onDocumentSelection(document.id)}
                          />
                        </td>
                        <td>
                          <div className="invoice-dialog__document-row">
                            {/*<div className="invoice-document-row__img-wrapper">*/}
                            {/*  <img src={document.imgUrl} alt="" />*/}
                            {/*</div>*/}
                            <div style={{ padding: 12 }}>{document.name}</div>
                          </div>
                        </td>
                        <td>{formatBasicStringDate(document.addedAt)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <div className="pt-4 px-5 pb-3 text-center">
                {t("shipmentModal:cards.finances.noDocuments")}
              </div>
            )}
            <div className="invoice-dialog__footer">
              <DoraButton variant="primary" onClick={() => onClose()}>
                {t("components:buttonLabels.cancel")}
              </DoraButton>
              <DoraButton variant="primary-filled" onClick={onSendInvoiceClick}>
                {t("shipmentModal:cards.finances.sendInvoiceButton")}
              </DoraButton>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default InvoiceDialog;
