import * as t from "io-ts";
import { cargoEmissionsT, nullable } from "dora-contracts";
import { cargoViewT } from "../cargo-views/types";

export const cargoEmissionsResponseT = t.strict({
  clientId: nullable(t.string),
  cargoView: cargoViewT,
  cargoEmissions: cargoEmissionsT,
});
export const cargoEmissionsResponsesT = t.array(cargoEmissionsResponseT);

export type CargoEmissionsResponse = t.TypeOf<typeof cargoEmissionsResponseT>;
