// Delete dublicates with the same value in key
export const arrayUniqueByKey = <T>(array: T[], key: keyof T) => {
  return [...new Map(array.map((item) => [item[key], item])).values()];
};

export const ensureStringArray = (input: unknown[]): string[] => {
  if (input.every((x) => typeof x === "string")) {
    return input as string[];
  }
  throw new Error("Input is not a string array");
};
