import { RootState } from "../../../redux-store";
import { selectTrailer, selectTrailers } from "../trailers/selectors";
import { selectDriver } from "../drivers/selectors";
import { createSelector } from "@reduxjs/toolkit";
import keyBy from "lodash/keyBy";
import { Trailer } from "../trailers/types";
import { VehicleForLiveView } from "./types";

const selectTrucksWithTrackers = createSelector(selectTrailers, (trailers) =>
  // Belongs to src/ducks/data/trailers, but created here unexported
  // because it is untested, and used for live view only
  trailers.filter((x) => x.trackerDeviceId)
);

export const selectVehiclesForLiveView = createSelector(
  selectTrucksWithTrackers,
  (state: RootState) => state.data.liveView.vehiclesForLiveView,
  (
    trucks,
    vehiclesForLiveView
  ): {
    id: string;
    truck: Trailer;
    liveViewSession?: VehicleForLiveView;
  }[] => {
    const liveView = keyBy(vehiclesForLiveView, (x) => x.truckId);
    return trucks.map((truck) => ({
      id: truck.id,
      truck,
      liveViewSession: liveView[truck.id],
    }));
  }
);

export const selectTruckRouteForLiveView = (state: RootState) => {
  const routeForLiveView = state.data.liveView.routeForLiveView;
  if (!routeForLiveView) {
    return null;
  }
  const truck = selectTrailer(routeForLiveView.vehicle.truckId)(state);
  const trailers = routeForLiveView.vehicle.trailerIds.map((id) =>
    selectTrailer(id)(state)
  );
  const driver = selectDriver(routeForLiveView.vehicle.driverId)(state);

  return {
    truckNumber: truck?.number,
    truckId: routeForLiveView.vehicle.truckId,
    trailerNumber: trailers[0]?.number,
    driverName: driver ? `${driver.firstName} ${driver.lastName[0]}.` : null,
    driverPhone: driver
      ? `${driver.phoneNo.countryCode}${driver.phoneNo.number}`
      : "",
    driverStartDayDate: routeForLiveView.driverStartDayDate,
    routeId: routeForLiveView.vehicle.routeId,
    pastLocations: routeForLiveView.pastLocations,
    futureLocations: routeForLiveView.futureLocations,
  };
};

export const selectTrackerDeviceMessagesForLiveView = (state: RootState) =>
  state.data.liveView.routeForLiveViewFlespiMessages;
