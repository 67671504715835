import { formatValidationErrors } from "io-ts-reporters";
import * as tPromise from "io-ts-promise";

export const prettyPrintIfDecodeError = (err: unknown) => {
  if (tPromise.isDecodeError(err)) {
    const msg = formatValidationErrors(err.errors);
    throw new Error(`Validation errors: \n${msg}`);
  } else {
    throw err;
  }
};
