import "./DoraButton.scss";
import classnames from "classnames";
import * as React from "react";

type HTMLButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonVariant = "primary" | "error" | "primary-filled" | "ghost";

type ButtonProps = {
  variant: ButtonVariant;
} & HTMLButtonProps;

const DoraButton = (props: ButtonProps) => {
  const { variant, className, ...rest } = props;
  const classes = classnames(
    "dora-button",
    {
      "dora-button--primary": variant === "primary",
      "dora-button--primary-filled": variant === "primary-filled",
      "dora-button--error": variant === "error",
      "dora-button--ghost": variant === "ghost",
    },
    className
  );

  return <button className={classes} {...rest} />;
};

export default DoraButton;
