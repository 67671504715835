import { useEffect } from "react";
/* eslint-disable no-unused-vars */
import { useSelector } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { theme, darkTheme } from "./styles/theme";
import "./styles/style.css";
import { ThemeProvider } from "@mui/material/styles";
import ErrorBoundary from "./Components/ErrorBoundary";
// This is needed to initialize i18next
import "./i18n/i18n";
// import { useTranslation } from "react-i18next";

// UI
import {
  selectCurrentLanguage,
  selectDarkMode,
} from "./ducks/app/userSettings/selectors";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import DragNDropProvider from "./DragNDropProvider";
import { changeLanguage } from "./i18n/i18n";
import DoraRoutes from "./pages/DoraRoutes";
import SocketConnection from "./SocketConnection";
import { useL10n } from "./l10n";

const LanguageSwitcher = () => {
  const selectedLanguage = useSelector(selectCurrentLanguage);
  useEffect(() => {
    changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  return null;
};

export default function App() {
  // const { t } = useTranslation(["dispatching", "translation", "fleet"]);
  const lightTheme = useSelector(selectDarkMode);
  useEffect(() => {
    document.body.setAttribute("data-theme", lightTheme ? "dark" : "light");
  }, [lightTheme]);
  const l10n = useL10n();
  return (
    <ErrorBoundary>
      <LanguageSwitcher />
      <DragNDropProvider>
        <ThemeProvider theme={lightTheme ? darkTheme : theme}>
          <CssBaseline />
          <LocalizationProvider
            dateAdapter={AdapterLuxon}
            adapterLocale={l10n.locale}
          >
            <ErrorBoundary>
              <SocketConnection>
                <DoraRoutes />
              </SocketConnection>
            </ErrorBoundary>
          </LocalizationProvider>
        </ThemeProvider>
      </DragNDropProvider>
    </ErrorBoundary>
  );
}
