const trucksColors = [
  {
    color: "#ff0000",
    description: "Tag 1",
    id: "1",
  },
  {
    color: "#008000",
    description: "Tag 2",
    id: "2",
  },
  {
    color: "#0000ff",
    description: "Tag 3",
    id: "3",
  },
  {
    color: "#ffa500",
    description: "Tag 4",
    id: "4",
  },
  {
    color: "#87cefa",
    description: "Tag 5",
    id: "5",
  },
  {
    color: "#c937cb",
    description: "Tag 6",
    id: "6",
  },
  {
    color: "#FF00AA",
    description: "Tag 7",
    id: "7",
  },
  {
    color: "#FABED4",
    description: "Tag 8",
    id: "8",
  },
  {
    color: "#FFE119",
    description: "Tag 9",
    id: "9",
  },
  {
    color: "#BFEF45",
    description: "Tag 10",
    id: "10",
  },
  {
    color: "#000000",
    description: "Tag 11",
    id: "11",
  },
];

export default trucksColors;
