import * as googleHelpers from "../../../helpers/google-helpers";

export const reverseGeocode = async (position: {
  lat: number;
  lon: number;
}) => {
  // convert
  const legacyResponse = await googleHelpers.reverseGeoCode(position);
  const { place, lat, lon, ...rest } = legacyResponse;
  return { placeName: place, coords: { lat, lon }, ...rest };
};

export type ReverseGeocodeResponse = Awaited<ReturnType<typeof reverseGeocode>>;
