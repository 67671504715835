import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios, { getData } from "../../axios";
import * as tPromise from "io-ts-promise";
import { upsertMultipleValuesInArray } from "../../helpers/reducer-helper";
import { Cargo } from "../../models/cargo";
import { StopApiDto } from "../../models/stop-api-dto";
import { deleteCargo } from "../app/cargo-dialog";
import { CargoState, StateCargo, cargosT } from "./types";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import mapValues from "lodash/mapValues";
import Decimal from "decimal.js-light";
import { createErrorReportingAsyncThunk } from "../helpers";
import { selectCargoView } from "../data/cargo-views/selectors";

const initialState: CargoState = {
  stops: [],
  cargoEntities: {},
  stopEntities: {},
  sortProperty: undefined,
  sortAscending: false,
  hoveredCargoId: undefined,
};

/**
 * @deprecated - Get rid of this
 */
export const getCargos = createErrorReportingAsyncThunk(
  "routes/getCargos",
  async () => {
    const result = await axios
      .get("/api/legacy/cargos")
      .then(getData)
      .then(tPromise.decode(cargosT));
    return result;
  }
);

export interface UpdateSmartMatchArgs {
  cargoId: string;
  smartMatch: boolean;
}
export const updateSmartMatch = createErrorReportingAsyncThunk(
  "routes/UPDATE_SMART_MATCH",
  async (args: UpdateSmartMatchArgs) => {
    await axios.put(
      `/api/cargo/set-smart-match/${args.cargoId}/${args.smartMatch}`
    );
  }
);

export const totalLm = (stops: { lm: string }[]) =>
  stops
    .reduce((acc, cur) => {
      const lm = new Decimal(cur.lm);
      return lm.gt(0) ? acc.add(lm) : acc;
    }, new Decimal(0))
    .toString();

const zero = new Decimal(0);

export const totalWeight = (stops: { weight: string | null }[]) => {
  const result = stops
    .map(({ weight }) => (weight === null ? null : new Decimal(weight)))
    .reduce((acc: Decimal, cur) => acc.add(cur || zero), zero);
  return result.isZero() ? null : result.toString();
};

const createCargos = (
  stops: StopApiDto[]
): {
  cargoEntities: Dictionary<StateCargo>;
  stopEntities: Dictionary<StopApiDto>;
} => {
  const cargoEntities = mapValues(groupBy(stops, "cargo_id"), (stops) => ({
    ...cargoFromStop(stops[0]),
    totalLm: totalLm(stops),
    totalWeight: totalWeight(stops),
    cargos: stops,
  }));
  const stopEntities = keyBy(stops, "id");
  return {
    cargoEntities,
    stopEntities,
  };
};

export const toggleSmartMatch = createErrorReportingAsyncThunk(
  "routes/toggle-smart-match",
  async (cargoId: string, thunkApi) => {
    const cargo = selectCargoView(cargoId)(thunkApi.getState());
    await thunkApi.dispatch(
      updateSmartMatch({
        cargoId,
        smartMatch: !cargo.smartMatch,
      })
    );
  }
);

export interface ReadSmartMatchArgs {
  newSmartMatch: Cargo;
  routeCargos: StopApiDto[];
}

const cargoSlice = createSlice({
  name: "cargo",
  initialState,
  reducers: {
    setCargoSortOrder: (state, action: PayloadAction<boolean>) => {
      state.sortAscending = action.payload;
    },
    setCargoSortProperty: (state, action: PayloadAction<string>) => {
      state.sortProperty = action.payload;
    },
    setHoveredCargoId: (state, action: PayloadAction<string | undefined>) => {
      state.hoveredCargoId = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Get
    builder.addCase(getCargos.fulfilled, (state, action) => {
      const stops = action.payload;
      state.stops = stops;
      const { cargoEntities, stopEntities } = createCargos(stops);
      state.cargoEntities = cargoEntities;
      state.stopEntities = stopEntities;
    });
    builder.addCase(deleteCargo.fulfilled, (state, action) => {
      state.stops = state.stops.filter((x) => x.cargo_id !== action.meta.arg);
    });
    builder.addCase(updateSmartMatch.fulfilled, (state, action) => {
      const { cargoId, smartMatch } = action.meta.arg;
      const updatedCargos = [
        ...state.stops.filter((c) => c.cargo_id === cargoId),
      ];
      updatedCargos.forEach((c) => (c.smart_match = smartMatch));
      state.stops = upsertMultipleValuesInArray(state.stops, updatedCargos);
      const cargo = state.cargoEntities[cargoId];
      if (cargo) {
        cargo.smart_match = smartMatch;
      }
    });
  },
});

export const { setCargoSortOrder, setCargoSortProperty, setHoveredCargoId } =
  cargoSlice.actions;

type Dictionary<T> = { [key: string]: T };

const cargoFromStop = ({
  smart_match,
  route_id,
  cargo_id,
  type,
  cargo_ref,
  created_at,
  delivery_id,
  qualities,
  temperature,
  requirements,
  cargo_info_note,
  client,
  client_contact,
  client_contact_phone,
  client_contact_email,
  trailer_id,
  invoiced,
  unit_id,
  quantity,
}: StopApiDto) => ({
  id: cargo_id,
  route_id: route_id || null,
  qualities,
  requirements,
  cargo_info_note,
  cargo_ref,
  created_at,
  delivery_id,
  client,
  temperature,
  client_contact,
  client_contact_phone,
  client_contact_email,
  type,
  invoiced,
  smart_match,
  trailer_id,
  unit_id,
  quantity,
});

export default cargoSlice.reducer;
