import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TrailerType, TrailerQuality, CargoType } from "../../../helpers/types";
import { Trailer } from "../../data/trailers/types";
import mapValues from "lodash/mapValues";
import * as dataActions from "../../data/trailers";

const prefix = "app/trailer-dialog";

type NullableStringToEmpty<T> = T extends null ? string : T;
type ConvertObject<T> = {
  [Key in keyof T]: NullableStringToEmpty<T[Key]>;
};

// Converts null values in an object to empty strings
export function parseNullable<T extends Record<string, any>>(
  obj: T
): ConvertObject<T> {
  return mapValues(obj, (v) => (v === null ? "" : v) as any);
}

export type FormData = {
  id?: string;
  type: TrailerType | null;
  note: string;
  lm: string;
  weight: string;
  height: string;
  width: string;
  length: string;
  volume: string | null;
  number: string;
  department: string;
  powered: boolean;
  qualities: (string | TrailerQuality | CargoType)[];
  deviationPercentage: string | null;
  fuelType: "DIESEL" | "CNG" | "LNG" | "BEV" | null;
  vehicleType:
    | "EURO 1"
    | "EURO 2"
    | "EURO 3"
    | "EURO 4"
    | "EURO 5"
    | "EURO 6"
    | "Electric"
    | "Hydrogen"
    | "Gas"
    | null;
  kmPerLiter: string | null;
  trackerDeviceId: string | null;
  departmentId: string | null;
  alias: string | null;
  color: string | null;
};

type State = { open: boolean; initialFormData: FormData | null };

const initialState: State = { open: false, initialFormData: null };

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    addTrailer(state, action: PayloadAction<{ powered: boolean }>) {
      const { powered } = action.payload;
      state.open = true;
      state.initialFormData = {
        powered,
        number: "",
        type: null,
        note: "",
        lm: "",
        weight: "",
        height: "",
        width: "",
        length: "",
        volume: "",
        department: "",
        qualities: [],
        deviationPercentage: null,
        fuelType: null,
        vehicleType: null,
        kmPerLiter: null,
        trackerDeviceId: null,
        departmentId: null,
        alias: null,
        color: null,
      };
    },
    editTrailer(state, action: PayloadAction<Trailer>) {
      state.open = true;
      state.initialFormData = {
        ...parseNullable(action.payload),
        fuelType: action.payload.fuelType,
        vehicleType: action.payload.vehicleType,
        deviationPercentage: action.payload.deviationPercentage,
        kmPerLiter: action.payload.kmPerLiter,
        qualities: action.payload.qualities || [],
        departmentId: action.payload.departmentId,
      };
    },
    setTrailer(state, action: PayloadAction<FormData>) {
      state.open = true;
      state.initialFormData = action.payload;
    },
    close(state) {
      state.open = false;
      state.initialFormData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(dataActions.addTrailer.fulfilled, (state) => {
      state.open = false;
      state.initialFormData = null;
    });
    builder.addCase(dataActions.updateTrailer.fulfilled, (state) => {
      state.open = false;
      state.initialFormData = null;
    });
  },
});

export default slice.reducer;
export const { addTrailer, editTrailer, close } = slice.actions;
