import axios, { isAxiosError } from "../../axios";
import { AppDispatch } from "../../redux-store";
import { meT } from "dora-contracts";
import * as tPromise from "io-ts-promise";
import { Me } from "./types";

export const AUTH_STATUS_UNINITIALIZED = "UNINITIALIZED";
export const AUTH_STATUS_AUTHENTICATING = "AUTHENTICATING";
export const AUTH_STATUS_AUTHENTICATED = "AUTHENTICATED";
export const AUTH_STATUS_UNAUTHENTICATED = "UNAUTHENTICATED";
export const AUTH_STATUS_ERROR = "AUTH_ERROR";

type AuthStatus =
  | typeof AUTH_STATUS_ERROR
  | typeof AUTH_STATUS_UNINITIALIZED
  | typeof AUTH_STATUS_AUTHENTICATING
  | typeof AUTH_STATUS_AUTHENTICATED
  | typeof AUTH_STATUS_UNAUTHENTICATED;

export type User = Me;

interface State {
  status: AuthStatus;
  user: null | User;
}

const initialState: State = {
  status: AUTH_STATUS_UNINITIALIZED,
  user: null,
};

const reducer = (state: State = initialState, action: any): State => {
  switch (action.type) {
    case "auth/check/begun":
      if (state.status === AUTH_STATUS_ERROR) {
        return state;
      }
      return {
        status: AUTH_STATUS_AUTHENTICATING,
        user: null,
      };
    case "auth/check/completed": {
      const { user } = action.payload;
      return {
        status: user ? AUTH_STATUS_AUTHENTICATED : AUTH_STATUS_UNAUTHENTICATED,
        user,
      };
    }
    case "auth/check/failed": {
      return {
        status: AUTH_STATUS_ERROR,
        user: null,
      };
    }
    case "auth/loggedOut": {
      return {
        status: AUTH_STATUS_UNAUTHENTICATED,
        user: null,
      };
    }
    default:
      return state;
  }
};

const decodeMe = tPromise.decode(meT);

export const checkAuthState = () => async (dispatch: AppDispatch) => {
  dispatch({ type: "auth/check/begun" });
  try {
    const response = await axios.get("/auth/me");
    const user = await decodeMe(response.data);
    dispatch({ type: "auth/check/completed", payload: { user } });
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 401) {
      return dispatch({
        type: "auth/check/completed",
        payload: { user: null },
      });
    }
    return dispatch({ type: "auth/check/failed" });
  }
};

export const logout = () => async (dispatch: AppDispatch) => {
  await axios.post("/auth/logout");
  dispatch({ type: "auth/loggedOut" });
};

export default reducer;
