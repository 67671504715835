import { useEffect } from "react";
import {
  selectFlespiToken,
  selectTrackerDevices,
} from "../../ducks/app/fleet-management/selectors";
import * as trackerDeviceActions from "../../ducks/data/tracker-devices";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux-store";
import mqtt from "mqtt";
import { useFeature } from "../../hooks";

const useFlespiToken = () => {
  const token = useSelector(selectFlespiToken);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!token) {
      dispatch(trackerDeviceActions.loadFlespiToken());
      return;
    }

    const expireAtTimestamp = Date.parse(token.expireAt);
    const now = Date.now();
    const timeout = setTimeout(() => {
      dispatch(trackerDeviceActions.loadFlespiToken());
    }, expireAtTimestamp - now);

    return () => {
      clearTimeout(timeout);
    };
  }, [dispatch, token]);
  return token;
};

const FlespiConnector = () => {
  const dispatch = useAppDispatch();
  const trackerDevices = useSelector(selectTrackerDevices);
  const token = useFlespiToken();

  const deviceIds = Object.values(trackerDevices).map(
    (trackerDevice) => trackerDevice.flespiId
  );

  const devicesString = deviceIds.join(",");

  useEffect(() => {
    dispatch(trackerDeviceActions.loadTrackerDevices());
  }, [dispatch]);

  useEffect(() => {
    if (!token || !devicesString) {
      return;
    }
    const mqttConnection = mqtt.connect("mqtts://mqtt.flespi.io/", {
      username: token.token,
      password: "",
    });

    const tachoTopics = [
      `tacho.driver.card.status.1`,
      `tacho.driver.card.status.2`,
      `tacho.driver.continuous_driving.duration.1`,
      `tacho.driver.continuous_driving.duration.2`,
      `tacho.driver.cumulative_break.duration.1`,
      `tacho.driver.cumulative_break.duration.2`,
      `tacho.driver.cumulative_driving.duration.1`,
      `tacho.driver.cumulative_driving.duration.2`,
      `tacho.driver.selected.activity.duration.1`,
      `tacho.driver.selected.activity.duration.2`,
      `tacho.driver.work.state.1`,
      `tacho.driver.work.state.2`,
      `tacho.driver.time.state.1`,
      `tacho.driver.time.state.2`,
    ];

    const firstPageSubscribeTopics = [
      "position",
      "engine.ignition.status",
      "movement.status",
    ];

    const mainTopic = `flespi/state/gw/devices/+/telemetry/${firstPageSubscribeTopics.join(",")}`;

    const deviceMessagesTopic = `flespi/message/gw/devices/${devicesString}`;
    // TODO: convert this to a hook
    const tachoSpecificTopic = `flespi/state/gw/devices/+/telemetry/${tachoTopics.join(",")}`;
    mqttConnection.on("connect", function () {
      mqttConnection.subscribe(mainTopic, (err: any) => {
        if (err) {
          console.error(err, "Error subscribing", { topic: mainTopic });
        }
      });
      // TODO: remove when conversion to hook is done
      mqttConnection.subscribe(tachoSpecificTopic, (err: any) => {
        if (err) {
          console.error(err, "Error subscribing", { topic: tachoSpecificTopic });
        }
      });
    });

    mqttConnection.on("message", async (topic: any, rawMessage: any) => {
      const message = JSON.parse(rawMessage.toString());
      console.log("Device message", { topic, message });
      if (topic === deviceMessagesTopic) {
        // THIS IF FOR CURRENT BEHAVIOUR
        // (processing messages as they arrive)
        // dispatch(trackerDeviceActions.loadDataFromTrackerDevice(message));
      } else {
        // This is for new behaviour, process telemetry, that reads last
        // known position when connecting.
        dispatch(
          trackerDeviceActions.flespiTelemetryMessageReceived({
            topic,
            message,
          })
        );
      }
    });

    return () => {
      mqttConnection.end();
    };
  }, [dispatch, token, devicesString]);

  return null;
};

const FeatureToggledFlespiConnector = () => {
  const isLiveViewEnabled = useFeature("live-view");
  return isLiveViewEnabled ? <FlespiConnector /> : null;
};

export default FeatureToggledFlespiConnector;
