import {
  Schedule,
  CreateUpdateScheduleReqBody,
  ScheduleWithData,
} from "./types";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { createErrorReportingAsyncThunk } from "../../helpers";
import axios, { getData } from "../../../axios";
import * as tPromise from "io-ts-promise";
import * as t from "io-ts";
import {
  automaticCargoCreationSchedulesT,
  automaticCargoCreationScheduleRequestBodyT,
  createScheduleCargoTemplateTypes,
} from "dora-contracts";

const { automaticCargoCreationScheduleWithDataT } =
  createScheduleCargoTemplateTypes({
    decimal: t.string,
    dateTime: t.string,
  });

const prefix = "data/schedules";

type InitialState = {
  schedules: Schedule[];
  schedule: ScheduleWithData | null;
};

const initialState: InitialState = {
  schedules: [],
  schedule: null,
};

export const loadSchedules = createErrorReportingAsyncThunk(
  `${prefix}/load-all`,
  async () => {
    return axios
      .get("/api/schedules")
      .then(getData)
      .then(tPromise.decode(automaticCargoCreationSchedulesT));
  }
);

export const loadSchedule = createErrorReportingAsyncThunk(
  `${prefix}/load`,
  async (id: string) => {
    return axios
      .get(`/api/schedules/${id}`)
      .then(getData)
      .then(tPromise.decode(automaticCargoCreationScheduleWithDataT));
  }
);

export const SCHEDULE_DUPLICATE_NAME_ERROR = "SCHEDULE_DUPLICATE_NAME_ERROR";

export const createSchedule = createAsyncThunk(
  `${prefix}/create`,
  async (data: CreateUpdateScheduleReqBody, thunkAPI) => {
    return axios
      .post(
        "/api/schedules",
        automaticCargoCreationScheduleRequestBodyT.encode(data)
      )
      .then(() => {
        thunkAPI.dispatch(loadSchedules() as any);
      })
      .catch((e) => {
        if (e.response?.status === 409) {
          throw new Error(SCHEDULE_DUPLICATE_NAME_ERROR);
        }
      });
  }
);

export const updateSchedule = createAsyncThunk(
  `${prefix}/update`,
  async (
    { id, data }: { id: string; data: CreateUpdateScheduleReqBody },
    thunkAPI
  ) => {
    return axios
      .put(
        `/api/schedules/${id}`,
        automaticCargoCreationScheduleRequestBodyT.encode(data)
      )
      .then(() => {
        thunkAPI.dispatch(loadSchedule(id) as any);
      })
      .catch((e) => {
        if (e.response?.status === 409) {
          throw new Error(SCHEDULE_DUPLICATE_NAME_ERROR);
        }
      });
  }
);

export const deleteSchedule = createAsyncThunk(
  `${prefix}/delete`,
  async (id: string, thunkAPI) => {
    return axios.delete(`/api/schedules/${id}`).then(() => {
      thunkAPI.dispatch(loadSchedules() as any);
    });
  }
);

export const addRouteTemplateToSchedule = createErrorReportingAsyncThunk(
  `${prefix}/add-route-template`,
  async (id: string, thunkAPI) => {
    return axios.post(`/api/schedules/${id}/route-templates`).then(() => {
      thunkAPI.dispatch(loadSchedule(id) as any);
    });
  }
);

export const removeRouteTemplateFromSchedule = createErrorReportingAsyncThunk(
  `${prefix}/remove-route-template`,
  async (
    {
      scheduleId,
      routeTemplateId,
    }: { scheduleId: string; routeTemplateId: string },
    thunkAPI
  ) => {
    return axios
      .delete(`/api/schedules/${scheduleId}/route-templates/${routeTemplateId}`)
      .then(() => {
        thunkAPI.dispatch(loadSchedule(scheduleId) as any);
      });
  }
);

export const updateRouteTemplateTrailer = createErrorReportingAsyncThunk(
  `${prefix}/update-route-template-trailer`,
  async (
    {
      scheduleId,
      routeTemplateId,
      trailerId,
    }: { scheduleId: string; routeTemplateId: string; trailerId: string },
    thunkAPI
  ) => {
    return axios
      .post(
        `/api/schedules/${scheduleId}/route-templates/${routeTemplateId}/trailer`,
        { trailerId }
      )
      .then(() => {
        thunkAPI.dispatch(loadSchedule(scheduleId) as any);
      });
  }
);

export const removeRouteTemplateTrailer = createErrorReportingAsyncThunk(
  `${prefix}/remove-route-template-trailer`,
  async (
    {
      scheduleId,
      routeTemplateId,
    }: { scheduleId: string; routeTemplateId: string },
    thunkAPI
  ) => {
    return axios
      .delete(
        `/api/schedules/${scheduleId}/route-templates/${routeTemplateId}/trailer`
      )
      .then(() => {
        thunkAPI.dispatch(loadSchedule(scheduleId) as any);
      });
  }
);

export const addDriverToRouteTemplate = createErrorReportingAsyncThunk(
  `${prefix}/add-driver-to-route-template`,
  async (
    {
      scheduleId,
      routeTemplateId,
      driverId,
    }: { scheduleId: string; routeTemplateId: string; driverId: string },
    thunkAPI
  ) => {
    return axios
      .post(
        `/api/schedules/${scheduleId}/route-templates/${routeTemplateId}/drivers`,
        { driverId }
      )
      .then(() => {
        thunkAPI.dispatch(loadSchedule(scheduleId) as any);
      });
  }
);

export const removeDriverFromRouteTemplate = createErrorReportingAsyncThunk(
  `${prefix}/remove-driver-from-route-template`,
  async (
    {
      scheduleId,
      routeTemplateId,
      driverId,
    }: { scheduleId: string; routeTemplateId: string; driverId: string },
    thunkAPI
  ) => {
    return axios
      .delete(
        `/api/schedules/${scheduleId}/route-templates/${routeTemplateId}/drivers`,
        { data: { driverId } }
      )
      .then(() => {
        thunkAPI.dispatch(loadSchedule(scheduleId) as any);
      });
  }
);

export const updateRouteTemplateName = createErrorReportingAsyncThunk(
  `${prefix}/update-route-template-name`,
  async (
    {
      scheduleId,
      routeTemplateId,
      routeName,
    }: { scheduleId: string; routeTemplateId: string; routeName: string },
    thunkAPI
  ) => {
    return axios
      .put(`/api/schedules/${scheduleId}/route-templates/${routeTemplateId}`, {
        routeName,
      })
      .then(() => {
        thunkAPI.dispatch(loadSchedule(scheduleId) as any);
      });
  }
);

const schedulesSlice = createSlice({
  name: "schedules",
  initialState,
  reducers: {
    unloadScheduleFromState: (state) => {
      state.schedule = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadSchedules.fulfilled, (state, action) => {
      state.schedules = action.payload;
    });
    builder.addCase(loadSchedule.fulfilled, (state, action) => {
      state.schedule = action.payload;
    });
  },
});

export default schedulesSlice.reducer;

export const scheduleActions = schedulesSlice.actions;
