import { RootState } from "../../../redux-store";
import Decimal from "decimal.js-light";

export const selectDelivery = (deliveryId: string) => (state: RootState) =>
  state.data.deliveries.entities[deliveryId];

export const selectRevision = (deliveryId: string) => (state: RootState) =>
  selectDelivery(deliveryId)(state).revision;

export const selectExpenses = (deliveryId: string) => (state: RootState) =>
  selectDelivery(deliveryId)(state).expenses;

export const selectInvoiceLines = (deliveryId: string) => (state: RootState) =>
  selectDelivery(deliveryId)(state).lines;

export const selectInvoicable = (deliveryId: string) => (state: RootState) =>
  selectDelivery(deliveryId)(state).invoicable;

export const selectGrossMargin = (deliveryId: string) => (state: RootState) => {
  const delivery = selectDelivery(deliveryId)(state);
  if (!delivery) {
    return null;
  }
  const invoicesTotal = delivery.lines.reduce(
    (acc, l) => acc.add(new Decimal(l.amount).mul(new Decimal(l.quantity))),
    new Decimal(0)
  );
  const expensesTotal = delivery.expenses.reduce(
    (acc, e) => acc.add(new Decimal(e.amount)),
    new Decimal(0)
  );
  return invoicesTotal.sub(expensesTotal);
};
