import { DateTime } from "luxon";
import { CreateEditShipmentStop } from "../CreateEditShipmentData";

export const createEmptyStop = (date: DateTime): CreateEditShipmentStop => ({
  loadmeters: 0,
  weight: "",
  colli: "",
  length: "",
  width: "",
  height: "",
  time: "",
  cubicMeters: "",
  place: null,
  date,
  openingDate: null,
  ref: "",
  tag: "",
  contact: "",
  contactPhone: "",
  fix: false,
  pieces: [],
  openingTime: null,
  CMR_TAG_LOCKED: false,
});
