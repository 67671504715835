import classnames from "classnames";
import "./DoraLinkButton.scss";
import { ReactNode } from "react";
import * as React from "react";

type HTMLButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement>;

type DoraLinkButtonProps = {
  className?: string;
  onClick?: () => void;
  disabled?: boolean;
  children: ReactNode;
  link?: boolean; // temporary, when the <DoraLink> will work properly we will switch to that
} & HTMLButtonProps;

type DoraLinkProps = {
  className?: string;
  url: string;
  newTab: boolean;
  children: ReactNode;
};

const DoraLinkButton = (props: DoraLinkButtonProps) => {
  const { className, children, onClick, disabled, ...rest } = props;
  const classes = classnames("dora-link-button", className);

  return (
    <button className={classes} onClick={onClick} disabled={disabled} {...rest}>
      {children}
    </button>
  );
};

export const DoraLink = (props: DoraLinkProps) => {
  const { className, url, newTab, children } = props;
  const classes = classnames("dora-link-button", "dora-link", className);
  const newTabProps = newTab ? { target: "_blank", rel: "noreferrer" } : {};

  return (
    <a className={classes} href={url} {...newTabProps}>
      {children}
    </a>
  );
};

export default DoraLinkButton;
