import {
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  DialogActions,
  Autocomplete,
} from "@mui/material";
import { MuiTextField } from "../../../formik";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Form, Field, Formik } from "formik";
import * as actions from "../../../../ducks/data/deliveries";
import { useAppDispatch, useSelector } from "../../../../redux-store";
import { useL10n } from "../../../../l10n";
import { selectExpenses } from "../../../../ducks/data/deliveries/selectors";
import { useMemo } from "react";
import { selectExpenseAccounts } from "../../../../ducks/data/expenseAccounts/selectors";
import { DateTime } from "luxon";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { notify } from "../../../../ducks/notifications";

const EditExpenseLineModal = (props: {
  onClose: () => void;
  id: { deliveryId: string; expenseId: string };
}) => {
  const { id, onClose } = props;
  const { t } = useTranslation(["translation", "expenses", "components"]);
  const l10n = useL10n();
  const dispatch = useAppDispatch();
  const expenses = useSelector(selectExpenses(id.deliveryId));
  const expense = useMemo(
    () => expenses.find((x) => x.id === id.expenseId) || null,
    [expenses, id.expenseId]
  );
  const expenseAccounts = useSelector(selectExpenseAccounts);
  const options = expenseAccounts.map((x) => ({
    id: x.id,
    label: `${x.accountNumber} - ${x.name}`,
  }));
  const validationSchema = useMemo(
    () =>
      yup.object({
        amount: yup
          .number()
          .label("Beløb")
          .typeError("${path} skal være et tal") // eslint-disable-line
          .transform((_, strValue) => l10n.parseNumber(strValue)),
      }),
    [l10n]
  );
  const defaultCurrency = "DKK";
  return (
    <LocalizationProvider
      dateAdapter={AdapterLuxon}
      adapterLocale={l10n.locale}
      localeText={{
        openDatePickerDialogue: (date, utils) =>
          date && utils.isValid(date)
            ? `${t("expenses:expenseLine.chosenDateIs")} ${utils.format(
                utils.date(date)!,
                "fullDate"
              )}`
            : `${t("expenses:expenseLine.selectDate")}`,
      }}
    >
      <Dialog
        open={true}
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
          },
        }}
      >
        <Formik
          initialValues={{
            accountId: expense?.accountId || null,
            currency: expense?.currency || defaultCurrency,
            amount:
              l10n.invariantToLocalizedNumberString(expense?.amount) || "",
            date: expense ? DateTime.fromISO(expense.date) : DateTime.now(),
          }}
          validationSchema={validationSchema}
          onSubmit={async (formValues) => {
            const accountId = formValues.accountId;
            if (!accountId) {
              return; // Mostly to keep typescript happy. Field is required
            }
            try {
              if (expense) {
                await dispatch(
                  actions.updateExpense({
                    ...formValues,
                    ...id,
                    accountId,
                    amount: l10n.parseNumber(formValues.amount).toString(),
                    date: formValues.date.toISODate(),
                  })
                )?.unwrap();
              } else {
                await dispatch(
                  actions.addExpense({
                    ...formValues,
                    ...id,
                    accountId,
                    amount: l10n.parseNumber(formValues.amount).toString(),
                    date: formValues.date.toISODate(),
                  })
                )?.unwrap();
              }
              onClose();
            } catch {
              dispatch(
                notify({
                  message: t("translation:technicalError"),
                  type: "error",
                })
              );
            }
          }}
        >
          {({ isSubmitting, values, ...rest }) => {
            return (
              <Form>
                <DialogTitle>
                  {expense
                    ? t("expenses:expenseLine.editExpense")
                    : t("expenses:expenseLine.newExpense")}
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item xs={8}>
                      <Autocomplete
                        options={options}
                        value={
                          options.find((x) => x.id === values.accountId) || null
                        }
                        onChange={(e, v) =>
                          rest.setFieldValue("accountId", v && v.id)
                        }
                        renderInput={(params) => (
                          <TextField
                            required
                            autoFocus
                            label={t("expenses:expenseLine.account")}
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <DatePicker
                        // mask="____/__/__"
                        label="Dato"
                        value={values.date}
                        onChange={(date) => {
                          rest.setFieldValue("date", date);
                        }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Field
                        component={MuiTextField}
                        required
                        label={t("expenses:expenseLine.amount")}
                        name="amount"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Autocomplete
                        options={["DKK", "EUR"]}
                        value={values.currency}
                        onChange={(_e, v) => {
                          rest.setFieldValue("currency", v);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={t("expenses:expenseLine.currency")}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose}>
                    {t("components:buttonLabels.cancel")}
                  </Button>
                  <Button type="submit">
                    {t("components:buttonLabels.save")}
                  </Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </LocalizationProvider>
  );
};

export default EditExpenseLineModal;
