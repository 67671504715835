import Card from "@mui/material/Card";
import React, { FC } from "react";

interface Props {
  style?: any;
  showBoxShadow?: boolean;
  children: React.ReactNode;
}

export const NoShadowCard: FC<Props> = React.memo((props) => {
  return (
    <Card
      style={props.style}
      elevation={props.showBoxShadow ? 4 : 0}
      sx={{
        marginBottom: props.showBoxShadow ? 1 : 0,
        border: "0.0625rem solid rgba(0, 0, 0, 0.08)",
        borderRadius: 0,
        "& .MuiCardHeader-root": {
          padding: 0.5, // 0.5
          paddingBottom: 0,
        },
        "& .MuiCardContent-root": {
          paddingLeft: 1,
          paddingRight: 1,
          paddingBottom: "4px !important",
        },
        "& .MuiCardHeader-action": {
          marginRight: 0,
          marginTop: 0,
        },
      }}
    >
      {props.children}
    </Card>
  );
});
