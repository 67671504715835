import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../redux-store";

export const selectDispatchers = createSelector(
  (state: RootState) => state.data.dispatchers.ids,
  (state: RootState) => state.data.dispatchers.entities,
  (ids, entities) => ids.map((id) => entities[id])
);

export const selectDispatcher = (id: string | null) => (state: RootState) =>
  id ? state.data.dispatchers.entities[id] : null;
