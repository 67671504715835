import "./DoraTextArea.scss";

type DoraTextAreaProps = {
  id?: string;
  placeholder?: string;
  register?: any;
};

type UFTextAreaProps = {
  register: any;
};

const DoraTextArea = (props: DoraTextAreaProps) => {
  const { placeholder, register, id } = props;

  return (
    <textarea
      placeholder={placeholder}
      className="dora-text-area"
      {...(id && { id })}
      {...register}
    />
  );
};

const UFDoraTextArea = (props: DoraTextAreaProps & UFTextAreaProps) => {
  return <DoraTextArea {...props} />;
};

export { DoraTextArea, UFDoraTextArea };
