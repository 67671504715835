import axios from "./axios";

export const performSmartmatchForRoute = async (route_id: number) => {
  await axios.post(`/api/perform-smartmatch-for-route/${route_id}`);
};

export const getEstimatedDistanceForCoords = async (
  coords: { lat: number; lon: number }[]
) => {
  return axios.post(`/api/get-estimated-distance-for-coords`, { coords });
};
