import axios, { getData } from "../../../axios";
import { createSlice } from "@reduxjs/toolkit";
import { createErrorReportingAsyncThunk } from "../../helpers";
import { AppDispatch } from "../../../redux-store";
import { notifyL } from "../../notifications";
import { isAxiosError } from "axios";
import { departmentT, departmentsT } from "dora-contracts";
import * as t from "io-ts";
import * as tPromise from "io-ts-promise";

export type Department = t.TypeOf<typeof departmentT>;

interface State {
  departments: Department[];
  allDepartments: Department[];
}

const initialState: State = {
  departments: [],
  allDepartments: [],
};

export const loadDepartments = createErrorReportingAsyncThunk(
  "data/departments/loadDepartments",
  async () => {
    return axios
      .get(`/api/e-conomic/departments`)
      .then(getData)
      .then(tPromise.decode(departmentsT));
  }
);

export const loadAllDepartments = createErrorReportingAsyncThunk(
  "data/departments/loadAllDepartments",
  async () => {
    return axios
      .get(`/api/e-conomic/departments?includeDeleted=true`)
      .then(getData)
      .then(tPromise.decode(departmentsT));
  }
);

export const syncDepartments = createErrorReportingAsyncThunk(
  "data/departments/syncDepartments",
  async () => {
    try {
      await axios.post(`/api/e-conomic/departments/sync`);
    } catch (e) {
      if (isAxiosError(e) && e.response?.status === 501) {
        return;
      }
      throw e;
    }
  }
);

export const syncDepartmentsManual = () => async (dispatch: AppDispatch) => {
  return dispatch(syncDepartments()).then(() => {
    dispatch(
      notifyL({
        namespace: "notifications",
        key: "departmentsSynced",
        type: "success",
      })
    );
  });
};

const slice = createSlice({
  name: "data/departments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadDepartments.fulfilled, (state, action) => {
      state.departments = action.payload;
    });
    builder.addCase(loadAllDepartments.fulfilled, (state, action) => {
      state.allDepartments = action.payload;
    });
  },
});

export default slice.reducer;
