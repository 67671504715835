import { useMemo } from "react";
import classnames from "classnames";
import { HTMLAttributes, useState } from "react";
import { useTranslation } from "react-i18next";
import { Autocomplete, MenuItem, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import "./DoraSelect.scss";
import sortByStartsWith from "../../helpers/sort-by-starts-with";
import { useElementId } from "../../hooks";
import { useSelector } from "react-redux";
import { selectIsLoading } from "../../ducks/data/clients/selectors";

export type DoraSelectOption = {
  value: string;
  label: string;
  unselectable?: boolean;
};

type SelectProps = {
  id?: string;
  icon?: IconProp;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  fullWidth?: boolean;
  label?: string;
  value?: string;
  options: DoraCustomersSelectOption[]; // TODO: see how to make it more generic, was DoraSelectOption[]
  onChange?: (e: any) => void;
  error?: boolean;
  freeSolo?: boolean;
  autofocus?: boolean;
  autoselect?: boolean;
  onInputChange?: (e: any, v: any) => void;
  customRenderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: DoraCustomersSelectOption
  ) => JSX.Element;
  customGetOptionLabel?: (option: DoraCustomersSelectOption) => string;
  renderOptionsInBatchesNumber?: number;
  style?: any;

  // useForms inject
  controlField?: any;
};

const setInputWidth = (text: string, hasIcon?: boolean) => {
  return 65 + text.length * 9 + (hasIcon ? 20 : 0);
};

type DoraCustomersSelectOption = DoraSelectOption & {
  labelSecondary: string;
};

const DoraSelect = (props: SelectProps) => {
  const {
    autofocus,
    autoselect,
    id,
    icon,
    options,
    placeholder,
    fullWidth,
    className,
    freeSolo,
    disabled,
    controlField,
    onInputChange,
    onChange,
    error,
    value,
    customRenderOption,
    customGetOptionLabel,
    renderOptionsInBatchesNumber,
    label,
  } = props;
  const { t } = useTranslation("components");
  const loading = useSelector(selectIsLoading);
  const [inputValue, setInputValue] = useState("");
  const [page, setPage] = useState(1);

  const [length, setLength] = useState(
    placeholder ? setInputWidth(placeholder) : 120
  );

  const wrapperClasses = classnames(
    "dora-select__wrapper",
    disabled ? "dora-select__wrapper--disabled" : null,
    error ? "dora-select__wrapper--error" : null,
    className
  );
  const staticId = useElementId();
  const customId = id || staticId;

  const onOptionSelected = (v: DoraSelectOption | null) => {
    if (onChange) {
      onChange(v?.value);
    }
    setLength(setInputWidth(v?.label || "", !!icon));
  };

  const onAdornmentClick = () => {
    const input = document.getElementById(customId);
    input?.parentElement?.click();
  };

  const valueProp = controlField
    ? {
        value:
          options.find((option) => option.value === controlField.value) || null,
      }
    : {
        inputValue:
          options.find((option) => option.value === value)?.label || value,
      };

  const defaultGetOptionLabel = (option: DoraSelectOption) =>
    option?.label || "";
  const textFieldId = useElementId();

  const defaultRenderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: DoraSelectOption
  ) => {
    return (
      <MenuItem {...props} key={option.value} disabled={option.unselectable}>
        {option.label}
      </MenuItem>
    );
  };

  const handleInputChange = (e: any, newInputValue: string) => {
    setInputValue(newInputValue);
    setPage(1);
  };

  const onMenuScroll = (e: any) => {
    if (!renderOptionsInBatchesNumber) {
      return;
    }
    const listbox = e.target;
    if (listbox.scrollTop + listbox.clientHeight === listbox.scrollHeight) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const declarativeFilteredOptions = useMemo(() => {
    const filterText = inputValue.toLowerCase();
    if (inputValue) {
      return options
        .filter(
          (o) =>
            o.label.toLowerCase().includes(filterText) ||
            o.labelSecondary?.toLowerCase().includes(filterText)
        )
        .sort(sortByStartsWith(filterText, "label", "labelSecondary"));
    }
    return options;
  }, [inputValue, options]);

  const declarativeRenderedOptions = useMemo(
    () =>
      renderOptionsInBatchesNumber
        ? declarativeFilteredOptions.slice(
            0,
            page * renderOptionsInBatchesNumber
          )
        : declarativeFilteredOptions,
    [declarativeFilteredOptions, page, renderOptionsInBatchesNumber]
  );

  const actualOptions = useMemo(() => {
    if (loading) {
      return [
        {
          value: "",
          label: t("optionLabels.loading"),
          unselectable: true,
        },
      ];
    }
    return declarativeRenderedOptions.length
      ? declarativeRenderedOptions
      : [
          {
            value: "",
            label: t("optionLabels.noOptions"),
            unselectable: true,
          },
        ];
  }, [declarativeRenderedOptions, t, loading]);

  return (
    <FormControl
      size="small"
      className={wrapperClasses}
      style={{ ...props.style }}
    >
      <Autocomplete
        fullWidth
        autoSelect={autoselect}
        sx={{
          margin: 0,
          background: disabled ? "var(--gray-100)" : "white",
          "& input::placeholder": {
            color: "var(--gray-800)",
            fontWeight: 400,
          },
          "& fieldset": { borderColor: "var(--gray-300)" },
          "& .MuiOutlinedInput-root:not(.Mui-focused):hover fieldset": {
            borderColor: "var(--gray-400) !important",
          },
          ...(!disabled && { "&:hover": { borderColor: "var(--gray-300)" } }),
          "& .MuiFormControl-root.MuiFormControl-marginDense.MuiFormControl-fullWidth.MuiTextField-root":
            {
              margin: "0 !important",
              ...(fullWidth && { width: "100% !important" }),
            },
          "& .MuiFormControl-root": fullWidth
            ? { width: "100% !important" }
            : {},
          "& .MuiOutlinedInput-root": { paddingRight: "25px !important" },
        }}
        options={actualOptions}
        ListboxProps={{
          onScroll: onMenuScroll,
        }}
        componentsProps={{
          popper: {
            sx: {
              minWidth: "fit-content",
            },
          },
        }}
        filterOptions={(vals: DoraCustomersSelectOption[], { inputValue }) => {
          return vals.filter(
            (v) =>
              v.label.toLowerCase().includes(inputValue.toLowerCase()) ||
              v.labelSecondary?.toLowerCase().includes(inputValue.toLowerCase())
          );
        }}
        getOptionLabel={customGetOptionLabel || defaultGetOptionLabel}
        renderOption={customRenderOption || defaultRenderOption}
        renderInput={(params) => (
          <TextField
            {...params}
            id={textFieldId}
            placeholder={placeholder}
            label={label}
            autoFocus={autofocus}
            sx={
              fullWidth
                ? {}
                : {
                    width: `${length}px !important`,
                    minWidth: `${length}px !important`,
                  }
            }
            InputProps={{
              ...params.InputProps,
              sx: { "& .MuiInputBase-input": { textIndent: icon ? 20 : 0 } },
              startAdornment: icon ? (
                <div className="dora-select__icon" onClick={onAdornmentClick}>
                  <FontAwesomeIcon icon={icon} />
                </div>
              ) : null,
              endAdornment: (
                <div className="dora-select__caret" onClick={onAdornmentClick}>
                  <FontAwesomeIcon icon={faAngleDown} />
                </div>
              ),
            }}
          />
        )}
        disabled={disabled}
        id={customId}
        /*{...(id && { id })}*/
        {...(freeSolo && { freeSolo })}
        {...controlField}
        {...valueProp}
        {...(onInputChange && { onInputChange })}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={
          controlField
            ? (_, v: DoraSelectOption | null) => {
                controlField.onChange(v?.value || null);
                onOptionSelected(v);
              }
            : (_, v) => onOptionSelected(v)
        }
      />
    </FormControl>
  );
};

export { DoraSelect };
