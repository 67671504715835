import { combineReducers } from "redux";
import auth from "./ducks/auth";
import notifications from "./ducks/notifications";
import app from "./ducks/app";
import data from "./ducks/data";
import cargo from "./ducks/cargo";
import routes from "./ducks/routes";
import features from "./ducks/features";
import smartMatch from "./ducks/smart-match";
import { AnyAction } from "@reduxjs/toolkit";

const appReducer = combineReducers({
  routes,
  cargo,
  smartMatch,
  auth,
  notifications,
  app,
  data,
  features,
});

export const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
  if (action.type === "RESET_STORE") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
