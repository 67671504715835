import { ModelBase } from "../models/model-base";

export function upsertValueInArray<T extends ModelBase>(
  old: T[],
  updated: T
): typeof old {
  const index = old.findIndex((o) => o.id === updated.id);
  return upsert(old, updated, index);
}

export function upsertValueInArrayCustomId<T extends ModelBase>(
  old: T[],
  updated: T,
  idFn: (t: T) => number
) {
  const index = old.findIndex((o) => idFn(o) === idFn(updated));
  return upsert(old, updated, index);
}

export function upsertMultipleValuesInArray<T extends ModelBase>(
  old: T[],
  updates: T[]
) {
  if (!updates) return old;

  updates.forEach((update) => {
    old = upsertValueInArray(old, update);
  });
  return old;
}

export function upsertMultipleValuesInArrayCustomId<T extends ModelBase>(
  old: T[],
  updates: T[],
  idFn: (t: T) => number
) {
  updates.forEach((update) => {
    old = upsertValueInArrayCustomId(old, update, idFn);
  });
  if (!updates) return old;
  return old;
}

export function upsert<T extends ModelBase>(
  old: T[],
  updated: T,
  index: number
) {
  // Need to be a clone, since redux have problems when mutating the state
  let clonedOld = [...old];

  if (index > -1) {
    clonedOld[index] = updated;
  } else {
    clonedOld.push(updated);
  }

  return clonedOld;
}

function removeValueInArray<T extends ModelBase>(
  old: T[],
  updated: T
): typeof old {
  const index = old.findIndex((o) => o.id === updated.id);
  return remove(old, index);
}

export function removeMultipleValuesInArray<T extends ModelBase>(
  old: T[],
  updates: T[]
): typeof old {
  updates.forEach((update) => {
    old = removeValueInArray(old, update);
  });
  return old;
}

function remove<T extends ModelBase>(old: T[], index: number) {
  // Need to be a clone, since redux have problems when mutating the state
  let clonedOld = [...old];

  if (index > -1) {
    clonedOld.splice(index, 1);
  } else {
    return old;
  }

  return clonedOld;
}
