import { createSlice } from "@reduxjs/toolkit";

type State = { collapseRoutes: boolean };

const initialState: State = { collapseRoutes: false };

const slice = createSlice({
  name: "collapseRoutes",
  initialState,
  reducers: {
    setCollapseRoutes: (state) => {
      state.collapseRoutes = !state.collapseRoutes;
    },
  },
});
export default slice.reducer;
export const { setCollapseRoutes } = slice.actions;
