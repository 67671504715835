import { MutableRefObject, useEffect, useMemo } from "react";
import { createRoot } from "react-dom/client";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const pastStopContainer = document.createElement("div");
pastStopContainer.setAttribute("id", "past-stop");
document.body.appendChild(pastStopContainer);
const domNode = document.getElementById("past-stop");
const root = createRoot(domNode!);
root.render(
  <div
    className="flex items-center justify-center"
    style={{
      borderRadius: 50,
      width: 15,
      height: 15,
      border: "3px solid black",
      background: "white",
    }}
  ></div>
);
document.body.removeChild(pastStopContainer);
pastStopContainer.removeAttribute("id");

const futureStopContainer = document.createElement("div");
futureStopContainer.setAttribute("id", "future-stop");
document.body.appendChild(futureStopContainer);
const domNodeFuture = document.getElementById("future-stop");
const rootFuture = createRoot(domNodeFuture!);
rootFuture.render(
  <div style={{ background: "black", width: 26, height: 26, borderRadius: 50 }}>
    <LocationOnIcon
      style={{
        fontSize: 20,
        color: "white",
        position: "relative",
        bottom: 3,
        left: 3,
      }}
    />
  </div>
);
document.body.removeChild(futureStopContainer);
futureStopContainer.removeAttribute("id");

const HereMapsStopMarker = ({
  lat,
  lng,
  mapRef,
  container,
}: {
  lat: number;
  lng: number;
  mapRef: MutableRefObject<H.Map | undefined>;
  container: any;
}) => {
  const map = mapRef.current;
  const marker = useMemo(
    () =>
      new H.map.DomMarker(
        { lat: 0, lng: 0 },
        {
          icon: new H.map.DomIcon(container),
          data: "",
          visibility: false,
        }
      ),
    [container]
  );
  useEffect(() => {
    if (!lat || !lng) {
      return;
    }
    marker.setVisibility(true);
    marker.setGeometry({ lat, lng });
  }, [lat, lng, marker]);
  useEffect(() => {
    if (!map) {
      return;
    }
    map.addObject(marker);
    return () => {
      map.removeObject(marker);
    };
  }, [map, marker]);
  return null;
};

export const HereMapsPastStopContainer = (props: {
  lat: number;
  lng: number;
  mapRef: MutableRefObject<H.Map | undefined>;
}) => {
  return (
    <HereMapsStopMarker
      lat={props.lat}
      lng={props.lng}
      mapRef={props.mapRef}
      container={pastStopContainer}
    />
  );
};

export const HereMapsFutureStopContainer = (props: {
  lat: number;
  lng: number;
  mapRef: MutableRefObject<H.Map | undefined>;
}) => {
  return (
    <HereMapsStopMarker
      lat={props.lat}
      lng={props.lng}
      mapRef={props.mapRef}
      container={futureStopContainer}
    />
  );
};
