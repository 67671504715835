import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import {
  reportError,
  reportWarning,
  formatError,
} from "../helpers/error-helper";
import * as notificationActions from "./notifications";
import { createAppAsyncThunk } from "../redux-helpers";
import { AppDispatch, AppThunkAction } from "../redux-store";
import { isAxiosError } from "axios";
import { selectFeature } from "./features/selectors";

type CreateAsyncThunk = typeof createAppAsyncThunk;

export const defaultErrorAction = () =>
  notificationActions.notifyL({
    type: "error",
    namespace: "notifications",
    key: "unexpectedError",
  });

const isNetworkError = (e: unknown) => {
  if (
    isAxiosError(e) ||
    (e &&
      typeof e === "object" &&
      "message" in e &&
      typeof e.message === "string")
  )
    return e.message === "Network Error" || e.message === "Request aborted";
  else return false;
};

// Wraps redux's createAsyncThink in a version that logs errors
export const createErrorReportingAsyncThunk = ((
  name: any,
  action: any,
  options?: any,
) =>
  createAsyncThunk(
    name,
    async (...args) => {
      try {
        return await action(...args);
      } catch (e) {
        const { dispatch, getState } = args[1];

        const showNetworkError = selectFeature("show-network-error-warning")(
          getState() as any,
        );
        const err = formatError(e);
        if (isNetworkError(e)) {
          reportWarning({
            type: "Async action failed",
            args: args.length && args[0],
            actionType: name,
            err,
          });
          if (showNetworkError) {
            dispatch(notificationActions.showNetworkErrorMessage());
          } else {
            if (!options?.skipNotification) {
              dispatch(defaultErrorAction());
            }
          }
        } else {
          reportError({
            type: "Async action failed",
            args: args.length && args[0],
            actionType: name,
            err,
          });
          if (!options?.skipNotification) {
            dispatch(defaultErrorAction());
          }
        }
        throw e;
      }
    },
    options,
  )) as any as CreateAsyncThunk;

type GetAsyncThunkReturn<T> =
  T extends AsyncThunk<infer U, any, any> ? U : never;
type GetAsyncThunkArg<T> = T extends AsyncThunk<any, infer U, any> ? U : never;

export const unwrap =
  <T extends AsyncThunk<any, any, any>>(asyncThunk: T) =>
  (arg: GetAsyncThunkArg<T>): AppThunkAction<Promise<GetAsyncThunkReturn<T>>> =>
  (dispatch: AppDispatch) =>
    dispatch(asyncThunk(arg)).unwrap();
