import { CSSProperties } from "react";
import classnames from "classnames";
import "./new-dora-inputs.scss";
import "./DoraCheckbox.scss";

type DoraCheckboxProps = {
  id?: string;
  className?: string;
  style?: CSSProperties;
  onChange?: (val: any) => void;
  checked?: boolean;
  disabled?: boolean;
  ["aria-label"]?: string;

  register?: any;
};

type UseFormsInputProps = {
  register: any;
};

const DoraCheckbox = (props: DoraCheckboxProps) => {
  const { id, register, style, checked, onChange, disabled, className } = props;
  const wrapperClasses = classnames("dora-checkbox__wrapper", className);
  const classes = classnames("dora-checkbox", {
    "dora-checkbox--checked": checked,
    "dora-checkbox--disabled": disabled,
  });

  const onChangeProp = !register
    ? {
        onChange: (e: any) => onChange && onChange(e.target.value),
      }
    : {};
  const checkedProp = !register ? { checked } : {};

  return (
    <div className={wrapperClasses} style={style}>
      <input
        {...(id && { id })}
        {...register}
        {...checkedProp}
        {...onChangeProp}
        disabled={disabled}
        type="checkbox"
        className={classes}
      />
      <div className="dora-checkbox__svg-wrapper">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="14"
          viewBox="0 0 12 12"
          fill="none"
        >
          <path
            d="M10 3L4.5 8.5L2 6"
            stroke="#3E68A3"
            strokeWidth="1.6666"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

const UFDoraCheckbox = (props: DoraCheckboxProps & UseFormsInputProps) => {
  return <DoraCheckbox {...props} />;
};

export { DoraCheckbox, UFDoraCheckbox };
