import Welcome from "../../welcome";
import UnauthenticatedLayout from "../UnauthenticatedLayout";

export default function WelcomeContainer() {
  return (
    <UnauthenticatedLayout>
      <Welcome />
    </UnauthenticatedLayout>
  );
}
