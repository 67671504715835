import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../../redux-store";

const selectEntities = (state: RootState) =>
  state.data.archivedTrailers.entities;
const selectIds = (state: RootState) => state.data.archivedTrailers.ids;

export const selectArchivedTrailers = createSelector(
  selectIds,
  selectEntities,
  (ids, entities) => ids.map((id) => entities[id])
);
