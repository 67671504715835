import axios, { getData, isAxiosError } from "../../../axios";
import { createSlice } from "@reduxjs/toolkit";
import { driverResourceT as driverT } from "dora-contracts";
import * as tPromise from "io-ts-promise";
import keyBy from "lodash/keyBy";
import { createErrorReportingAsyncThunk } from "../../helpers";
import { notifyL } from "../../notifications";
import {
  driversT,
  addDriverT,
  updateDriverT,
  Driver,
  AddDriver,
  UpdateDriver,
} from "dora-contracts";

const prefix = "data/drivers";

interface State {
  driverEntities: Record<string, Driver>;
  driverIds: string[];
  status: "NOT_LOADED" | "LOADED" | "LOADING" | "LOAD_ERROR";
}

const initialState: State = {
  driverEntities: {},
  driverIds: [],
  status: "NOT_LOADED",
};

export const loadDrivers = createErrorReportingAsyncThunk(
  `${prefix}/load`,
  async () => {
    const { data } = await axios.get("/api/drivers");
    return tPromise.decode(driversT, data);
  }
);

export const sendLoginSms = createErrorReportingAsyncThunk(
  `${prefix}/send-login-sms`,
  async (driverId: string) => {
    await axios.post(`/api/drivers/${driverId}/send-login-message`);
  }
);

export const addDriver = createErrorReportingAsyncThunk(
  `${prefix}/add-driver`,
  async (driver: AddDriver): Promise<Driver> => {
    return await axios
      .post("/api/drivers", addDriverT.encode(driver))
      .then(getData)
      .then(tPromise.decode(driverT));
  }
);

export const updateDriver = createErrorReportingAsyncThunk(
  `${prefix}/update-driver`,
  async (driver: UpdateDriver & { id: string }): Promise<Driver> => {
    const { id, ...rest } = driver;
    return await axios
      .put(`/api/drivers/${id}`, updateDriverT.encode(rest))
      .then(getData)
      .then(tPromise.decode(driverT));
  }
);

export const deleteDriver = createErrorReportingAsyncThunk(
  `${prefix}/delete-driver`,
  async (id: string, { dispatch }) => {
    try {
      await axios.delete(`/api/drivers/${id}`);
    } catch (e) {
      if (isAxiosError(e)) {
        dispatch(
          notifyL({
            namespace: "notifications",
            key: "driverCannotBeDeleted",
            type: "error",
          })
        );
      }
      throw e;
    }
  },
  { skipNotification: true } as any
);

export const deactivateDriver = createErrorReportingAsyncThunk(
  `${prefix}/deactivate`,
  async (id: string) => {
    return axios
      .post(`/api/drivers/${id}/deactivate`)
      .then(getData)
      .then(tPromise.decode(driverT));
  }
);

export const activateDriver = createErrorReportingAsyncThunk(
  `${prefix}/activate`,
  async (id: string) => {
    return axios
      .post(`/api/drivers/${id}/activate`)
      .then(getData)
      .then(tPromise.decode(driverT));
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadDrivers.pending, (state) => {
        state.status = "LOADING";
      })
      .addCase(loadDrivers.fulfilled, (state, action) => {
        state.status = "LOADED";
        state.driverEntities = keyBy(action.payload, "id");
        state.driverIds = action.payload.map((x) => x.id);
      })
      .addCase(loadDrivers.rejected, (state) => {
        state.status = "LOAD_ERROR";
      })
      .addCase(addDriver.fulfilled, (state, action) => {
        const driver = action.payload;
        state.driverIds.push(driver.id);
        state.driverEntities[driver.id] = driver;
      })
      .addCase(updateDriver.fulfilled, (state, action) => {
        const driver = action.payload;
        state.driverEntities[driver.id] = driver;
      })
      .addCase(activateDriver.fulfilled, (state, action) => {
        const driver = action.payload;
        state.driverEntities[driver.id] = driver;
      })
      .addCase(deactivateDriver.fulfilled, (state, action) => {
        const driver = action.payload;
        state.driverEntities[driver.id] = driver;
      })
      .addCase(deleteDriver.fulfilled, (state, action) => {
        const driverId = action.meta.arg;
        state.driverIds = state.driverIds.filter((x) => x !== driverId);
        delete state.driverEntities[driverId];
      });
  },
});

export default slice.reducer;
