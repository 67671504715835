import { useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";

export default function ValidationError(props: {
  id: string;
  field: "amount" | "quantity" | "percentage";
}) {
  const formState = useFormState();
  const error = formState.errors[props.field];
  const { t } = useTranslation("invoicing");
  if (!error) {
    return null;
  }
  const field = t("invoiceLine.fieldLabel", { context: props.field });
  return (
    <div id={props.id}>
      {t("invoiceLine.validationErrors.errorType", {
        context: error?.type as "min" | "typeError" | "tooManyDecimals",
        field,
      })}
    </div>
  );
}
