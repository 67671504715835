import { CargoEvent, cargoEventsT } from "./types";
import * as tPromise from "io-ts-promise";
import axios, { getData } from "../../../axios";
import { createErrorReportingAsyncThunk } from "../../helpers";
import { createSlice } from "@reduxjs/toolkit";

const prefix = "data/cargo-events";

// Currently we only store one set of cargo events, the ones that are present
// when you view a cargo in the create/edit shipment dialog.
type State = {
  entities: CargoEvent[];
  requestStatus?: "loading" | "error";
};

const initialState: State = {
  entities: [],
};

export const loadCargoEvents = createErrorReportingAsyncThunk(
  `${prefix}/load-cargo-events`,
  async (cargoId: string, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const requestStatus = getState().data.cargoEvents.requestStatus;
    if (requestStatus === "loading") {
      return null;
    }
    dispatch(loadCargoEventsApiCall(cargoId));
  }
);

export const loadCargoEventsApiCall = createErrorReportingAsyncThunk(
  `${prefix}/load-cargo-events-api-call`,
  async (cargoId: string) => {
   return axios
     .get(`/api/events/cargo-events/${cargoId}`)
     .then(getData)
     .then(tPromise.decode(cargoEventsT))
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    unloadCargoEvents: (state) => {
      state.entities = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadCargoEventsApiCall.pending, (state) => {
      state.requestStatus = "loading";
    });
    builder.addCase(loadCargoEventsApiCall.rejected, (state) => {
      state.requestStatus = "error";
    });
    builder.addCase(loadCargoEventsApiCall.fulfilled, (state, action) => {
      state.requestStatus = undefined;
      state.entities = action.payload;
    });
  },
});

export default slice.reducer;

export const { unloadCargoEvents } = slice.actions;
