import { useState } from "react";
import {
  Divider,
  Menu,
  ListItemText,
  Avatar,
  Typography,
  Button,
  ListItemIcon,
  MenuItem,
} from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import ModeNightOutlinedIcon from "@mui/icons-material/ModeNightOutlined";
// import ModeNightIcon from "@mui/icons-material/ModeNight";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { MuiTooltip } from "./nav-styles";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { useSelector } from "react-redux";
import * as authActions from "../../ducks/auth";
import { getLanguage, Languages, LanguageType } from "../../i18n/i18n";
import { useTranslation } from "react-i18next";
import FlagDanish from "../../i18n/flags/da.png";
import FlagBritish from "../../i18n/flags/en.png";
import FlagGerman from "../../i18n/flags/de.png";
import FlagSwedish from "../../i18n/flags/se.png";
import { selectUser } from "../../ducks/auth/selectors";
import { setLanguage } from "../../ducks/app/userSettings";
// import { selectDarkMode } from "../../ducks/app/userSettings/selectors";
import { useAppDispatch } from "../../redux-store";
import { useFeature } from "../../hooks";

export default function UserAccountMenu() {
  const { t } = useTranslation(["navigation", "translation"]);
  const language = getLanguage();
  const user = useSelector(selectUser);
  // const lightTheme = useSelector(selectDarkMode);
  const dispatch = useAppDispatch();
  // const darkmode = () => {
  //   dispatch(setDarkMode());
  // };
  const logout = () => {
    dispatch(authActions.logout());
  };
  const changeLanguage = (language: LanguageType) => {
    dispatch(setLanguage(language));
  };
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElLanguages, setAnchorElLanguages] =
    useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenLanguages = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLanguages(event.currentTarget);
  };
  const handleCloseLanguages = () => {
    setAnchorElLanguages(null);
  };
  const swedishEnabled = useFeature("language_se");
  const germanEnabled = useFeature("language_de");

  if (!user) {
    return null;
  }
  return (
    <>
      <MuiTooltip title={t("navigation:topbar.myAccount")} placement="bottom">
        <Button
          id="logged-in-user-menu"
          variant="text"
          color="inherit"
          size="large"
          startIcon={<AccountCircle />}
          onClick={handleOpenUserMenu}
        >
          {user.first}
        </Button>
      </MuiTooltip>
      <Menu
        anchorEl={anchorElUser}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        keepMounted
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        sx={{
          cursor: "pointer",
          "& a": { py: "0.5rem" },
          "& span": { fontSize: "14px" },
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            width: 200,
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 34,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
      >
        {/* <MenuItem onClick={darkmode}>
          <ListItemIcon>
            {lightTheme ? (
              <ModeNightIcon fontSize="small" />
            ) : (
              <ModeNightOutlinedIcon fontSize="small" />
            )}
          </ListItemIcon>
          <ListItemText>{t("navigation:userAccountMenu.darkmode")}</ListItemText>
        </MenuItem> */}

        <MenuItem
          onClick={(e) => {
            handleOpenLanguages(e);
          }}
        >
          <ListItemIcon>
            <KeyboardArrowLeftIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("translation:language")}</ListItemText>
          <Typography variant="body2" color="text.secondary">
            {t("translation:language", {
              context: language,
            })}
          </Typography>
        </MenuItem>

        <Menu
          anchorEl={anchorElLanguages}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
          anchorOrigin={{
            horizontal: "right",
            vertical: "bottom",
          }}
          open={Boolean(anchorElLanguages)}
          onClose={handleCloseLanguages}
          sx={{
            cursor: "pointer",
            "& a": { py: "0.5rem" },
            "& span": { fontSize: "14px" },
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              width: 140,
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: -5.15,
              ml: -25.25,
            },
          }}
        >
          <MenuItem
            onClick={() => {
              changeLanguage("da");
              handleCloseLanguages();
            }}
          >
            <ListItemIcon>
              <Avatar
                alt="Flag"
                src={FlagDanish}
                sx={{ width: 22, height: 22 }}
              />
            </ListItemIcon>
            <ListItemText>{Languages.da}</ListItemText>
          </MenuItem>
          {germanEnabled && (
            <MenuItem
              onClick={() => {
                changeLanguage("de");
                handleCloseLanguages();
              }}
            >
              <ListItemIcon>
                <Avatar
                  alt="Flag"
                  src={FlagGerman}
                  sx={{ width: 22, height: 22 }}
                />
              </ListItemIcon>
              <ListItemText>{Languages.de}</ListItemText>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              changeLanguage("en");
              handleCloseLanguages();
            }}
          >
            <ListItemIcon>
              <Avatar
                alt="Flag"
                src={FlagBritish}
                sx={{ width: 22, height: 22 }}
              />
            </ListItemIcon>
            <ListItemText>{Languages.en}</ListItemText>
          </MenuItem>
          {swedishEnabled && (
            <MenuItem
              onClick={() => {
                changeLanguage("se");
                handleCloseLanguages();
              }}
            >
              <ListItemIcon>
                <Avatar
                  alt="Flag"
                  src={FlagSwedish}
                  sx={{ width: 22, height: 22 }}
                />
              </ListItemIcon>
              <ListItemText>{Languages.se}</ListItemText>
            </MenuItem>
          )}
        </Menu>

        <Divider />
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <LogoutOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("navigation:userAccountMenu.logOut")}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
