import {
  ExtraCO2Types,
  ExtraCO2TypeReqBody,
  extraCO2TypesT,
  extraCo2TypeReqBodyT,
  ExtraCO2Type,
} from "dora-contracts";
import { createErrorReportingAsyncThunk } from "../../helpers";
import axios from "../../../axios";
import * as tPromise from "io-ts-promise";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ERR_DUPLICATE_EXTRA_CO2_TYPE } from "dora-shared";
import { notifyL } from "../../notifications";

const prefix = "data/extra-co2-types";

interface State {
  types: ExtraCO2Types;
}

const initialState: State = {
  types: [],
};

export const loadExtraCO2Types = createErrorReportingAsyncThunk(
  `${prefix}/load`,
  async () => {
    const { data } = await axios.get("/api/extra-co2-types");
    return tPromise.decode(extraCO2TypesT, data);
  }
);

export const createExtraCO2Type = createAsyncThunk(
  `${prefix}/create`,
  async (data: ExtraCO2TypeReqBody, { dispatch }) => {
    return axios
      .post("/api/extra-co2-types", extraCo2TypeReqBodyT.encode(data))
      .then(() => {
        dispatch(loadExtraCO2Types() as any); // as any because problem combining async thunk and error reporting async thunk
      })
      .catch((e) => {
        if (e.response?.status === 409) {
          throw new Error(ERR_DUPLICATE_EXTRA_CO2_TYPE);
        } else {
          dispatch(
            notifyL({
              namespace: "notifications",
              key: "saveExtraCO2Error",
              type: "error",
            })
          );
        }
      });
  }
);

export const updateExtraCO2Type = createAsyncThunk(
  `${prefix}/update`,
  async (data: ExtraCO2Type, { dispatch }) => {
    return axios
      .put(`/api/extra-co2-types/${data.id}`, extraCo2TypeReqBodyT.encode(data))
      .then(() => {
        dispatch(loadExtraCO2Types() as any); // as any because problem combining async thunk and error reporting async thunk
      })
      .catch((e) => {
        if (e.response?.status === 409) {
          throw new Error(ERR_DUPLICATE_EXTRA_CO2_TYPE);
        } else {
          dispatch(
            notifyL({
              namespace: "notifications",
              key: "saveExtraCO2Error",
              type: "error",
            })
          );
        }
      });
  }
);

export const deleteExtraCO2Type = createErrorReportingAsyncThunk(
  `${prefix}/delete`,
  async (id: string, { dispatch }) => {
    return axios.delete(`/api/extra-co2-types/${id}`).then(() => {
      dispatch(loadExtraCO2Types());
    });
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadExtraCO2Types.fulfilled, (state, action) => {
      state.types = action.payload;
    });
  },
});

export default slice.reducer;
