/// <reference types="vite-plugin-svgr/client" />
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "react-app-polyfill/stable";
import { store } from "./redux-store";
import { reportInfo, reportError } from "./helpers/error-helper";
import { serializeError } from "serialize-error";
import * as configActions from "./ducks/app/userSettings";
import * as featureActions from "./ducks/features";
import "./styles.css";
import initLuxon from "./init-luxon";
import App from "./app";
import * as Sentry from "@sentry/react";
import { replayIntegration } from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useNavigationType,
} from "react-router";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { loadApiKeys } from "./ducks/app/api-keys";

initLuxon();

Sentry.init({
  release: "test-release",
  dsn: "https://e125f02c88fed52de884916309680b40@o4507542742368256.ingest.de.sentry.io/4507542780182608",
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/app.nordic\.dk/],
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.6, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: process.env.NODE_ENV !== "development",
});

// import { init } from "./socket";
//
reportInfo({ msg: "UI Session started" });

store.dispatch(configActions.init());
store.dispatch(featureActions.initialize());
store.dispatch(loadApiKeys());

window.addEventListener("error", (err) => {
  console.log(err);
  reportError({
    msg: "uncaught error",
    err: serializeError(err),
  });
});

const container = document.querySelector("main");
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
