import axios from "../axios";
import { v4 as uuid } from "uuid";

const sessionId = uuid();

export const reportInfo = (body: Record<string, unknown>) => {
  axios.post("/api/log-entries/info", { ...body, sessionId });
};

export const formatError = (err: any) =>
  err instanceof Error
    ? `${err.toString()}\n${err.message}\n${err.stack}`
    : JSON.stringify(err);

export const reportError = (body: Record<string, unknown>) => {
  axios.post("/api/log-entries/errors", { ...body, sessionId }).catch(() => {
    // Don't throw error to avoid infinite circular loop
  });
};

export const reportWarning = (body: Record<string, unknown>) => {
  axios.post("/api/log-entries/warnings", { ...body, sessionId }).catch(() => {
    // Don't throw error to avoid infinite circular loop
  });
};
