import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunkAction } from "../../../redux-store";
import * as t from "io-ts";
import { LanguageType, supportedLanguages } from "../../../i18n/i18n";

type State = {
  darkMode: boolean;
  disableTouchDevice: boolean;
  language: LanguageType;
};
export const STORAGE_KEY = "user-config";

const initialState: State = {
  darkMode: false,
  disableTouchDevice: false,
  language: "da",
};

const configT = t.exact(
  t.partial({
    darkMode: t.boolean,
    language: t.union(supportedLanguages.map((x) => t.literal(x)) as any),
  })
);

const setConfigObj =
  (newSettings: Partial<State>): AppThunkAction =>
  (dispatch, getState) => {
    const state = getState().app.userSettings;
    const config = { ...state, ...newSettings };
    window.localStorage.setItem(STORAGE_KEY, JSON.stringify(config));
    dispatch(slice.actions.setConfig(config));
  };

export const setDarkMode = (): AppThunkAction => (dispatch, getState) => {
  const state = getState().app.userSettings;
  dispatch(setConfigObj({ darkMode: !state.darkMode }));
};

export const setLanguage =
  (language: LanguageType): AppThunkAction =>
  (dispatch) => {
    dispatch(setConfigObj({ language }));
  };

export const init = (): AppThunkAction => (dispatch) => {
  try {
    const data = window.localStorage.getItem(STORAGE_KEY);
    const parsed = configT.decode(data && JSON.parse(data));
    switch (parsed._tag) {
      case "Left":
        dispatch(slice.actions.setConfig(initialState));
        return;
      case "Right":
        dispatch(
          slice.actions.setConfig({
            ...initialState,
            ...parsed.right,
          })
        );
        return;
    }
  } catch {}
  dispatch(slice.actions.setConfig(initialState));
};

const slice = createSlice({
  name: "app/user-settings",
  initialState,
  reducers: {
    setDarkMode: (state) => {
      state.darkMode = !state.darkMode;
    },
    setConfig: (_state, action: PayloadAction<State>) => {
      return action.payload;
    },
    init: (state) => {
      return state;
    },
  },
});
export default slice.reducer;
// export const { init } = slice.actions;
