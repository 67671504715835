import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import "./new-dora-inputs.scss";
import { InputHTMLAttributes } from "react";

type DoraTextInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  "onChange"
> & {
  icon?: IconProp;
  suffix?: string;
  error?: boolean;
  autoFocus?: boolean;
  onChange?: (value: string) => void;

  // useForms inject
  register?: any;
};

type UseFormsInputProps = {
  register: any;
};

const DoraTextInput = (props: DoraTextInputProps) => {
  const {
    id,
    icon,
    placeholder,
    className,
    onChange,
    disabled,
    error,
    register,
    autoFocus,
    ...rest
  } = props;
  const wrapperClasses = classnames(
    "dora-text-input-wrapper",
    disabled ? "dora-text-input-wrapper--disabled" : null,
    error ? "dora-text-input-wrapper--error" : null,
    className
  );
  const classes = classnames("dora-text-input__input", {
    "dora-text-input__input--with-icon": !!icon,
  });
  const onChangeProp = !register
    ? {
        onChange: (e: any) => onChange && onChange(e.target.value),
      }
    : {};
  return (
    <div className={wrapperClasses}>
      {icon && (
        <div className="dora-text-input__icon">
          <FontAwesomeIcon icon={icon} />
        </div>
      )}
      <input
        {...(id && { id })}
        {...register}
        {...onChangeProp}
        {...(autoFocus && { autoFocus: true })}
        {...rest}
        aria-invalid={error ? "true" : "false"}
        type="text"
        className={classes}
        disabled={disabled}
        placeholder={placeholder}
      />
    </div>
  );
};

const UFDoraTextInput = (props: DoraTextInputProps & UseFormsInputProps) => {
  const { register, ...rest } = props;
  return <DoraTextInput register={register} {...rest} />;
};
export { DoraTextInput, UFDoraTextInput };
