import { createSlice } from "@reduxjs/toolkit";
import { AppThunkAction } from "../../../redux-store";
import * as cargoActions from "../../cargo";

const prefix = "app/UpcomingPage-page";
type State = {
  open: boolean;
};

const initialState: State = { open: false };

export const refresh = (): AppThunkAction => async (dispatch, getState) => {
  if (getState().app.upcomingPage.open) {
    await dispatch(cargoActions.getCargos());
  }
};

const slice = createSlice({
  initialState,
  name: prefix,
  reducers: {
    showUpcomingPage(state) {
      state.open = true;
    },
    hideUpcomingPage(state) {
      state.open = false;
    },
  },
});

export default slice.reducer;
export const { showUpcomingPage, hideUpcomingPage } = slice.actions;
