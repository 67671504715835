import { useState } from "react";
import axios from "../../axios";
import { useNavigate, Link } from "react-router-dom";
import ContentStyle from "./ContentStyle";
import {
  Alert,
  Button,
  TextField,
  Typography,
  Container,
  Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const ResetPassword = () => {
  const [error, setError] = useState(false);
  const [form, setForm] = useState({ email: "", code: "", password: "" });
  const navigate = useNavigate();
  const { t } = useTranslation("auth");

  const handleChange = (e) => {
    setForm((form) => ({ ...form, [e.target.name]: e.target.value }));
  };

  const handleReset = (e) => {
    e.preventDefault();
    axios
      .post("/reset-password", form)
      .then(({ data }) => {
        if (data.success) {
          navigate(`./enter-code?email=${form.email}`);
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        setError(true);
      });
  };

  return (
    <>
      <Typography inline align="right" style={{ paddingTop: 20 }}>
        <Link to="/welcome/login">
          <Button variant="text">Tilbage til log ind</Button>
        </Link>
      </Typography>
      <ContentStyle>
        <Container sx={{ p: 3, mt: 16 }} maxWidth="xs">
          <form>
            <Stack spacing={3}>
              <Typography variant="h4">
                {t("auth:resetPage.heading")}
              </Typography>
              <p>{t("auth:resetPage.instructions")}</p>
              {error && (
                <Alert severity="error">{t("auth:resetPage.wrongEmail")}</Alert>
              )}
              <TextField
                fullWidth
                autoFocus
                name="email"
                type="email"
                label="E-mail"
                onChange={(e) => handleChange(e)}
              />
              <Button
                fullWidth
                type="submit"
                variant="contained"
                onClick={handleReset}
              >
                {t("auth:resetPage.sendCodeButton")}
              </Button>
            </Stack>
          </form>
        </Container>
      </ContentStyle>
    </>
  );
};

export default ResetPassword;
