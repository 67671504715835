import axios, { getData } from "../../../axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createErrorReportingAsyncThunk } from "../../helpers";
import { orderT, orderViewsT, OrderView, Order } from "./types";
import * as tPromise from "io-ts-promise";

const prefix = "data/orders";

type State = {
  orderViews: OrderView[];
  entities: Record<string, Order>;
};

const initialState: State = { orderViews: [], entities: {} };

export const loadOrders = createErrorReportingAsyncThunk(
  `${prefix}/load-orders`,
  async () => {
    const result = axios
      .get("/api/orders")
      .then(getData)
      .then(tPromise.decode(orderViewsT));
    return result;
  }
);

export const loadOrder = createErrorReportingAsyncThunk(
  `${prefix}/load-order`,
  async (id: string) => {
    const result = axios
      .get(`/api/orders/${id}`)
      .then(getData)
      .then(tPromise.decode(orderT));
    return result;
  }
);

const slice = createSlice({
  initialState,
  name: prefix,
  reducers: {
    setOrder: (state, action: PayloadAction<Order>) => {
      const order = action.payload;
      state.entities[order.id] = order;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadOrders.fulfilled, (state, action) => {
        state.orderViews = action.payload;
      })
      .addCase(loadOrder.fulfilled, (state, action) => {
        state.entities[action.payload.id] = action.payload;
      });
  },
});

export const { setOrder } = slice.actions;
export default slice.reducer;
