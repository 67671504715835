import { Apartment, Check, Star } from "@mui/icons-material";
import classnames from "classnames";
import "./SavedLocationTypeSelectionOption.scss";
import { useTranslation } from "react-i18next";

type SavedLocationTypeSelectionOptionProps = {
  type: "favorite" | "terminal";
  active: boolean;
  hasCheckIcon?: boolean;
  onClick: () => void;
};

const SavedLocationTypeSelectionOption = (
  props: SavedLocationTypeSelectionOptionProps
) => {
  const { active, hasCheckIcon, type, onClick } = props;
  const classes = classnames(
    "saved-location-type-selection-option__type-selection",
    {
      "saved-location-type-selection-option__type-selection--selected": active,
    }
  );
  const { t } = useTranslation("modals");

  return (
    <button
      className={classes}
      onClick={onClick}
      role="radio"
      aria-checked={active}
    >
      {type === "favorite" ? (
        <Star className="saved-location-type-selection-option__type_symbol" />
      ) : (
        <Apartment className="saved-location-type-selection-option__type_symbol" />
      )}
      <div className="saved-location-type-selection-option__type-selection-text">
        {type === "favorite"
          ? `${t("manageSavedLocations.favorites")}`
          : `${t("manageSavedLocations.terminals")}`}
      </div>
      {hasCheckIcon && (
        <div className="saved-location-type-selection-option__check-wrapper">
          <div className="saved-location-type-selection-option__check-circle">
            {active && (
              <Check className="saved-location-type-selection-option__check" />
            )}
          </div>
        </div>
      )}
    </button>
  );
};

export default SavedLocationTypeSelectionOption;
