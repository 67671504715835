import { RootState } from "../../../redux-store";
import { createSelector } from "@reduxjs/toolkit";

export const selectTrackerDevices = createSelector(
  (state: RootState) => state.data.trackerDevices.ids,
  (state: RootState) => state.data.trackerDevices.entities,

  (ids, trackerDevices) => ids.map((id) => trackerDevices[id])
);

export const selectTelemetryPosition =
  (deviceId: string) => (state: RootState) => {
    const trackerState = state.data.trackerDevices;
    const doraDevice = trackerState.entities[deviceId];
    const telemetry = doraDevice?.flespiId
      ? trackerState.telemetry[doraDevice?.flespiId]
      : null;
    return telemetry?.position;
  };

export const selectFlespiDeviceIdFromDoraDeviceId =
  (doraDeviceId: string) => (state: RootState) =>
    state.data.trackerDevices.entities[doraDeviceId]?.flespiId;

export const selectTrackerAddress =
  ({ doraDeviceId }: { doraDeviceId?: string }) =>
  (state: RootState) => {
    const flespiId =
      doraDeviceId && selectFlespiDeviceIdFromDoraDeviceId(doraDeviceId)(state);
    const telemetry = flespiId
      ? state.data.trackerDevices.telemetry[flespiId]
      : null;
    return telemetry?.location;
  };

export const selectTelemetryIgnitionStatus =
  ({ doraDeviceId }: { doraDeviceId: string | null }) =>
  (state: RootState) => {
    const flespiId =
      doraDeviceId && selectFlespiDeviceIdFromDoraDeviceId(doraDeviceId)(state);
    const telemetry = flespiId
      ? state.data.trackerDevices.telemetry[flespiId]
      : null;
    return telemetry?.ignitionStatus;
  };

export const selectTelemetryMovementStatus =
  ({ doraDeviceId }: { doraDeviceId: string | null }) =>
  (state: RootState) => {
    const flespiId =
      doraDeviceId && selectFlespiDeviceIdFromDoraDeviceId(doraDeviceId)(state);
    const telemetry = flespiId
      ? state.data.trackerDevices.telemetry[flespiId]
      : null;
    return telemetry?.movementStatus;
  };

export const selectTelemetryTachoStats =
  ({ doraDeviceId }: { doraDeviceId: string | null }) =>
  (state: RootState) => {
    const flespiId =
      doraDeviceId && selectFlespiDeviceIdFromDoraDeviceId(doraDeviceId)(state);
    const telemetry = flespiId
      ? state.data.trackerDevices.telemetry[flespiId]
      : null;
    return telemetry?.tachoStats;
  };
