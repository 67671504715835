import { createRoot } from "react-dom/client";
import VehicleCirclePin from "../components/VehicleCirclePin";
import trucksColors from "../../../helpers/trucks-colors";

const coloredTrucksRenderedHtml: { [color: string]: HTMLDivElement } = {};

for (const truckColor of trucksColors) {
  const container = document.createElement("div");
  container.setAttribute("id", "vehicle-circle-pin-root");
  document.body.appendChild(container);
  const domNode = document.getElementById("vehicle-circle-pin-root");
  const root = createRoot(domNode!);
  root.render(
    <VehicleCirclePin
      large
      style={{
        transform: "translate(-50%, -50%)",
        backgroundColor: truckColor.color,
      }}
    />
  );
  document.body.removeChild(container);
  container.removeAttribute("id");
  coloredTrucksRenderedHtml[truckColor.id] = container;
}

// default, no color
const container = document.createElement("div");
container.setAttribute("id", "vehicle-circle-pin-root");
document.body.appendChild(container);
const domNode = document.getElementById("vehicle-circle-pin-root");
const root = createRoot(domNode!);
root.render(
  <VehicleCirclePin
    large
    style={{
      transform: "translate(-50%, -50%)",
    }}
  />
);
document.body.removeChild(container);
container.removeAttribute("id");

const getColoredTruckHtml = (color: string | null) => {
  return color ? coloredTrucksRenderedHtml[color] : container;
};

export default getColoredTruckHtml;
