import { useTranslation } from "react-i18next";
import { useL10n } from "../../../../../l10n";
import { useWatch } from "react-hook-form";
import { Form } from "./types";

const Total = () => {
  const l10n = useL10n();
  const { t } = useTranslation("invoicing");
  const [quantity, amount] = useWatch<Form>({
    name: ["quantity", "amount"],
  }).map((x) => (x ? l10n.parseDecimal(x) : null));
  if (quantity && amount) {
    const result = quantity.mul(amount);
    return (
      <span aria-label={t("invoiceLine.sum")}>
        {l10n.formatCurrency(result)}
      </span>
    );
  } else {
    return null;
  }
};

export default Total;
