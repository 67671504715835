import { createSlice } from "@reduxjs/toolkit";
import { createErrorReportingAsyncThunk } from "../../helpers";
import axios, { getData } from "../../../axios";
import * as tPromise from "io-ts-promise";
import * as t from "io-ts";
import { financeT } from "dora-contracts";

const prefix = "data/finance/configuration";

type Configuration = t.TypeOf<typeof financeT>;
type State = null | Configuration;

const initialState = null as State;

export const loadFinance = createErrorReportingAsyncThunk(
  "load-finance",
  async () => {
    const response: Configuration = await axios
      .get("/api/finance")
      .then(getData)
      .then(tPromise.decode(financeT));
    return response;
  }
);

const slice = createSlice({
  initialState,
  name: prefix,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadFinance.fulfilled, (_state, action) => {
      const newState: Configuration = action.payload;
      return newState;
    });
  },
});

export default slice.reducer;
