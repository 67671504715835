import axios from "../../../axios";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as dataActions from "../../data/orders";
import { AsyncAppThunkAction } from "../../../redux-store";
import { isAxiosError } from "axios";
import * as t from "io-ts";
import * as tPromise from "io-ts-promise";
import { orderT } from "./types";

export const loadOrder =
  (id: string): AsyncAppThunkAction =>
  async (dispatch) => {
    dispatch(slice.actions.setStatus("OK"));
    await dispatch(dataActions.loadOrder(id)).unwrap();
  };

const prefix = "app/order-dialog";

type State = {
  status: "OK" | "ACCEPT_CONFLICT" | "REJECT_CONFLICT";
};

const initialState: State = {
  status: "OK",
};

export const rejectOrder =
  (id: string): AsyncAppThunkAction<boolean> =>
  async (dispatch, getState) => {
    const order = getState().data.orders.entities[id];
    try {
      await axios.post(`/api/orders/${id}/reject`, {
        revision: order.revision,
      });
      await dispatch(dataActions.loadOrders());
      return true;
    } catch (e) {
      if (!isAxiosError(e)) {
        throw e;
      }
      if (e.response?.status === 409 && e.response?.data) {
        const { current } = await tPromise.decode(
          t.strict({ current: orderT }),
          e.response.data
        );
        dispatch(slice.actions.setStatus("REJECT_CONFLICT"));
        dispatch(dataActions.setOrder(current));
      }
      return false;
    }
  };

export const acceptOrder =
  (id: string): AsyncAppThunkAction<boolean> =>
  async (dispatch, getState) => {
    const order = getState().data.orders.entities[id];
    try {
      await axios.post(`/api/orders/${id}/accept`, {
        revision: order.revision,
      });
      await dispatch(dataActions.loadOrders());
      return true;
    } catch (e) {
      if (!isAxiosError(e)) {
        throw e;
      }
      if (e.response?.status === 409 && e.response?.data) {
        const { current } = await tPromise
          .decode(t.strict({ current: orderT }), e.response.data)
          .catch((err) => {
            console.error(err);
            throw err;
          });
        dispatch(slice.actions.setStatus("ACCEPT_CONFLICT"));
        dispatch(dataActions.setOrder(current));
      }
      return false;
    }
  };

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<State["status"]>) => {
      state.status = action.payload;
    },
  },
});

export const { setStatus } = slice.actions;
export default slice.reducer;
