import { useMemo } from "react";
import { useStatefulFields } from "../../hooks/useStatefulFields";
import { useRegistration } from "../../hooks/useRegistration";
import {
  Alert,
  AlertTitle,
  Button,
  Stack,
  Container,
  Typography,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

export default function RegistrationForm() {
  const [values, handleChange] = useStatefulFields();
  const [error, handleSubmit] = useRegistration();
  const onSubmit = (e) => {
    e.preventDefault();
    handleSubmit(values);
  };

  const errorStrings = useMemo(() => {
    if (!error) {
      return [];
    }
    if (typeof error === "object") {
      if (error.type === "VALIDATION_ERRORS") {
        return error.errors.map((err) => {
          switch (err.type) {
            case "INVALID_EMAIL":
              return "Ugyldig email (kun små bogstaver)";
            case "EMPTY_INPUT":
              return `Input felt er tomt (${err.field})`;
            case "INVALID_COMPANY":
              return "Fejl i firma-id";
            default:
              return "Fejl i input";
          }
        });
      }
      if (error.type === "ORG_NOT_FOUND") {
        return ["Virksomhed ikke fundet"];
      }
      if (error.type === "EMAIL_ALREADY_EXISTS") {
        return ["Email adressen er allerede registreret"];
      }
    }
    return ["Noget gik galt, forsøg igen"];
  }, [error]);

  return (
    <>
      <Typography inline align="right" style={{ paddingTop: 20 }}>
        <Button
          variant="text"
          id="go-to-login-link"
          component={Link}
          to="/welcome/login"
        >
          Log ind her
        </Button>
      </Typography>

      <ContentStyle>
        <Container sx={{ p: 3, mt: 10 }} maxWidth="xs">
          <form autoComplete="on" onSubmit={onSubmit}>
            <Stack spacing={3}>
              <Typography variant="h4">Tilmeld dig som bruger</Typography>
              <TextField
                required
                fullWidth
                name="first"
                size="small"
                label="Fornavn"
                type="text"
                variant="outlined"
                onChange={handleChange}
              />
              <TextField
                fullWidth
                required
                name="last"
                label="Efternavn"
                size="small"
                type="text"
                variant="outlined"
                onChange={handleChange}
              />
              <TextField
                fullWidth
                required
                name="company_id"
                label="Firma id"
                size="small"
                type="text"
                variant="outlined"
                onChange={handleChange}
              />
              <TextField
                fullWidth
                required
                name="email"
                size="small"
                label="Email"
                type="email"
                variant="outlined"
                onChange={handleChange}
              />
              <TextField
                fullWidth
                required
                name="password"
                label="Password"
                size="small"
                type="password"
                variant="outlined"
                onChange={handleChange}
              />
              {errorStrings.length > 0 && (
                <Alert severity="error">
                  <AlertTitle>Fejl</AlertTitle>
                  <ul>
                    {errorStrings.map((s, i) => (
                      <li key={i}>{s}</li>
                    ))}
                  </ul>
                </Alert>
              )}
              <Button
                color="primary"
                fullWidth
                type="submit"
                variant="contained"
              >
                Tilmeld
              </Button>
            </Stack>
          </form>
        </Container>
      </ContentStyle>
    </>
  );
}
