import * as t from "io-ts";
import { createSlice } from "@reduxjs/toolkit";
import { createErrorReportingAsyncThunk } from "../../../helpers";
import { financeUnitT, FinanceUnit } from "./types";
import axios, { getData } from "../../../../axios";
import * as tPromise from "io-ts-promise";

const prefix = "data/finance/units";

type State = null | FinanceUnit[];

const initialState = null as State;

export const loadFinanceUnits = createErrorReportingAsyncThunk(`${prefix}/load`, async () =>
  axios
    .get("/api/finance/units")
    .then(getData)
    .then(tPromise.decode(t.array(financeUnitT)))
);

const slice = createSlice({
  initialState,
  name: prefix,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadFinanceUnits.fulfilled, (_, action) => action.payload);
  },
});

export default slice.reducer;
