import { createContext, useContext } from "react";
import NumberParser from "./NumberParser";
import Decimal from "decimal.js-light";
import { DateTime, Settings } from "luxon";

class L10nEngine {
  locale: string;
  numberParser: NumberParser;
  private dateFormat = "dd.MM.yyyy";
  constructor(locale = "da-DK") {
    this.locale = locale;
    this.numberParser = new NumberParser(locale);
  }

  private toNumber(n: string | number | Decimal): number {
    switch (typeof n) {
      case "number":
        return n;
      case "string":
        return new Decimal(n).toNumber();
      default:
        return n.toNumber();
    }
  }

  private asDateTime(d: DateTime | string) {
    return (
      d instanceof DateTime ? d : DateTime.fromISO(d, { setZone: true })
    ).setLocale(this.locale);
  }

  formatNumber(n: string | number | Decimal, decimalPlaces?: number): string {
    return new Intl.NumberFormat(this.locale, {
      ...(decimalPlaces && {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
      }),
    }).format(this.toNumber(n));
  }

  private formatWithExactFractionDigits(
    n: string | number | Decimal,
    noOfDigits: number
  ) {
    return new Intl.NumberFormat(this.locale, {
      minimumFractionDigits: noOfDigits,
      maximumFractionDigits: noOfDigits,
    }).format(
      typeof n === "number" ? n : typeof n === "string" ? +n : n.toNumber()
    );
  }

  getDateFormat() {
    return this.dateFormat;
  }

  formatPercentage(n: string | number | Decimal): string {
    return this.formatWithExactFractionDigits(n, 2) + "%";
  }

  formatCurrency(n: string | number | Decimal): string {
    return this.formatWithExactFractionDigits(n, 2);
  }

  formatDateWithWeekdayAndMonthName(d: DateTime) {
    const text = this.asDateTime(d).toFormat("EEEE d. MMM", {
      locale: Settings.defaultLocale,
    });
    const [weekday, date, monthName] = text.split(" ");
    return [
      weekday.charAt(0).toUpperCase() + weekday.slice(1),
      date,
      monthName.charAt(0).toUpperCase() + monthName.slice(1),
    ].join(" ");
  }

  formatDate(d: string) {
    return this.asDateTime(d).toFormat(this.dateFormat);
  }

  formatDateAndTime(d: DateTime | string) {
    return this.asDateTime(d).toLocaleString(DateTime.DATETIME_SHORT);
  }

  parseNumber(s: string): number {
    return this.numberParser.parse(s);
  }

  /**
   * Parse a string to a `Decimal` instance. null if not a valid number
   */
  parseDecimal(s: string): Decimal | null {
    return this.numberParser.parseDecimal(s);
  }

  invariantToLocalizedNumberString(input?: string): string | undefined {
    return input && this.formatNumber(+input);
  }
}

export const LocalizationContext = createContext(new L10nEngine());

export const LocalizationProvider = ({
  locale,
  children,
}: {
  locale: string;
  children: React.ReactNode;
}) => (
  <LocalizationContext.Provider value={new L10nEngine(locale)}>
    {children}
  </LocalizationContext.Provider>
);

export const useL10n = () => useContext(LocalizationContext);
