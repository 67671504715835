import * as Sentry from "@sentry/react";
import { rootReducer } from "./reducer";
import { DateTime } from "luxon";
import { configureStore, AnyAction, ThunkAction } from "@reduxjs/toolkit";
import * as redux from "react-redux";
import { reportInfo } from "./helpers/error-helper";
import Decimal from "decimal.js-light";

/*
const logLocal =
    (_store: RootStore) => (next: AppDispatch) => (action: AnyAction) => {
        // const preState = store.getState();
        const result = next(action);
        // const postState = store.getState();
        console.log({ action });
        return result;
    };
*/

// eslint-disable-next-line
const logToServer =
  (_store: RootStore) => (next: AppDispatch) => (action: AnyAction) => {
    // const preState = store.getState();
    const result = next(action);
    // const postState = store.getState();
    reportInfo({ action });
    return result;
  };

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

export const store = configureStore({
  reducer: rootReducer,
  enhancers: (defaultEnhancers) => [...defaultEnhancers, sentryReduxEnhancer],
  devTools: process.env.NODE_ENV !== "production" && {
    serialize: {
      replacer: (_key, value) => {
        if (value instanceof Decimal) {
          return { $type: "Decimal", value: value.toString() };
        }
        if (value instanceof DateTime) {
          return { $type: "DateTime", value: value.toISO() };
        }
        return value;
      },
      reviver: (_key, value: any) => {
        if (typeof value === "object" && value !== null && "$type" in value) {
          switch (value.$type) {
            case "Decimal":
              return new Decimal(value.data);
            case "DateTime":
              return DateTime.fromISO(value.data, { setZone: true });
          }
        }
      },
    },
  },
  middleware: (getDefault): ReturnType<typeof getDefault> =>
    getDefault({ serializableCheck: false }),
  // getDefault().prepend(logToServer as any) as any,
});

export type AppThunkAction<T = void> = ThunkAction<
  T,
  RootState,
  any,
  AnyAction
>;
export type AsyncAppThunkAction<T = void> = AppThunkAction<Promise<T>>;
export type RootState = ReturnType<typeof rootReducer>;
export type RootStore = typeof store;
export type AppDispatch = RootStore["dispatch"];
export type GetState = RootStore["getState"];
export type Action = Parameters<AppDispatch>[0];
export type ActionCreator<T extends any[]> = (...args: T) => Action;
export const useAppDispatch = () => redux.useDispatch<AppDispatch>();
export const useSelector = <T>(selector: (state: RootState) => T) =>
  redux.useSelector(selector);
