import { createSlice } from "@reduxjs/toolkit";
import { createAppAsyncThunk } from "../../../redux-helpers";
import * as dataActions from "../../data/teams";
const prefix = "app/team-dialog";

type FormData = {
  name: string;
  description: string;
};
type State = {
  formData: FormData | null;
};

const initialState: State = { formData: null };

export const submit = createAppAsyncThunk(
  `${prefix}/submit`,
  async (data: FormData, { dispatch }) => {
    await dispatch(dataActions.addTeam(data)).unwrap();
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {
    newTeam: (_state: State): State => ({
      formData: { name: "", description: "" },
    }),
    close: (_state) => ({ formData: null }),
  },
  extraReducers: (builder) => {
    builder.addCase(submit.fulfilled, (state) => {
      state.formData = null;
    });
  },
});

export const { newTeam, close } = slice.actions;
export default slice.reducer;
