import axios, { getData } from "../axios";
import { createErrorReportingAsyncThunk, defaultErrorAction } from "./helpers";
import * as t from "io-ts";
import * as tPromise from "io-ts-promise";
import { ERR_CANNOT_MOVE_CARGO_TO_ARCHIVED_ROUTE } from "dora-shared";
import { notifyL } from "./notifications";
import { createAsyncThunk } from "@reduxjs/toolkit";

const cargoOrdersT = t.strict({
  updatedCargoOrders: t.array(
    t.strict({ routeId: t.string, cargoOrder: t.array(t.string) })
  ),
});

export const moveCargo = createAsyncThunk(
  "/app/dispatch/moveCargo",
  async (
    arg: {
      sourceRouteId: string | null;
      destRouteId: string | null;
      cargoId: string;
      destIndex?: number;
    },
    thunkAPI
  ): Promise<t.TypeOf<typeof cargoOrdersT>> => {
    const { sourceRouteId, destRouteId, destIndex, cargoId } = arg;
    if (destRouteId || sourceRouteId) {
      // Don't post if rearranging unassigned cargo
      const { dispatch } = thunkAPI;
      return axios
        .post(`/api/cargos/${cargoId}/move-to-route`, {
          destRouteId,
          destIndex,
        })
        .then(getData)
        .then(tPromise.decode(cargoOrdersT))
        .catch((err) => {
          if (
            err?.response.status === 409 &&
            err?.response?.data?.message ===
              ERR_CANNOT_MOVE_CARGO_TO_ARCHIVED_ROUTE
          ) {
            dispatch(
              notifyL({
                namespace: "notifications",
                key: "cannotAddCargoToArchivedRoute",
                type: "warning",
              })
            );
          } else {
            dispatch(defaultErrorAction());
          }
          throw err;
        });
    } else {
      return { updatedCargoOrders: [] };
    }
  }
);

export const updateFirstStopTime = createErrorReportingAsyncThunk(
  "/app/dispatch/updateFirstStopTime",
  async (arg: { cargoId: string; firstStopTime: string }) => {
    const { cargoId, firstStopTime } = arg;
    return axios.post(`/api/cargos/${cargoId}/first-stop-time`, {
      firstStopTime,
    });
  }
);
