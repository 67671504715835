import { DatePicker } from "@mui/x-date-pickers";
import { Control, Controller } from "react-hook-form";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

type DatePickerProps = {
  id: string;
  onChange?: (date: DateTime) => void;
  value?: DateTime;
  label?: string;

  // useForms inject
  controlField?: any;
};

type UseFormsInputProps = {
  control: Control<any, any>;
  name: string;
};

export const DoraDatePicker = (props: DatePickerProps) => {
  const { controlField, id, onChange, value, label } = props;
  const { t } = useTranslation("components");
  return (
    <DatePicker
      slotProps={{
        inputAdornment: { position: "start" },
        openPickerButton: { tabIndex: -1 },
      }}
      displayWeekNumber
      localeText={{
        calendarWeekNumberHeaderText: t("week"),
      }}
      label={label}
      sx={{
        margin: 0,
        svg: { color: "var(--gray-400)", fontSize: 20 },
        background: "white",
        "& .MuiInputAdornment-root": { marginRight: 0 },
        "& fieldset": { borderColor: "var(--gray-300)" },
        "& .MuiOutlinedInput-root:not(.Mui-focused):hover fieldset": {
          borderColor: "var(--gray-400) !important",
        },
      }}
      {...(!controlField && { onChange, value })}
      // TODO: id gets overwritten by MUI, consider removing or putting it somewhere else
      {...(id && { id })}
      {...controlField}
    />
  );
};

export const UFDoraDatePicker = (
  props: DatePickerProps & UseFormsInputProps
) => {
  const { name, control, ...rest } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => <DoraDatePicker controlField={field} {...rest} />}
    />
  );
};
