import { Route } from "../../models/route";
import * as t from "io-ts";
import { routeT } from "dora-contracts";
// import { useTranslation } from "react-i18next";

export type RouteSortProperty = "PICKUPDATE" | "DROPOFFDATE" | "TRUCKNUMBER";
export type SortOrder = "ASC" | "DESC";

export interface RoutesState {
  offeredCargoIds: string[];
  routeIds: string[];
  routeEntities: Record<string, StateRoute | undefined>;
  routeUpdates: Record<string, StateRoute | undefined>;
  sortProperty?: RouteSortProperty;
  routesSortOrder: SortOrder;
  isSearchForArchived: boolean;
  archivedSearchRouteIds?: string[];
}

export type StateRoute = Omit<Route, "cargo_order">;

export const routesT = t.array(routeT);

export const pickupSort = "Læssedato"; //{t("sorting:sortOrder.PICKUPDATE")};
export const dropoffSort = "Lossedato";
export const ldmSort = "Ladmeter";
export const createdAtSort = "Tilføjet";
export const pickupPostCodeSort = "PickupPostcode";
export const dropoffPostCodeSort = "DropoffPostcode";
export const clientSort = "Client";

export interface SearchInputs {
  clientId: string | null;
  teamId: string | null;
  cargoRef?: string;
  trailerNumber: string | null;
  dateFrom: string | null;
  dateTo: string | null;
  restrictSearchPeriod: boolean;
  onlyShowUninvoiced: boolean;
  showAllRoutes?: boolean;
}

export type RouteT = t.TypeOf<typeof routeT>;
