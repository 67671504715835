import classnames from "classnames";
import "./LocationDot.scss";

type LocationDotProps = {
  variant: "green" | "gray" | "black";
};

const LocationDot = (props: LocationDotProps) => {
  const { variant } = props;
  const classes = classnames("location-dot", {
    "location-dot--green": variant === "green",
    "location-dot--gray": variant === "gray",
    "location-dot--black": variant === "black",
  });
  return (
    <div className={classes}>
      <div className="location-dot__inner"></div>
    </div>
  );
};

export default LocationDot;
